import React from "react";
import "./list.css";
import { BsCircle } from "react-icons/bs";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const CustomList = ({
  icon,
  title,
  onClick,
  style,
  fd = "row",
  disable = false,
  type,
}) => {
  const { emp_data, user_type } = useSelector((state) => state.other);

  return (
    <div
      className="list-main-single"
      style={{
        ...style,
        flexDirection: fd ? fd : "row",
        opacity: disable ? 0.9 : 1,
      }}
      onClick={() => {
        if (user_type == "admin" || emp_data?.[type]) {
          onClick();
        } else {
          toast.info("You're not allowed to access this page", {
            toastId: "not-allowed-access", // Set a unique id for this toast
          });
        }
      }}
    >
      <div className="list-icon">
        {icon ? icon : <div className="circle"></div>}
      </div>
      <div className="list-title fs12">{title}</div>
    </div>
  );
};

export default CustomList;
