export const downloadFile = (filePath) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.download = filePath.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  export const previewAndDownloadFile = (filePath) => {
    // Create an iframe to preview the file within the same page
    const iframe = document.createElement("iframe");
    iframe.src = filePath;
    iframe.style.width = "100%";
    iframe.style.height = "500px"; // Set height or customize as needed
    iframe.style.border = "none";
  
    // Append iframe to the document to show the preview
    const previewContainer = document.getElementById("preview-container");
    if (previewContainer) {
      previewContainer.innerHTML = ""; // Clear any previous content
      previewContainer.appendChild(iframe);
    } else {
      const newContainer = document.createElement("div");
      newContainer.id = "preview-container";
      newContainer.style.margin = "20px 0";
      document.body.appendChild(newContainer);
      newContainer.appendChild(iframe);
    }
  
    // Create a download button
    const downloadButton = document.createElement("button");
    downloadButton.innerText = "Download";
    downloadButton.style.marginTop = "10px";
    downloadButton.onclick = () => {
      const downloadLink = document.createElement("a");
      downloadLink.href = filePath;
      downloadLink.download = filePath.split("/").pop(); // Set the file name for download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
  
    // Append download button to the container
    if (previewContainer) {
      previewContainer.appendChild(downloadButton);
    }
  };
  
  