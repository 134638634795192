import React, { useEffect, useState } from "react";
import styles from "./profile.module.css";
import Button from "../../components/btn/Button";
import CardDataRow from "./CardDataRow";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import SmallModal from "../../components/modal/SmallModal";
import { Input } from "../../components/inputs/Inputs";
import { toast } from "react-toastify";
import { profile_update_api } from "../../utils/apis";
import Loader from "../../components/Loader";
import { get_profile_data } from "./UsersSlice";
import { get_complete_list } from "../../redux/slice/completeListSlice";
import { CardDataRowProfile } from "../../components/list/ProfileList";
import { FaEdit, FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";

const Ainfo = () => {
  const [openView, setOpenView] = useState(false);
  const [showUpdatePic, setShowUpdatePic] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [upi_id, setUpi_id] = useState("");
  const { stateList, omcList } = useSelector((state) => state.completeList);
  const [rmvPic, setRmvPic] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [type, setType] = useState("");
  const [url, setUrl] = useState("");

  const { profile_data } = useSelector((state) => state.profile);
  const { user_id } = useSelector((state) => state.other);

  useEffect(() => {
    update_profile_data();
  }, [user_id]);
  const update_profile_data = () => {
    if (user_id) {
      store.dispatch(get_profile_data(user_id));
    }
  };

  useEffect(() => {
    // store.dispatch(get_complete_list());
  }, [profile_data]);

  const handle_pic_update = async (type) => {
    if (selectedFile !== null) {
      try {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("action", type);
        formData.append("agencyid", user_id);
        formData.append("image", selectedFile);
        if (type == "updateUPI") {
          formData.append("upi_id", upi_id);
        }
        const responce = await profile_update_api(formData);
        setShowLoader(false);
        if (responce.success) {
          setSelectedFile("");
          setShowUpdatePic(false);
          toast.success(responce?.message);
          update_profile_data();
          setUpi_id("");
          setUrl("");
        } else {
          toast.error(responce?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess("Please select file first");
      setOpen(true);
    }
  };
  const remove_pic = async (type) => {
    try {
      try {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("action", type);
        formData.append("agencyid", user_id);
        const responce = await profile_update_api(formData);
        setShowLoader(false);
        if (responce.success) {
          toast.success(responce?.message);
          update_profile_data();
          setRmvPic(false);
          setUrl("");
        } else {
          toast.error(responce?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.p_card_div}>
      <div className={styles.p_card_header}>
        <div className={styles.card_heading}>Agency Information</div>
      </div>
      <div className={styles.p_card}>
        <CardDataRowProfile
          title={"Distributorship ID"}
          value={profile_data?.agencyid}
        />
        <CardDataRowProfile
          title={"Distributorship Name"}
          value={profile_data?.name?.toUpperCase()}
        />
        <CardDataRowProfile title={"GSTIN"} value={profile_data?.gst} />
        <CardDataRowProfile
          title={"OIL Company"}
          value={omcList[profile_data?.OMC]}
        />
        <CardDataRowProfile
          title={"State"}
          value={
            stateList?.filter((v) => v?.code == profile_data?.state)[0]?.name
          }
        />
        <CardDataRowProfile
          title={"Signature"}
          value={
            <div style={{ display: "flex", gap: ".75rem" }}>
              <FaRegEdit
                size={16}
                cursor="pointer"
                onClick={() => {
                  setType("signedit");
                  setShowUpdatePic(true);
                  setFileName(profile_data?.sign_url || "");
                }}
              />
              <FaEye
                size={16}
                cursor="pointer"
                onClick={() => {
                  if (profile_data?.sign_url) {
                    setType("signedit");
                    setOpenView(true);
                    setUrl(profile_data?.sign_url);
                  } else {
                    toast.error("Please Upload Signature First");
                  }
                }}
              />
              <MdDelete
                size={16}
                cursor="pointer"
                onClick={() => {
                  if (profile_data?.sign_url) {
                    setRmvPic(true);
                    setType("signedit");
                  } else {
                    toast.error("Please Upload Signature First");
                  }
                }}
              />
            </div>
          }
        />
        <CardDataRowProfile
          title={"Seal"}
          value={
            <div style={{ display: "flex", gap: ".75rem" }}>
              <FaRegEdit
                size={16}
                cursor="pointer"
                onClick={() => {
                  setType("sealedit");
                  setShowUpdatePic(true);
                  setFileName(profile_data?.seal_url || "");
                }}
              />
              <FaEye
                size={16}
                cursor="pointer"
                onClick={() => {
                  if (profile_data?.seal_url) {
                    setType("sealedit");
                    setOpenView(true);
                    setUrl(profile_data?.seal_url);
                  } else {
                    toast.error("Please Upload Seal First");
                  }
                }}
              />
              <MdDelete
                size={16}
                cursor="pointer"
                onClick={() => {
                  if (profile_data?.seal_url) {
                    setRmvPic(true);
                    setType("sealedit");
                  } else {
                    toast.error("Please Upload Seal First");
                  }
                }}
              />
            </div>
          }
        />
        <CardDataRowProfile
          title={"UPI QR Code"}
          value={
            <div style={{ display: "flex", gap: ".75rem" }}>
              <FaRegEdit
                size={16}
                cursor="pointer"
                onClick={() => {
                  setType("updateUPI");
                  setUpi_id(profile_data?.UPI_ID);
                  setShowUpdatePic(true);
                  setFileName(profile_data?.QR_CODE_URL || "");
                }}
              />
              <FaEye
                size={16}
                cursor="pointer"
                onClick={() => {
                  if (profile_data?.QR_CODE_URL) {
                    setType("updateUPI");
                    setOpenView(true);
                    setUrl(profile_data?.QR_CODE_URL);
                  } else {
                    toast.error("Please Upload UPI First");
                  }
                }}
              />
              <MdDelete
                size={16}
                cursor="pointer"
                onClick={() => {
                  if (profile_data?.QR_CODE_URL) {
                    setRmvPic(true);
                    setType("updateUPI");
                  } else {
                    toast.error("Please Upload UPI First");
                  }
                }}
              />
            </div>
          }
        />
        {profile_data?.UPI_ID && (
          <CardDataRowProfile title={"UPI ID"} value={profile_data?.UPI_ID} />
        )}
      </div>
      {showUpdatePic && (
        <SmallModal
          onCancle={() => {
            setUpi_id("");
            setSelectedFile("");
            setFileName("");
          }}
          okBtnHide={false}
          isOpen={showUpdatePic}
          setIsOpen={setShowUpdatePic}
          style={{ width: "25rem" }}
          okbtn={setShowUpdatePic}
          title={`UPLOAD ${
            type == "signedit"
              ? "SIGNATURE"
              : type == "sealedit"
              ? "SEAL"
              : type == "updateUPI"
              ? "UPI"
              : ""
          }`}
          mess={
            <div className="pin-ver">
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      value={selectedFile?.name || fileName}
                      placeholder={"File Name"}
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: "30%",
                    padding: "0 0 0 1% ",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <label
                    style={{ width: "100%", margin: "5px 0" }}
                    className="label"
                  >
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      required
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        if (selectedFile) {
                          const fileType = selectedFile.type;
                          if (
                            fileType === "image/jpeg" ||
                            fileType === "image/png" ||
                            fileType === "image/jpg"
                          ) {
                            setSelectedFile(selectedFile);
                          } else {
                            alert(
                              "Please select a valid image file (either .jpg. .png or .jpeg)."
                            );
                            e.target.value = null;
                          }
                        }
                      }}
                    />

                    <span style={{ fontSize: "13px" }}>Browse</span>
                  </label>
                </div>
              </div>
              {type == "updateUPI" && (
                <div style={{ width: "100%" }}>
                  <Input
                    value={upi_id}
                    onChange={(e) => {
                      setUpi_id(e.target.value);
                    }}
                    placeholder={"UPI ID"}
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: "fit-content", margin: "1.5rem 0" }}>
                  <Button
                    title={"UPLOAD"}
                    onClick={() => handle_pic_update(type)}
                  />
                </div>
              </div>
            </div>
          }
        />
      )}
      {rmvPic && (
        <SmallModal
          isOpen={rmvPic}
          setIsOpen={setRmvPic}
          onClick={() =>
            remove_pic(
              type == "signedit"
                ? "signremove"
                : type == "sealedit"
                ? "sealremove"
                : type == "updateUPI"
                ? "upiremove"
                : ""
            )
          }
          title={"Confirm"}
          mess={`ARE YOU SURE TO REMOVE ${
            type == "signedit"
              ? "SIGNATURE"
              : type == "sealedit"
              ? "SEAL"
              : type == "updateUPI"
              ? "UPI"
              : ""
          }`}
        />
      )}
      {open && (
        <SmallModal
          title={"Alerts"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={mess}
          okbtn={true}
        />
      )}

      {openView && (
        <SmallModal
          okbtn={true}
          okBtnHide={false}
          isOpen={openView}
          setIsOpen={setOpenView}
          title={`${
            type == "signedit"
              ? "SIGNATURE"
              : type == "sealedit"
              ? "SEAL"
              : type == "updateUPI"
              ? "UPI"
              : ""
          }`}
          mess={
            <div>
              <img
                style={{
                  width: "300px",
                  objectFit: "fill",
                }}
                src={url}
                alt=""
              />
            </div>
          }
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default Ainfo;
