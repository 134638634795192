import React, { useEffect, useMemo, useRef, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import Button from "../../../components/btn/Button";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import { motion } from "framer-motion";
import Modal from "../../../components/modal/Modal";
import Input from "../../../components/inputs/Input";
import Dropdown from "../../../components/inputs/Dropdown";
import RadioBtn from "../../../components/radio/RadioBtn";
import { useSelector } from "react-redux";
import PageLoading from "../../../components/PageLoading";
import store from "../../../redux/store";
import { BankCodeFinder, DeleteIcon, Radio } from "../../../utils/utils";
import {
  get_commercial_sales_list,
  get_customer_purchase_order_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import moment from "moment";
import Table from "../../../components/table/Table";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import {
  Calcle_E_way_bill_api,
  Cancle_E_Invoice_api,
  E_Invoice_api,
  E_way_bill_api,
  GST_data_api,
  commercial_cylinder_sales_API,
  invoice_eway_preview,
} from "../../../utils/apis";
import { toast } from "react-toastify";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import {
  AreaCodeList,
  CVOList,
  EquipmentList,
  PriceList,
  StaffList,
} from "../../OtherComponent/StaticLists/staticLists";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import NoData from "../../../components/NoData";
import { DropInput } from "../../../components/inputs/DropInput";
import { alfaNumericWithoutSpace } from "../../../utils/Rejex";
import {
  get_areacode_master_list,
  get_bank_master_list,
  get_credit_limit_list,
  get_customer_vender_list,
  get_equipment_list,
  get_refill_price_list,
  get_staff_list,
  get_vehical_list,
} from "../../MasterData/masterdataSlice";
import ReactToPrint from "react-to-print";
import { TaxInvoice } from "../../OtherComponent/TaxInvoice/TaxInvoice";
import { Alerts } from "../../../components/Alerts/Alerts";
import { UOMData } from "../../../utils/data";
import {
  DateValidator2,
  gst_calculate,
  isInvoiceDateWithinCreditPeriod,
  UnitFunction,
} from "../../../utils/validation";
import TaxInvoice2 from "../../OtherComponent/TaxInvoice/TaxInvoice2";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
import TaxInvoice3 from "../../OtherComponent/TaxInvoice/TaxInvoice3";
import { ReduceFunction } from "../../../utils/Hook/ReduceFunction";
import { EmptyDataHook } from "../../../utils/Hook/EmptyDataHook";
// import { FaFileInvoice } from "react-icons/fa6";
// import { PiInvoiceThin } from "react-icons/pi";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { TbFileInvoice } from "react-icons/tb";
import {
  downloadFile,
  previewAndDownloadFile,
} from "../../../utils/Hook/DownloadFile";
import Tooltip from "../../../components/tooltip/Tooltip";
import PrintComp from "../../../components/PrintComp";
import ReactPrint from "../../../components/ReactPrint";

const tax_invoice_table_header2 = [
  "S.NO",
  "PRODUCT",
  "HSN/SAC	",
  "QTY",
  "UOM",
  "RATE",
  "DISCOUNT",
  "TAXABLE",
  "GST %	",
  "GST",
  "TOTAL",
];
const tax_invoice_table_header = [
  { title: "Sl No.", cols: false },
  { title: "Description of Goods", cols: true },
  { title: "HSN/SAC", cols: false },
  { title: "GST Rate", cols: false },
  { title: "Quantity", cols: false },
  { title: "Rate Incl. GST", cols: false },
  { title: "Rate", cols: false },
  { title: "per", cols: false },
  // { title:"Disc.% Cash",cols:false},
  { title: "Discount", cols: false },
  // { title: "Transport Charge" ,cols:false},
  { title: "Amount", cols: false },
];

export const ComercialCylSale = () => {
  const previewRef = useRef(null);
  const [EquipClick, setEquipClick] = useState(false);
  const [PriceClick, setPriceClick] = useState(false);
  const [StaffClick, setStaffClick] = useState(false);
  const [AreaClick, setAreaClick] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [addData, setAddData] = useState([]);
  const [addDataHead, setAddDataHead] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cvoClick, setCvoClick] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [SerchInput, setSearchInput] = useState("");
  const [open2, setOpen2] = useState(false);
  const [open2data, setOpen2data] = useState("");

  console.log("viewItem ===", viewItem);

  const { godown_address_list, day_end_date, user_turnover, token_status } =
    useSelector((state) => state.profile);
  const [showPreview, setShowPreview] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const {
    area_code_list,
    refill_price_list,
    cust_vend_list,
    staff_list,
    vehical_list,
    bank_master_list,
    equipment_list,
    credit_limit_list,
  } = useSelector((state) => state.masterdata);
  const { user_id, today, emp_id, user_type } = useSelector(
    (state) => state.other
  );
  const {
    commercial_sales_list,
    commercial_sales_loading,
    transition_complete_list,
    customer_purchase_list,
  } = useSelector((state) => state.transactions);

  console.log("commercial_sales_list ===", commercial_sales_list);

  const obj = {
    page: pageNo,
    limit: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };

  useEffect(() => {
    store.dispatch(get_equipment_list());
    store.dispatch(get_bank_master_list());
    store.dispatch(get_staff_list());
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_areacode_master_list());
    store.dispatch(get_refill_price_list());
    store.dispatch(get_customer_vender_list());
    store.dispatch(get_vehical_list());
    store.dispatch(get_customer_purchase_order_list());
    store.dispatch(get_credit_limit_list());
  }, []);

  const navigate = useNavigate();

  const fetchData = () => {
    store.dispatch(get_commercial_sales_list(obj));
  };

  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const fetchTransCharge = async (ind, area_code) => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "fetchareacode");
      body.append("agencyid", user_id);
      body.append("areacode", area_code);
      const response = await commercial_cylinder_sales_API(body);
      setLoading(false);
      if (response.success) {
        setAddData((pre) =>
          pre.map((v, i) =>
            i === ind
              ? {
                  ...v,
                  transport_charges:
                    Number(response.TRANSPORT_CHARGES || 0) *
                    ((addData[ind]?.quantity || 1) -
                      (addData?.[ind]?.psv_quantity || 0)),
                  transport_charges_unit: Number(
                    response.TRANSPORT_CHARGES || 0
                  ),
                  amount: "",
                  online_rcvd_amount: "",
                }
              : { ...v }
          )
        );
      } else if (!response.success) {
        setOpen1(true);
        setMess(response?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchGST = async () => {
    // const data = () => {
    //   if (
    //     !addData[addData.length - 1]?.product_code ||
    //     (addData[addData.length - 1]?.product_code &&
    //       addData[addData.length - 1]?.product_code.length === 0)
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // };
    const data = [];
    const data1 = DateValidator2(
      moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Invoice date cant be before as day end Date ${day_end_date}`,
      `Invoice date cant be a future date`
    );
    if (
      !addDataHead.inv_date ||
      (addDataHead.inv_date && addDataHead.inv_date.length != 10)
    ) {
      data.push("Please Enter Invoice Date First");
    } else if (data1 != true) {
      data.push(...data1);
    }
    if (
      !addDataHead.customer_name ||
      (addDataHead.customer_name && addDataHead.customer_name.length == 0)
    ) {
      data.push("Please Enter Customer Name First");
    }
    if (
      !addData[addData.length - 1]?.product_code ||
      (addData[addData.length - 1]?.product_code &&
        addData[addData.length - 1]?.product_code.length === 0)
    ) {
      data.push("please select product first ");
    }

    if (data?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "fetchgst");
        body.append("agencyid", user_id);
        body.append("procode", addData[addData.length - 1]?.product_code);
        body.append("invdate", addDataHead?.inv_date);
        body.append("cus_id", addDataHead?.customer_id || "0");
        body.append("pono", addDataHead?.purchase_order_id || "");
        const response = await commercial_cylinder_sales_API(body);
        setLoading(false);

        if (response.success) {
          setAddData((pre) =>
            pre.map((v, i) =>
              i === addData.length - 1
                ? {
                    ...v,
                    p_gst: response?.GSTP,
                    unit_rate: response?.BASE_PRICE,
                    discount_price: response?.dis_unit_rate,
                  }
                : { ...v }
            )
          );
        } else if (!response.success) {
          setOpen1(true);
          setMess(response?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen1(true);
      setMess(<Alerts data={data} />);
    }
  };

  const onDelete_item = async () => {
    setOpen(false);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    body.append("id", id);
    if (emp_id) {
      body.append("emp_code", emp_id);
    }
    const responce = await commercial_cylinder_sales_API(body);
    if (responce.success) {
      toast(responce?.message);
      await store.dispatch(get_commercial_sales_list(obj));
    } else if (!responce?.success) {
      setMess(responce?.message);
      setOpen1(true);
    }
  };

  const calculate = () => {
    var total = 0;
    var psv_t = 0;

    if (
      !addDataHead?.inv_date ||
      (addDataHead?.inv_date && addDataHead?.inv_date?.length == 0)
    ) {
      setMess("Please Enter Invoice date ");
      setOpen1(true);
      return;
    }
    if (
      !addDataHead?.customer_name ||
      (addDataHead?.customer_name && addDataHead?.customer_name?.length == 0)
    ) {
      setMess("Please Enter Customer Name First ");
      setOpen1(true);
      return;
    } else if (
      addDataHead?.customer_id?.length == 0 &&
      addDataHead?.cust_reg == "1"
    ) {
      setMess("Please Enter Customer Name First ");
      setOpen1(true);
      return;
    }
    addData?.forEach((v, ind) => {
      if (
        !v?.area_code ||
        (v?.area_code && v?.area_code.length == 0) ||
        !v?.unit_rate ||
        (v?.unit_rate && v?.unit_rate.length == 0) ||
        !v?.quantity ||
        (v?.quantity && v?.quantity.length == 0) ||
        !v?.p_gst ||
        (v?.p_gst && v?.p_gst.length == 0) ||
        v?.discount_price === undefined ||
        v?.discount_price === null ||
        v?.discount_price < 0 ||
        v?.discount_price === "" ||
        (addDataHead?.cust_reg == "1" &&
          (v?.psv_quantity === undefined ||
            v?.psv_quantity === null ||
            v?.psv_quantity === "" ||
            v?.psv_quantity < 0))
      ) {
        setOpen1(true);
        setMess("Please Fill All filed first");
        return;
      }
      const unit_rate = Number(v?.unit_rate);
      const quantity = Number(v?.quantity);
      const p_gst = Number(v?.p_gst);
      const discount_price = Number(v?.discount_price);
      const psv_quantity = Number(v?.psv_quantity);
      // const prepaid_cylinder = Number(v?.prepaid_cylinder);
      const transport_charges = Number(v?.transport_charges) || 0;

      // Normal Quantity Calculation
      const psv_amount = (unit_rate - discount_price) * psv_quantity;
      const psv_gst = psv_amount * (p_gst / 100);
      const psv_total = psv_gst + psv_amount;
      const tax_amount = (unit_rate - discount_price) * quantity - psv_amount;
      const gst_amount = tax_amount * (p_gst / 100);
      const tr_charges = transport_charges + transport_charges * (p_gst / 100);
      // const amount = gst_amount + tax_amount + tr_charges;

      const tds_amt = addDataHead?.type == "NA" ? 0 : tax_amount * (0.1 / 100);
      const amount =
        addDataHead?.type == "TDS"
          ? gst_amount + tr_charges + tax_amount + tds_amt
          : addDataHead?.type == "TCS"
          ? gst_amount + tr_charges + tax_amount - tds_amt
          : gst_amount + tr_charges + tax_amount;

      // PSV Quantity Calculation
      // const psv_amount = (unit_rate - discount_price) * psv_quantity;
      // const psv_gst_amount = psv_amount * (p_gst / 100);
      // const psv_total_amount = psv_gst_amount + psv_amount;

      // // Digi Quantity Calculation
      // const prepaid_amount = (unit_rate - discount_price) * prepaid_cylinder;
      // const prepaid_gst_amount = prepaid_amount * (p_gst / 100);
      // const prepaid_total_amount = prepaid_gst_amount + prepaid_amount;

      // Finalize
      // const transiction_total_amount =
      //   amount - psv_total_amount - prepaid_total_amount - tr_charges;
      // const online_rcvd_total_amount =
      //   prepaid_total_amount == 0 ? 0 : prepaid_total_amount;

      const total_amount = amount;

      setAddData((pre) =>
        pre.map((v, i) => {
          if (i == ind) {
            return {
              ...v,
              tax_amount: Number(tax_amount).toFixed(2),
              amount: Number(total_amount).toFixed(2),
              igst:
                addDataHead?.c_type == "isp"
                  ? Number(gst_amount).toFixed(2)
                  : 0,
              sgst:
                addDataHead?.c_type != "isp"
                  ? Number(gst_amount / 2).toFixed(2)
                  : 0,
              cgst:
                addDataHead?.c_type != "isp"
                  ? Number(gst_amount / 2).toFixed(2)
                  : 0,
              tds_amt: Number(tds_amt).toFixed(2),
            };
          }
          return { ...v };
        })
      );
      total += amount;
      psv_t += psv_total;
    });
    setAddDataHead({
      ...addDataHead,
      inv_amount: Number(total).toFixed(2),
      total_new: Number(total).toFixed(2),
      cash_recieved: Number(
        total -
          (addDataHead?.online_recieved > 0 ? addDataHead?.online_recieved : 0)
      ).toFixed(2),
      online_recieved: Number(addDataHead?.online_recieved || 0).toFixed(2),
    });
  };

  const validation_check = () => {
    if (
      addData.find(
        (v) =>
          !v?.product_code ||
          (v.product_code && v.product_code.length == 0) ||
          !v.p_gst ||
          (v.p_gst && v.p_gst.length == 0) ||
          !v.unit_rate ||
          (v.unit_rate && v.unit_rate.length == 0) ||
          !v.quantity ||
          v.quantity == 0 ||
          (v.quantity && v.quantity.length == 0) ||
          v?.psv_quantity === undefined ||
          v?.psv_quantity === null ||
          v?.psv_quantity === "" ||
          v?.empty_recived === undefined ||
          v?.empty_recived === null ||
          v?.empty_recived === "" ||
          !v.area_code ||
          (v.area_code && v.area_code.length == 0) ||
          v?.discount_price === undefined ||
          v?.discount_price === null ||
          v?.discount_price === "" ||
          !v.amount ||
          (v.amount && v.amount.length == 0)
      )
    ) {
      return true;
    }
    return false;
  };

  const add_items = async (type) => {
    const data = await valiDate();
    // const data = [...new Set(data1)];
    if (data?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("invnumber", addDataHead?.ref_no);
        body.append("payment_terms", addDataHead?.payment_terms);
        body.append("invdate", addDataHead?.inv_date);
        body.append("cust_reg", addDataHead?.cust_reg);
        body.append("si_amt", addDataHead?.inv_amount);
        body.append("pono", addDataHead?.purchase_order_id || "");
        body.append("dispatch_godown", addDataHead?.godown_id);
        body.append("delivery_godown", addDataHead?.delivery_godown_id);
        body.append("cus_id", addDataHead?.customer_id || "");
        body.append("gst_no", addDataHead?.gst_num);
        body.append("cash_rcvd", addDataHead?.cash_recieved);
        body.append("online_rcvd_amt", addDataHead?.online_recieved);
        body.append("transaction_id", addDataHead?.transaction_id || "");
        body.append("cash_rcvd_form", addDataHead?.cash_recieved_from || "");
        body.append("bank_id", addDataHead?.bank_account || "");
        body.append("cus_name", addDataHead?.customer_name || "");
        body.append("address", addDataHead?.address);
        body.append("vehicle_no", addDataHead?.vehical_number);
        body.append("connection_type", addDataHead?.c_type);
        body.append(
          "sync_flag",
          addDataHead?.type == "TDS"
            ? 1
            : addDataHead?.type == "TCS"
            ? 2
            : addDataHead?.type == "NA"
            ? 3
            : ""
        );

        addData?.forEach((v) => {
          if (v) {
            body.append("product[]", v?.product_code);
            body.append("quantity[]", v?.quantity);
            body.append("dis_unit_rate[]", v?.discount_price);
            body.append("unit_rate[]", v?.unit_rate);
            body.append("gst[]", v?.p_gst);
            body.append("total_amount[]", v?.amount);
            body.append("tds[]", v?.tds_amt);
            body.append("narration[]", v?.narration);
            body.append("psv_cyls[]", v?.psv_quantity);
            body.append("empty_received[]", v?.empty_recived);
            body.append("bank_account[]", v?.bank_account || "");
            body.append("staff_id[]", v?.delivered_by);
            body.append("transport_charges[]", v?.transport_charges);
            body.append("areacode[]", v?.area_code);
          }
        });
        if (emp_id) {
          body.append("emp_code", emp_id);
        }

        const data = await commercial_cylinder_sales_API(body);
        setLoading(false);
        if (data.success) {
          toast(data?.message);
          setIsAdd(false);
          store.dispatch(get_commercial_sales_list(obj));
          setAddData([]);
          setShowPreview(false);
          setAddDataHead({});
        } else if (!data.success) {
          setMess(data?.message);
          setOpen1(true);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const FetchVoucherNumber = async () => {
    const data = [];
    const data1 = DateValidator2(
      moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Invoice date cant be before as day end Date ${day_end_date}`,
      `Invoice date cant be a future date`
    );
    if (
      !addDataHead.inv_date ||
      (addDataHead.inv_date && addDataHead.inv_date.length == 0)
    ) {
      data.push("Please Enter Invoice Date First");
    } else if (data1 != true) {
      data.push(...data1);
    }
    if (
      !addDataHead.customer_name ||
      (addDataHead.customer_name && addDataHead.customer_name.length == 0)
    ) {
      data.push("Please Enter Customer Name First");
    }

    if (data?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "invoicenumber");
        body.append("agencyid", user_id);
        body.append("date", addDataHead?.inv_date);
        body.append("is_gst_reg", addDataHead?.cust_reg_gst);
        body.append("cust_id", addDataHead?.customer_id || "");
        const response = await commercial_cylinder_sales_API(body);
        setLoading(false);
        if (response.success) {
          setAddDataHead({ ...addDataHead, ref_no: response.invoice_number });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen1(true);
      setMess(<Alerts data={data} />);
    }
  };

  const valiDate = () => {
    const data = [];
    const data1 = DateValidator2(
      moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Invoice date cant be before as day end Date ${day_end_date}`,
      `Invoice date cant be a future date`
    );
    if (
      !addDataHead?.ref_no ||
      (addDataHead?.ref_no && addDataHead?.ref_no.length === 0)
    ) {
      data.push("Please Enter Invoice Number");
    } else if (data1 != true) {
      data.push(...data1);
    }
    if (
      !addDataHead?.inv_date ||
      (addDataHead?.inv_date && addDataHead?.inv_date.length === 0)
    ) {
      data.push("Please Enter Invoice Date");
    }
    if (
      !addDataHead?.cust_reg ||
      (addDataHead?.cust_reg && addDataHead?.cust_reg.length === 0)
    ) {
      data.push("Please Enter Customer Registration");
    }
    if (
      !addDataHead?.customer_name ||
      (addDataHead?.customer_name && addDataHead?.customer_name.length === 0)
    ) {
      data.push("Please Enter CUSTOMER NAME");
    }

    // if (
    //   !addDataHead?.vehical_number ||
    //   (addDataHead?.vehical_number && addDataHead?.vehical_number.length === 0)
    // ) {
    //   data.push("Please Select VEHICLE NUMBER");
    // }

    if (godown_address_list?.length == 0) {
      data.push("Please FIrst add Godown in the list");
    } else if (
      !addDataHead?.godown_id ||
      (addDataHead?.godown_id && addDataHead?.godown_id.length === 0)
    ) {
      data.push("Please Select DISPATCH GODOWN");
    }
    if (addDataHead?.cust_reg == "1") {
    } else if (addDataHead?.cust_reg == "0") {
      if (
        !addDataHead?.address ||
        (addDataHead?.address && addDataHead?.address.length === 0)
      ) {
        data.push("Please Enter Address");
      } else if (addDataHead?.address?.length < 30) {
        data.push("Address should be greater then 30 charactors ");
      } else if (addDataHead?.address?.length > 200) {
        data.push("Address should be less then 200 charactors ");
      }

      if (
        addDataHead?.gst_num &&
        addDataHead?.gst_num != "" &&
        addDataHead?.gst_num.length !== 15
      ) {
        data.push("Please Enter a Valid GST");
      }

      // const sumData =
      //   Number(addDataHead?.cash_recieved) +
      //   Number(addDataHead?.online_recieved);
      if (
        (!addDataHead?.cash_recieved || addDataHead?.cash_recieved == 0) &&
        (!addDataHead?.online_recieved || addDataHead?.online_recieved == 0)
      ) {
        data.push("Please Enter Cash and Online Recieved Amount");
      }
      // else if (sumData != invAmount) {
      //   data.push(
      //     "Please Make sure the sum of Cash and Online Recieved Amount is Equal to Inv Amount"
      //   );
      // }
      // else if (tableOnlineRecivedAmmount > addDataHead?.online_recieved) {
      //   data.push(
      //     "Online Recieved Amount Should be Greater then or equal to Table Online RCVD amounts Sum"
      //   );
      // }
      else if (
        Number(addDataHead?.cash_recieved) < 0 ||
        Number(addDataHead?.online_recieved) < 0
      ) {
        if (Number(addDataHead?.cash_recieved) < 0) {
          data.push("Cash Received amount cant be lessthen Zero");
        } else if (Number(addDataHead?.online_recieved) < 0) {
          data.push("Online Received amount cant be lessthen Zero");
        }
      }
      if (addDataHead?.online_recieved > 0) {
        // if (
        //   addData?.cash_received != 0 &&
        //   !addDataHead?.transaction_id &&
        //   (addDataHead?.transaction_id || addDataHead?.transaction_id == "")
        // ) {
        //   data.push("Please Enter Transaction Id");
        // }
        //  else if (addDataHead?.transaction_id?.length != 15) {
        //   data.push("Please Enter valid Transaction Id");
        // }
        if (
          (!addDataHead?.cash_recieved_from ||
            addDataHead?.cash_recieved_from == "") &&
          addDataHead?.cash_recieved > 0
        ) {
          data.push("Please Select a Cash Recieved From");
        }
        if (
          (!addDataHead?.bank_account || addDataHead?.bank_account == "") &&
          addDataHead?.online_recieved > 0
        ) {
          data.push("Please Select a Bank Account");
        }
      }
    }

    for (let v of addData) {
      if (
        !v?.product_code ||
        (v?.product_code && v?.product_code.length === 0)
      ) {
        data.push("Please Select PRODUCT ");
      }
      if (!v?.p_gst || (v?.p_gst && v?.p_gst.length === 0)) {
        data.push("Please Select GST");
      }
      if (!v?.unit_rate || (v?.unit_rate && v?.unit_rate.length === 0)) {
        data.push("Please Select Unit Rate");
      }
      if (
        v?.discount_price === undefined ||
        v?.discount_price === null ||
        v?.discount_price === ""
      ) {
        data.push("Please Enter Discount Price");
      } else if (v?.discount_price < 0) {
        data.push("Discount cant be a negetive value");
      }
      if (!v?.quantity || (v?.quantity && v?.quantity.length === 0)) {
        data.push("Please Enter Quantity");
      } else if (v?.quantity == 0) {
        data.push("Please Enter valid Quantity");
      }
      //  else if (
      //   equipment_list?.filter((s) => s?.PROD_CODE == v?.product_code)?.[0]
      //     ?.CS_FULLS < v?.quantity
      // ) {
      //   data.push(
      //     "Given Quantity is not available in our stock, please Check Stocks and Try again"
      //   );
      // }
      if (
        v?.psv_quantity === undefined ||
        v?.psv_quantity === null ||
        v?.psv_quantity === ""
      ) {
        data.push("Please Enter PSV Quantity");
      } else if (v?.psv_quantity < 0) {
        data.push("PSV Quantity cant be a negetive value");
      }
      if (
        !v?.empty_recived ||
        (v?.empty_recived && v?.empty_recived.length === 0)
      ) {
        data.push("Please Enter Empty Cylinder");
      }
      // if (
      //   addDataHead?.payment_terms != "2" &&
      //   (!v?.bank_account || (v?.bank_account && v?.bank_account.length === 0))
      // ) {
      //   data.push("Please Select Bank Account");
      // }
      if (!v?.area_code || (v?.area_code && v?.area_code.length === 0)) {
        data.push("Please Select Area Code");
      }
      if (!v?.amount || (v?.amount && v?.amount.length === 0)) {
        data.push("Please Click on Calculate first");
      }
      // if (
      //   !v?.transport_charges ||
      //   (v?.transport_charges && v?.transport_charges.length === 0)
      // ) {
      //   data.push("Please Select Area Code");
      // }
    }
    if (
      !addDataHead?.inv_amount ||
      (addDataHead?.inv_amount && addDataHead?.inv_amount.length === 0)
    ) {
      data.push("Please Click on Calculate");
    }
    return data;
  };

  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);
  const preview_inv = async (type) => {
    const data = await valiDate();
    if (data?.length == 0) {
      if (type == "first") {
        if (addDataHead?.cust_reg == "0" && addDataHead?.inv_amount > 250000) {
          setMess(
            "YOUR INVOICE AMOUNT EXCEEDS RS.2,50,000/- .IF YOU WANT TO ADD FURTHER,ADD IN NEXT INVOICE"
          );
          setOpen1(true);
          return;
        } else if (addDataHead?.cust_reg == "1") {
          const data3 = credit_limit_list?.filter(
            (v) => v?.customer_id == addDataHead?.customer_id
          )?.[0];
          const data2 = cust_vend_list?.filter(
            (v) => v?.ID == addDataHead?.customer_id
          )?.[0];
          const dateValid = isInvoiceDateWithinCreditPeriod(
            data3?.modified_date,
            addDataHead?.inv_date,
            data3?.credit_days
          );
          if (data3?.cc_days == "BLOCK" && !dateValid) {
            setMess(
              "THIS TRANSACTION CANNOT BE PROCEEDED FURTHER AS THIS CUSTOMER HAS EXCEEDED CREDIT Days."
            );
            setOpen1(true);
            return;
          } else if (data3?.cc_days == "WARN" && !dateValid) {
            setMess(
              "THIS CUSTOMER HAS EXCEEDED CREDIT Days. DO YOU WANT TO CONTINUE?"
            );
            setOpen4(true);
            return;
          } else if (
            data3?.cc_limit == "BLOCK" &&
            (Number(data3.credit_limit) < Number(addDataHead?.inv_amount) ||
              Number(data3.credit_limit) < Number(data2.CBAL) ||
              Number(data3.credit_limit) <
                Number(addDataHead?.inv_amount) + Number(data2.CBAL))
          ) {
            setMess(
              "THIS TRANSACTION CANNOT BE PROCEEDED FURTHER AS THIS CUSTOMER HAS EXCEEDED CREDIT LIMIT."
            );
            setOpen1(true);
            return;
          } else if (
            data3?.cc_limit == "WARN" &&
            (Number(data3.credit_limit) < Number(addDataHead?.inv_amount) ||
              Number(data3.credit_limit) < Number(data2.CBAL) ||
              Number(data3.credit_limit) <
                Number(addDataHead?.inv_amount) + Number(data2.CBAL))
          ) {
            setMess(
              "THIS CUSTOMER HAS EXCEEDED CREDIT LIMIT. DO YOU WANT TO CONTINUE?"
            );
            setOpen4(true);
            return;
          }
        }
      }
      setOpen4(false);
      setOpen1(false);
      filter_hsn_data(addData);
      setShowPreview(true);
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };
  const filter_hsn_data = (addData) => {
    if (addData?.length > 0) {
      const hsn_data = Object.values(
        addData.reduce((acc, item) => {
          if (!acc[item.hsn]) {
            acc[item.hsn] = {
              hsn: item.hsn,
              taxable: 0,
              cgst: 0,
              igst: 0,
              sgst: 0,
              total: 0,
              igst_p: addDataHead?.c_type == "lp" ? 0 : item?.p_gst,
              sgst_p: addDataHead?.c_type == "lp" ? Number(item?.p_gst) / 2 : 0,
              cgst_p: addDataHead?.c_type == "lp" ? Number(item?.p_gst) / 2 : 0,
            };
          }
          acc[item.hsn].taxable += Number(item.tax_amount);
          acc[item.hsn].cgst += Number(item.cgst);
          acc[item.hsn].igst += Number(item.igst);
          acc[item.hsn].sgst += Number(item.sgst);
          acc[item.hsn].total +=
            Number(item.cgst) + Number(item.igst) + Number(item.sgst);
          return acc;
        }, {})
      );
      setAddDataHead({ ...addDataHead, hsn_data: hsn_data });
    } else {
      return;
    }
  };

  const genrate_e_invoice = async (sr_no, id) => {
    try {
      setLoading(true);
      const res = await E_Invoice_api({
        document_number: sr_no,
        agencyid: user_id,
      });
      setLoading(false);

      if (res.success) {
        // const pdfUrl =
        //   res?.data?.results?.message?.EinvoicePdf || res?.data?.einvoice_url;
        toast.success(res.message);
        // await store.dispatch(get_commercial_sales_list(obj));
        // const data = commercial_sales_list?.filter((v) => v?.ID === id)?.[0];
        await downloadFile(
          res?.data?.results?.message?.EinvoicePdf || res?.data?.einvoice_url
        );
        // setViewItem(data)
      } else {
        setOpen1(true);
        setMess(res.message);
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };

  // const genrate_e_invoice = async (sr_no) => {
  //   try {
  //     setLoading(true);
  //     const res = await E_Invoice_api({
  //       document_number: sr_no,
  //       agencyid: user_id,
  //     });
  //     setLoading(false);
  //     if (res.success) {
  //       // await downloadFile(
  //       //   res?.data?.results?.message?.EinvoicePdf || res?.data?.einvoice_url
  //       // );
  //       // setPdfUrl(
  //       //   res?.data?.results?.message?.EinvoicePdf || res?.data?.einvoice_url
  //       // );
  //       const url =
  //         res?.data?.results?.message?.EinvoicePdf || res?.data?.einvoice_url;
  //       // window.open(url, "_blank");
  //       // const newWindow = window.open(
  //       //   `https://docs.google.com/gview?url=${url}&embedded=true`,
  //       //   "_blank"
  //       // );
  //       // newWindow.onload = () => {
  //       //   newWindow.print();
  //       // };
  //       // setPdfUrl(url);
  //       // // window.print(url);

  //       toast.success(res.message);
  //       await store.dispatch(get_commercial_sales_list(obj));
  //       showPreview(true);
  //     } else if (!res.success) {
  //       setOpen1(true);
  //       setMess(res.message);
  //     }
  //   } catch (error) {
  //     console.log("error ===", error);
  //   }
  // };

  const genrate_e_way_bill = async (sr_no) => {
    try {
      setLoading(true);
      const res = await E_way_bill_api({
        document_number: sr_no,
        agencyid: user_id,
      });
      setLoading(false);
      if (res.success) {
        await downloadFile(
          "https://" +
            (res?.data?.results?.message?.url || res?.data?.ewaybill_url)
        );

        toast.success(res.message);
      } else if (!res.success) {
        setOpen1(true);
        setMess(res.message);
        store.dispatch(get_commercial_sales_list(obj));
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };

  const cancle_e_invoice = async (irn) => {
    try {
      setLoading(true);
      const res = await Cancle_E_Invoice_api({
        irn: irn,
        agencyid: user_id,
      });
      setLoading(false);
      if (res.success) {
        setOpenView(false);
        toast.success(res.message);
        store.dispatch(get_commercial_sales_list(obj));
      } else if (!res.success) {
        setOpen1(true);
        setMess(res.message);
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };

  const cancle_e_way_bill = async (bill_no) => {
    try {
      setLoading(true);
      const res = await Calcle_E_way_bill_api({
        eway_bill_number: bill_no,
        agencyid: user_id,
      });
      setLoading(false);
      if (res.success) {
        toast.success(res.message);
      } else if (!res.success) {
        setOpen1(true);
        setMess(res.message);
        store.dispatch(get_commercial_sales_list(obj));
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"COMMERCIAL CYLINDER SALES"}
        right={
          <>
            <AddButton
              onClick={() => {
                setAddData((prevData) => [...prevData, {}]);
                setIsAdd(true);
              }}
            />
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/oEzSuwSIkRg",
                "https://youtu.be/rrX-pqA7h68",
              ]}
            />
          </>
        }
      />

      {/* {pdfUrl && <PrintComp url={pdfUrl} />} */}

      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {commercial_sales_loading ? (
          <PageLoading />
        ) : commercial_sales_list?.length == 0 ? (
          <NoData mt="2rem" />
        ) : (
          <div className="grid">
            {commercial_sales_list.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">
                    {v?.cvo_name || v?.CUST_NAME}
                  </div>
                  <RowTxt
                    title={"Invoice No."}
                    value={
                      <ReactToPrint
                        trigger={() => (
                          <div
                            onMouseEnter={() => {
                              setViewItem(v);
                            }}
                            onTouchStart={() => {
                              setViewItem(v);
                            }}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setViewItem(v);
                            }}
                          >
                            {v?.SR_NO}
                          </div>
                        )}
                        content={() => previewRef.current}
                        documentTitle={`${v?.cvo_name || v?.CUST_NAME}-${
                          v?.SR_NO
                        }`}
                      />
                    }
                  />
                  <RowTxt
                    title={"Invoice Date"}
                    value={moment(v?.SI_DATE).format("DD-MMM-YYYY")}
                  />
                  <RowTxt
                    title={"Total Amount"}
                    value={Number(v?.SI_AMOUNT).toFixed(2)}
                  />
                  <RowTxt
                    title={"Payment Terms"}
                    value={
                      v?.PAYMENT_TERMS == "1"
                        ? "Cash"
                        : v?.PAYMENT_TERMS == "2"
                        ? "Credit"
                        : ""
                    }
                  />
                  <RowTxt
                    title={"TDS/TCS"}
                    value={
                      v?.SYNC_FLAG == 1
                        ? "TDS"
                        : v?.SYNC_FLAG == 2
                        ? "TCS"
                        : v?.SYNC_FLAG == 3
                        ? "NA"
                        : ""
                    }
                  />
                  <RowTxt title={"Godown Address"} value={v?.GODOWN_NAME} />
                  {v?.PO_NO && <RowTxt title={"PO Number"} value={v?.PO_NO} />}
                  {v?.PAYMENT_TERMS == "1" && (
                    <>
                      {v?.CUST_GSTIN && (
                        <RowTxt title={"GST Number"} value={v?.CUST_GSTIN} />
                      )}
                      <RowTxt
                        title={"Cash Recieved"}
                        value={v?.CASH_RECEIVED}
                      />
                      <RowTxt
                        title={"Online Recieved"}
                        value={v?.AMOUNT_RECEIVED_ONLINE}
                      />
                      {v?.TRANSACTION_ID && (
                        <RowTxt
                          title={"Transaction Id"}
                          value={v?.TRANSACTION_ID}
                        />
                      )}
                      {v?.RECEIVED_FROM && (
                        <RowTxt
                          title={"Cash Recieved From"}
                          value={
                            v?.RECEIVED_FROM == "1"
                              ? "Phone Pay"
                              : v?.RECEIVED_FROM == "2"
                              ? "Google Pay"
                              : v?.RECEIVED_FROM == "3"
                              ? "Paytm"
                              : v?.RECEIVED_FROM == "4"
                              ? "Cred"
                              : v?.RECEIVED_FROM == "5"
                              ? "Others"
                              : v?.RECEIVED_FROM
                          }
                        />
                      )}

                      <RowTxt title={"Bank Account"} value={v?.BANK_ACC_NO} />
                    </>
                  )}
                  <RowTxt title={"Created By"} value={v?.CREATED_NAME || "-"} />
                </div>
                <div
                  className="df jcsb mt10"
                  // style={{
                  //   justifyContent:
                  //     user_type != "admin" && v?.EMPLOYEE_CODE != emp_id
                  //       ? "flex-end"
                  //       : "space-between",
                  // }}
                >
                  {v?.STATUS_FLAG == "0" ? (
                    <>
                      {user_type == "admin" || v?.EMPLOYEE_CODE == emp_id ? (
                        <DeleteIcon
                          onClick={() => {
                            setId(v?.ID);
                            setOpen(true);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <div></div>
                  )}

                  {token_status == 1 && (
                    <>
                      {v?.CVO_TIN?.length == 15 && (
                        <Tooltip content={"E-Invoice"}>
                          <ReactPrint
                            num={
                              <TbFileInvoice
                                onClick={() =>
                                  genrate_e_invoice(v?.SR_NO, v?.ID)
                                }
                                color="#007bff"
                                size={20}
                                cursor={"pointer"}
                              />
                            }
                            v={v}
                            setViewItem={setViewItem}
                            documentTitle={`${v?.cvo_name || v?.CUST_NAME}-${
                              v?.SR_NO
                            }`}
                            content={() => previewRef.current}
                            previewRef={previewRef}
                          />
                        </Tooltip>
                      )}
                      {Number(v?.SI_AMOUNT).toFixed(2) > 50000 && (
                        <Tooltip content={"E-Way Bill"}>
                          <LiaFileInvoiceSolid
                            onClick={() => genrate_e_way_bill(v?.SR_NO)}
                            color="#007bff"
                            size={20}
                            cursor={"pointer"}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}

                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div
                      className="view-btn"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    >
                      View
                    </div>
                    <HiOutlineArrowRight
                      size={20}
                      color="#1b64af"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddData([]);
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button
                title={"CVO"}
                back
                onClick={() => setCvoClick(!cvoClick)}
              />
              <Button
                title={"EQUIPMENT"}
                back
                onClick={() => setEquipClick(!EquipClick)}
              />
              <Button
                title={"PRICE"}
                back
                onClick={() => setPriceClick(!PriceClick)}
              />
              <Button
                title={"BANK"}
                back
                onClick={() => setCvoClick(!cvoClick)}
              />
              <Button
                title={"STAFF"}
                back
                onClick={() => setStaffClick(!StaffClick)}
              />
              <Button
                title={"AREACODE"}
                back
                onClick={() => setAreaClick(!AreaClick)}
              />
            </div>
          }
          title={"COMMERCIAL CYLINDER SALES"}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Input
                  label={"SALES INVOICE DATE"}
                  type={"date"}
                  value={addDataHead.inv_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        inv_date: e.target.value,
                        PO_NO: "",
                        cust_reg: "",
                        purchase_order: "",
                        purchase_order_id: "",
                        purchase_order_date: "",
                        customer_name: "",
                        customer_id: "",
                        gst_num: "",
                        address: "",
                        godown_id: "",
                        payment_terms: "",
                        SR_NO: "",
                      });
                      const resetData1 = EmptyDataHook(addData?.[0]);
                      setAddData([resetData1]);
                    }
                  }}
                />
                <Input
                  label={"INVOICE NUMBER"}
                  placeholder={"INVOICE NUMBER"}
                  value={addDataHead.ref_no}
                  disabled={true}
                />
                <Dropdown
                  width2=""
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      PO_NO: "",
                      cust_reg: e.target.value,
                      purchase_order: "",
                      purchase_order_id: "",
                      purchase_order_date: "",
                      customer_name: "",
                      customer_id: "",
                      gst_num: "",
                      address: "",
                      godown_id: "",
                      payment_terms:
                        e.target.value == "1"
                          ? "2"
                          : e.target.value == "0"
                          ? "1"
                          : "",
                      SR_NO: "",
                      payment_terms_dis:
                        e.target.value == "1" || e.target.value == "0"
                          ? true
                          : false,
                    });
                    if (e.target.value == "1") {
                      setAddData((prevData) =>
                        prevData.map((val, ind) => ({
                          ...val,
                          bank_account: "",
                        }))
                      );
                    } else if (e.target.value == "0") {
                      setAddData((prevData) =>
                        prevData.map((val, ind) => ({
                          ...val,
                          psv_quantity: 0,
                          empty_recived: val?.quantity || 0,
                        }))
                      );
                    }
                  }}
                  value={addDataHead?.cust_reg}
                  label={"CUSTOMER REG."}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"1"}>YES</option>
                      <option value={"0"}>NO</option>
                    </>
                  }
                />
                {addDataHead?.cust_reg == "0" && (
                  <Input
                    label={"GST NO."}
                    placeholder={"GST NO."}
                    value={addDataHead?.gst_num}
                    onChange={async (e) => {
                      let GSTInputValue = e.target.value.toUpperCase();
                      const validInput = GSTInputValue.replace(
                        /[^a-zA-Z0-9]/g,
                        ""
                      );
                      if (validInput.length <= 15) {
                        setAddDataHead({
                          ...addDataHead,
                          gst_num: validInput,
                        });
                      }
                      if (validInput?.length == 15) {
                        try {
                          const body = new FormData();
                          body.append("gstin", validInput);
                          const response = await GST_data_api(body);
                          if (response.error === false) {
                          } else if (response.error === true) {
                            setAddDataHead({
                              ...addDataHead,
                              gst_num: "",
                            });
                            setMess(
                              response.errmsg == null
                                ? "Please enter valid GSTIN"
                                : response?.errmsg
                            );
                            setOpen1(true);
                          }
                        } catch (error) {
                          console.log(error);
                        }
                      }
                    }}
                  />
                )}
                <DropInput
                  label={"CUSTOMER NAME"}
                  id="cust"
                  placeholder={"CUSTOMER NAME"}
                  value={addDataHead?.customer_name}
                  onChange={(e) => {
                    const data = transition_complete_list?.Customer?.filter(
                      (item) => item?.ID == e.target.value
                    );
                    const data1 = cust_vend_list?.filter(
                      (item) => item?.ID == e.target.value
                    );
                    setAddDataHead({
                      ...addDataHead,
                      address: data1?.length > 0 ? data1[0]?.cvo_address : "",
                      ref_no: "",
                      mobile: data1?.length > 0 ? data1[0]?.CVO_CONTACT : "",
                      state_code: data1?.length > 0 ? data1[0]?.B_STATE : "",
                      gst_num: data1?.length > 0 ? data1[0]?.CVO_TIN : "",
                      district: data1?.length > 0 ? data1[0]?.B_DISTRICT : "",
                      location: data1?.length > 0 ? data1[0]?.B_LOCATION : "",
                      pincode: data1?.length > 0 ? data1[0]?.B_PINCODE : "",
                      state_name:
                        data1?.length > 0 ? data1[0]?.B_STATE_NAME : "",
                      location: data1?.length > 0 ? data1[0]?.B_LOCATION : "",
                      customer_name: data[0]?.cvo_name || e.target.value,
                      customer_id: data?.length == 0 ? 0 : e.target.value,
                      cust_reg_gst:
                        data?.length > 0 ? data[0]?.IS_GST_REG : "NO",
                      delivery_godown_id: "",
                      type:
                        e.target.value != ""
                          ? user_turnover < 4 && data?.[0]?.ANNUAL_TURNOVER == 1
                            ? "NA" // If user_turnover is less than 10 CR and VENDOR ANNUAL_TURNOVER is > 10 CR, return "NA"
                            : // user_turnover >= 4 &&
                            data?.[0]?.ANNUAL_TURNOVER == 1 &&
                              data?.[0]?.ANNUAL_PURCHASE == 1
                            ? "TCS" // If  VENDOR ANNUAL_TURNOVER is > 10 CR, and VENDOR ANNUAL_PURCHASE is > 50 LAKHS, return "TCS"
                            : user_turnover >= 4 &&
                              data?.[0]?.ANNUAL_TURNOVER == 0 &&
                              data?.[0]?.ANNUAL_PURCHASE == 1
                            ? "TDS" // If user_turnover is greater than or equal to 10 CR, VENDOR ANNUAL_PURCHASE is > 50 LAKHS, and ANNUAL_TURNOVER is < 10 CR, return "TDS"
                            : "NA"
                          : "",

                      ship_name: "",
                      ship_address: "",
                      ship_location: "",
                      ship_district: "",
                      ship_pincode: "",
                      ship_state_code: "",
                      ship_state_name: "",
                      c_type:
                        data?.length > 0
                          ? data?.[0]?.connection_type
                          : e.target.value != ""
                          ? "lp"
                          : "",
                      c_type_dis: addDataHead?.cust_reg == "1" ? true : false,
                    });
                  }}
                  list={
                    addDataHead?.cust_reg == "1"
                      ? transition_complete_list?.Customer?.filter(
                          (v, i) => v?.COM == "1"
                        )?.map((v, i) => (
                          <option key={i} value={v?.ID}>
                            {v?.cvo_name?.toUpperCase()}
                          </option>
                        ))
                      : ""
                  }
                />
                {addDataHead?.cust_reg == "0" && (
                  <>
                    <Input
                      label={"ADDRESS"}
                      placeholder={"ADDRESS"}
                      value={addDataHead?.address}
                      onChange={(e) => {
                        if (e.target.value.length <= 200) {
                          setAddDataHead({
                            ...addDataHead,
                            address: e.target.value,
                          });
                        }
                      }}
                    />
                  </>
                )}
                {addDataHead?.cust_reg == "1" && (
                  <Dropdown
                    width2=""
                    label={"PURCHASE ORDER NO"}
                    value={addDataHead?.purchase_order}
                    onChange={(e) => {
                      if (
                        addDataHead?.inv_date &&
                        addDataHead?.inv_date?.length == 10
                      ) {
                        const data = customer_purchase_list?.filter(
                          (v) => v?.PO_NO == e.target.value
                        );
                        setAddDataHead({
                          ...addDataHead,
                          purchase_order: e.target.value,
                          purchase_order_id:
                            data?.length > 0 ? data?.[0]?.ID : "",
                          purchase_order_date:
                            data?.length > 0 ? data?.[0]?.PO_Date : "",
                        });
                        addData.forEach((data, index) => {
                          setAddData((prevData) => {
                            return prevData.map((item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  product_code: "",
                                  p_gst: "",
                                  unit_rate: "",
                                  tds_amt: "",
                                  amount: "",
                                };
                              }
                              return item;
                            });
                          });
                        });
                      } else {
                        setAddDataHead({
                          ...addDataHead,
                          purchase_order: "",
                        });
                        setMess("Please Select Invoice Date first");
                        setOpen1(true);
                      }
                    }}
                    options={
                      <>
                        <option value={""}>SELECT</option>
                        {customer_purchase_list
                          ?.filter(
                            (t, i) => t?.CUSTOMER_ID == addDataHead?.customer_id
                          )
                          ?.filter(
                            (n) =>
                              DateValidator2(
                                moment(addDataHead?.inv_date).format(
                                  "DD/MM/YYYY"
                                ),
                                moment(n?.PO_VALID_UPTO).format("DD/MM/YYYY"),
                                moment(n?.PO_Date).format("DD/MM/YYYY")
                              ) === true
                          )
                          .map((v, i) => (
                            <option value={v?.PO_NO} key={i}>
                              {v?.PO_NO} - {v?.CUSTOMER_NAME}
                            </option>
                          ))}
                      </>
                    }
                  />
                )}
                <Dropdown
                  width2=""
                  label={"PAYMENT TERMS"}
                  disabled={addDataHead?.payment_terms_dis}
                  value={addDataHead?.payment_terms}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      payment_terms: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"1"}>CASH</option>
                      <option value={"2"}>CREDIT</option>
                    </>
                  }
                />
                {addDataHead?.payment_terms == "1" && (
                  <>
                    <Input
                      label={"CASH RECIEVED"}
                      placeholder={"CASH RECIEVED"}
                      value={addDataHead?.cash_recieved}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          (inputValue?.length <= 10 &&
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            )) ||
                          inputValue === "" ||
                          inputValue === "-" ||
                          !addDataHead?.cash_recieved
                        ) {
                          setAddDataHead({
                            ...addDataHead,
                            cash_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) || inputValue === ""
                                ? inputValue
                                : "",
                            online_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) ||
                              inputValue === "" ||
                              inputValue === "-"
                                ? Number(
                                    Number(addDataHead?.total_new || 0) -
                                      Number(
                                        inputValue == "-" ? 0 : inputValue || 0
                                      )
                                  )?.toFixed(2)
                                : "",
                          });
                        }
                      }}
                    />
                    <Input
                      label={"ONLINE RCVD AMOUNT"}
                      placeholder={"ONLINE RCVD AMOUNT"}
                      value={addDataHead?.online_recieved}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          (inputValue?.length <= 10 &&
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            )) ||
                          inputValue === "" ||
                          inputValue === "-" ||
                          !addDataHead?.online_recieved
                        ) {
                          setAddDataHead({
                            ...addDataHead,
                            online_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) || inputValue == ""
                                ? inputValue
                                : "",
                            cash_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) ||
                              inputValue === "" ||
                              inputValue === "-"
                                ? Number(
                                    Number(addDataHead?.total_new || 0) -
                                      Number(
                                        inputValue == "-" ? 0 : inputValue || 0
                                      )
                                  )?.toFixed(2)
                                : "",
                          });
                        }
                      }}
                    />
                    <Input
                      placeholder={"TRANSACTION ID"}
                      disabled={addDataHead?.online_recieved <= 0}
                      label={"TRANSACTION ID"}
                      onChange={(e) => {
                        const inputValue = e.target.value?.toUpperCase();
                        if (
                          inputValue.length <= 15 &&
                          (alfaNumericWithoutSpace.test(inputValue) ||
                            inputValue === "")
                        ) {
                          setAddDataHead({
                            ...addDataHead,
                            transaction_id: alfaNumericWithoutSpace.test(
                              inputValue
                            )
                              ? inputValue
                              : "",
                          });
                        }
                      }}
                      value={addDataHead?.transaction_id}
                    />
                    <Dropdown
                      disabled={addDataHead?.online_recieved <= 0}
                      width2=""
                      label={"CASH RECIVED FROM"}
                      value={addDataHead?.cash_recieved_from}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          cash_recieved_from: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value="">SELECT</option>
                          <option value="1">Phonepay</option>
                          <option value="2">Google Pay</option>
                          <option value="3">Paytm</option>
                          <option value="4">Cred</option>
                          <option value="5">Others</option>
                        </>
                      }
                    />

                    <Dropdown
                      disabled={addDataHead?.online_recieved <= 0}
                      width2=""
                      label={"BANK ACCOUNT"}
                      value={addDataHead?.bank_account}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          bank_account: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value="">SELECT</option>
                          {bank_master_list
                            ?.filter(
                              (v) =>
                                v?.BANK_CODE == "TAR ACCOUNT" ||
                                v?.BANK_CODE == "SAVINGS" ||
                                v?.BANK_CODE == "CURRENT" ||
                                v?.BANK_CODE == "OVER DRAFT"
                            )
                            ?.map((v, i) => (
                              <option key={i} value={v?.ID}>
                                {bankCode(v?.BANK_CODE)} -
                                {v?.BANK_ACC_NO == "NA"
                                  ? user_id
                                  : v?.BANK_ACC_NO}
                              </option>
                            ))}
                        </>
                      }
                    />
                  </>
                )}
                <Dropdown
                  width2=""
                  label={"DISPATCH GODOWN"}
                  value={addDataHead?.godown_id}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      godown_id: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {godown_address_list?.map((s, i) => (
                        <option key={i} value={s?.ID}>
                          {s?.UNIT_NAME}
                        </option>
                      ))}
                    </>
                  }
                />
                {addDataHead?.cust_reg == "1" && (
                  <>
                    <Dropdown
                      width2=""
                      label={"SHIP TO"}
                      value={addDataHead?.delivery_godown_id}
                      onChange={(e) => {
                        const data = cust_vend_list
                          ?.filter((item) => item?.CVO_CAT == "1")
                          .filter(
                            (item) => item?.ID == addDataHead?.customer_id
                          )?.[0]
                          ?.shipping_addresses?.filter(
                            (v, i) => v?.ID == e.target.value
                          )?.[0];
                        setAddDataHead({
                          ...addDataHead,
                          delivery_godown_id: e.target.value,
                          ship_name: data ? data?.UNIT_NAME : "",
                          ship_address: data ? data?.S_ADDRESS : "",
                          ship_location: data ? data?.S_LOCATION : "",
                          ship_district: data ? data?.S_DISTRICT : "",
                          ship_pincode: data ? data?.S_LOCATION : "",
                          ship_state_code: data ? data?.S_STATE : "",
                          ship_state_name: data ? data?.S_DISTRICT : "",
                        });
                      }}
                      options={
                        <>
                          <option value={""}>SELECT</option>

                          {cust_vend_list
                            ?.filter((item) => item?.CVO_CAT == "1")
                            .filter(
                              (item) => item?.ID == addDataHead?.customer_id
                            )?.[0]
                            ?.shipping_addresses?.map((s, i) => (
                              <option key={i} value={s?.ID}>
                                {s?.UNIT_NAME}
                              </option>
                            ))}
                        </>
                      }
                    />
                  </>
                )}
                <Dropdown
                  width2=""
                  label={"VEHICLE NUMBER"}
                  value={addDataHead?.vehical_number}
                  onChange={(e) => {
                    const data = vehical_list?.filter(
                      (item, s) => item?.ID == e.target.value
                    );
                    setAddDataHead({
                      ...addDataHead,
                      vehical_number: e.target.value,
                      vehical_number_name:
                        data?.length > 0 ? data[0]?.VEHICLE_NO : "",
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {vehical_list?.map((item, s) => (
                        <option value={item.ID} key={s}>
                          {item.VEHICLE_NO}
                        </option>
                      ))}
                    </>
                  }
                />
                <Input
                  label={"SALES INVOICE AMOUNT"}
                  placeholder={"INV AMOUNT"}
                  disabled={true}
                  value={addDataHead?.inv_amount}
                />
              </div>
              <div
                style={{ marginTop: "1rem", gap: "2rem" }}
                className="flex aic gap10"
              >
                <div className="flex aic gap10 ">
                  <RadioBtn label={"SALE TYPE"} />
                  <Radio
                    disabled={addDataHead?.c_type_dis}
                    title={"LOCAL SALE"}
                    show={addDataHead.c_type == "lp" ? true : false}
                    setShow={() =>
                      setAddDataHead({ ...addDataHead, c_type: "lp" })
                    }
                  />
                  <Radio
                    disabled={addDataHead?.c_type_dis}
                    title={"INTER-STATE SALE"}
                    show={addDataHead.c_type == "isp" ? true : false}
                    setShow={() =>
                      setAddDataHead({ ...addDataHead, c_type: "isp" })
                    }
                  />
                </div>
                <div
                  className="flex aic gap10"
                  style={{
                    flexWrap: "wrap",
                    rowGap: "0",
                    margin: "1rem 0",
                  }}
                >
                  <RadioBtn label={"TDS/TCS"} />
                  <Radio
                    title={"TDS"}
                    disabled={addDataHead?.dis}
                    show={addDataHead?.type == "TDS" ? "TDS" : ""}
                    setShow={() => {
                      setOpen2(true);
                      setOpen2data("TDS");
                    }}
                  />
                  <Radio
                    disabled={addDataHead?.dis}
                    title={"TCS"}
                    show={addDataHead?.type == "TCS" ? "TCS" : ""}
                    setShow={() => {
                      setOpen2(true);
                      setOpen2data("TCS");
                    }}
                  />
                  <Radio
                    disabled={addDataHead?.dis}
                    title={"NA"}
                    show={addDataHead?.type == "NA" ? "NA" : ""}
                    setShow={() => {
                      setOpen2(true);
                      setOpen2data("NA");
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  maxHeight: "20%",
                  overflow: "scroll",
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Table
                  headData={[
                    "PRODUCT",
                    "GST %	",
                    "UNIT PRICE",
                    "DISCOUNT ON UNIT PRICE",
                    "QUANTITY",
                    "PSV CYLINDERS",
                    "EMPTIES RECEIVED",
                    "DELIVERED BY",
                    // "BANK ACCOUNT",
                    "AREA CODE",
                    "TRANSPORT CHARGES",
                    "TSD/TCS",
                    "TOTAL AMOUNT",
                    "NARRATION",
                    "ACTIONS",
                  ]}
                  body={addData?.map((val, ind) => (
                    <tr key={ind}>
                      <td>
                        {
                          <Dropdown
                            disabled={addData?.length > ind + 1}
                            options={
                              addDataHead?.cust_reg == "1" &&
                              addDataHead?.purchase_order?.length != 0 ? (
                                <>
                                  <option value={""}>SELECT</option>
                                  {customer_purchase_list
                                    ?.filter(
                                      (v) =>
                                        v?.PO_NO == addDataHead?.purchase_order
                                    )?.[0]
                                    ?.products?.filter(
                                      (vat) => vat?.CAT_NAME == "COMMERCIAL"
                                    )
                                    .map((it, i) => (
                                      <option
                                        disabled={
                                          addData?.filter(
                                            (d) => d?.product_code == it?.CODE
                                          )?.length > 0
                                        }
                                        key={i}
                                        value={it?.CODE}
                                      >
                                        {it?.CAT_NAME} - {it?.CAT_DESC}
                                      </option>
                                    )) ||
                                    refill_price_list
                                      .filter(
                                        (t) => t?.cat_name == "COMMERCIAL"
                                      )
                                      .map((v, i) => (
                                        <option
                                          disabled={
                                            addData?.filter(
                                              (d) => d?.product_code == v?.pcode
                                            )?.length > 0
                                          }
                                          value={v.pcode}
                                          key={i}
                                        >
                                          {v.cat_desc}
                                        </option>
                                      ))}
                                </>
                              ) : (
                                <>
                                  <option value={""}>SELECT</option>
                                  {refill_price_list
                                    .filter((t) => t?.cat_name == "COMMERCIAL")
                                    .map((v, i) => (
                                      <option
                                        disabled={
                                          addData?.filter(
                                            (d) => d?.product_code == v?.pcode
                                          )?.length > 0
                                        }
                                        value={v.pcode}
                                        key={i}
                                      >
                                        {v.cat_desc}
                                      </option>
                                    ))}
                                </>
                              )
                            }
                            onChange={(e) => {
                              const data = equipment_list?.filter(
                                (d) => d?.PROD_CODE == e.target.value
                              );
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        product_code: e.target.value,
                                        hsn:
                                          data?.length > 0
                                            ? data?.[0]?.CSTEH_NO
                                            : "",
                                        UOM:
                                          data?.length > 0
                                            ? data?.[0]?.UNITS
                                            : "",
                                        p_gst: "",
                                        unit_rate: "",
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                            value={val.product_code}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.p_gst}
                            placeholder={"GST %	"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.unit_rate}
                            placeholder={"UNIT PRICE"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.discount_price}
                            placeholder={"DISCOUNT ON UNIT PRICE"}
                            onChange={(e) => {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        discount_price: e.target.value,
                                        amount: "",
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.quantity}
                            placeholder={"Quantity"}
                            onChange={(e) => {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        quantity: e.target.value,
                                        transport_charges:
                                          Number(
                                            (e.target.value || 1) -
                                              (val?.psv_quantity || 0)
                                          ) *
                                          Number(
                                            val?.transport_charges_unit || 0
                                          ),
                                        amount: "",
                                        empty_recived:
                                          addDataHead?.cust_reg == "0"
                                            ? e.target.value
                                            : "",
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                          />
                        }
                      </td>

                      <td>
                        {
                          <Input
                            value={val?.psv_quantity}
                            disabled={addDataHead?.cust_reg == "0"}
                            placeholder={"PSV QUANTITY"}
                            onChange={(e) => {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        psv_quantity: e.target.value,
                                        transport_charges:
                                          Number(
                                            (val?.quantity || 1) -
                                              (e.target.value || 0)
                                          ) *
                                            Number(
                                              val?.transport_charges_unit || 0
                                            ) || "0",
                                        amount: "",
                                        online_rcvd_amount: "",
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.empty_recived}
                            disabled={addDataHead?.cust_reg == "0"}
                            placeholder={"EMPTIES RECEIVED"}
                            onChange={(e) => {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        empty_recived: e.target.value,
                                        amount: "",
                                        online_rcvd_amount: "",
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Dropdown
                            options={
                              <>
                                <option value={""}>SELECT</option>
                                {staff_list?.map((v, s) => (
                                  <option value={v.ID} key={s}>
                                    {v.EMP_NAME}
                                  </option>
                                ))}
                              </>
                            }
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        delivered_by: e.target.value,
                                      }
                                    : { ...v }
                                )
                              )
                            }
                            value={val.delivered_by}
                          />
                        }
                      </td>
                      {/* <td>
                        <Dropdown
                          width2=""
                          value={val?.bank_account}
                          onChange={(e) => {
                            setAddData((pre) =>
                              pre.map((v, i) =>
                                i == ind
                                  ? {
                                      ...v,
                                      bank_account: e.target.value,
                                    }
                                  : { ...v }
                              )
                            );
                          }}
                          disabled={
                            addDataHead?.payment_terms == "2" ? true : false
                          }
                          options={
                            <>
                              <option value="">SELECT</option>
                              {bank_master_list
                                ?.filter(
                                  (v) =>
                                    v?.BANK_CODE == "TAR ACCOUNT" ||
                                    v?.BANK_CODE == "SAVINGS" ||
                                    v?.BANK_CODE == "CURRENT"
                                )
                                ?.map((v, i) => (
                                  <option key={i} value={v?.ID}>
                                    {bankCode(v?.BANK_CODE)} -
                                    {v?.BANK_ACC_NO == "NA"
                                      ? user_id
                                      : v?.BANK_ACC_NO}
                                  </option>
                                ))}
                            </>
                          }
                        />
                      </td> */}
                      <td>
                        {
                          <Dropdown
                            options={
                              <>
                                <option value={""}>SELECT</option>
                                {area_code_list.map((item, s) => (
                                  <option value={item.ID} key={s}>
                                    {item.AREA_NAME}
                                  </option>
                                ))}
                              </>
                            }
                            onChange={(e) => {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        area_code: e.target.value,
                                      }
                                    : { ...v }
                                )
                              );
                              const data = area_code_list?.filter(
                                (v) => v?.ID == e.target.value
                              );
                              if (e.target.value != "" && data?.length > 0) {
                                fetchTransCharge(ind, data[0]?.AREA_CODE);
                              }
                            }}
                            value={val.area_code}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={
                              Number(val?.transport_charges) == NaN
                                ? "0.00"
                                : val?.transport_charges
                            }
                            placeholder={"TRANSPORT CHARGES"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.tds_amt}
                            placeholder={"TDS Amount"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.amount}
                            placeholder={"Sale Amount"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.narration}
                            placeholder={"NARRATION"}
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        narration: e.target.value,
                                      }
                                    : { ...v }
                                )
                              )
                            }
                          />
                        }
                      </td>
                      <td>
                        {
                          <DeleteIcon
                            onClick={() => {
                              setAddData((pre) =>
                                pre.filter((v, i) => i != ind)
                              );
                              setAddDataHead({
                                ...addDataHead,
                                inv_amount: "",
                                cash_recieved: "",
                                online_recieved: "",
                              });
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <Button
                  title={"ADD"}
                  onClick={async () => {
                    const data = await validation_check();
                    data &&
                      setMess(
                        "Please enter all the values in current row,calculate and then add next row"
                      );
                    data
                      ? setOpen1(true)
                      : setAddData((pre) => [...pre, { psv_quantity: 0 }]);
                  }}
                />
                <Button
                  title={"FETCH INVOICE NUMBER"}
                  back
                  onClick={FetchVoucherNumber}
                />
                <Button title={"FETCH GST"} back onClick={() => FetchGST()} />
                <Button title={"CALCULATE"} back onClick={() => calculate()} />
                {/* <SaveButton onClick={() => add_items()} /> */}
                <Button
                  title={"PREVIEW DATA AND SAVE"}
                  back
                  onClick={() => preview_inv("first")}
                />
              </div>
            </div>
          }
        ></Modal>
      )}
      {showPreview && (
        <Modal
          isOpen={showPreview}
          setIsOpen={setShowPreview}
          title={"COMMERCIAL CYLINDER SALES"}
          body={
            <div>
              <TaxInvoice3
                empty_row_height={180 - addData?.length * 25}
                view={true}
                listdata={{
                  SR_NO: addDataHead?.ref_no,
                  SR_NO_DATE: addDataHead?.inv_date,
                  REF_NUMBER: addDataHead?.purchase_order,
                  REF_DATE: addDataHead?.purchase_order_date,
                  CVO_NAME: addDataHead?.customer_name,
                  CVO_ADDRESS: addDataHead?.address,
                  CVO_DISTRICT: addDataHead?.district,
                  CVO_LOCATION: addDataHead?.location,
                  CVO_PINCODE: viewItem?.pincode,
                  CVO_TIN: addDataHead?.gst_num || "NA",
                  B_STATE_CODE: addDataHead?.state_code,
                  B_STATE_NAME: addDataHead?.state_name,
                  SHIP_CVO_NAME: addDataHead?.ship_name,
                  SHIP_CVO_ADDRESS: addDataHead?.ship_address,
                  SHIP_CVO_DISTRICT: viewItem?.ship_location,
                  SHIP_CVO_LOCATION: viewItem?.ship_district,
                  SHIP_B_STATE: addDataHead?.ship_state_code,
                  SHIP_B_STATE_NAME: addDataHead?.ship_state_name,
                  SHIP_CVO_PINCODE: viewItem?.ship_pincode,
                  SHIP_CVO_TIN: addDataHead?.gst_num || "NA",
                  MOBILE: addDataHead?.mobile,
                }}
                table_body={
                  <>
                    {addData?.map((v, i) => (
                      <tr key={i} className="both">
                        <td className="both">{i + 1}</td>
                        <td
                          className="both"
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            minWidth: "250px",
                          }}
                        >
                          {(() => {
                            const data = equipment_list?.filter(
                              (d) => d?.PROD_CODE == v?.product_code
                            );
                            if (data?.length > 0) {
                              return `${data[0]?.CAT_NAME} - ${data[0]?.product_name}`;
                            } else {
                              return "";
                            }
                          })()}
                        </td>
                        <td className="both">{v?.hsn || "NA"}</td>
                        <td className="both">
                          {Number(v?.quantity) - Number(v?.psv_quantity)}{" "}
                          {UnitFunction(v?.UNITS || v?.UOM, "title")}
                        </td>
                        <td className="both">{v?.unit_rate}</td>
                        <td className="both">{v?.discount_price}</td>
                        <td className="both">{v?.p_gst} %</td>
                        <td className="both" style={{ textAlign: "right" }}>
                          {v?.tax_amount}
                        </td>
                      </tr>
                    ))}
                  </>
                }
                net_total={Number(addDataHead?.inv_amount).toFixed(2)}
                taxable_amt={ReduceFunction(addData, "tax_amount")}
                Total_tax={(
                  Number(ReduceFunction(addData, "igst")) +
                  Number(ReduceFunction(addData, "cgst")) +
                  Number(ReduceFunction(addData, "sgst"))
                ).toFixed(2)}
                igst={ReduceFunction(addData, "igst")}
                cgst={ReduceFunction(addData, "cgst")}
                sgst={ReduceFunction(addData, "sgst")}
                // other={(
                //   Number(ReduceFunction(addData, "transport_charges")) +
                //   (Number(ReduceFunction(addData, "transport_charges")) * 18) /
                //     100
                // ).toFixed(2)}
                other={(
                  Number(ReduceFunction(addData, "transport_charges")) +
                  (Number(ReduceFunction(addData, "transport_charges")) * 18) /
                    100 +
                  (addDataHead?.type == "TDS"
                    ? Number(ReduceFunction(addData, "tds_amt"))
                    : addDataHead?.type == "TCS"
                    ? -Number(ReduceFunction(addData, "tds_amt"))
                    : 0)
                ).toFixed(2)}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: "1rem",
                  padding: "0 5% 0 5%",
                }}
              >
                <div
                  style={{
                    width: "500px",
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    title={"CANCEL"}
                    onClick={() => {
                      setShowPreview(false);
                    }}
                  />
                  <SaveButton onClick={() => add_items("first")} />
                </div>
              </div>
            </div>
          }
        />
      )}

      <div style={{ display: "none" }}>
        <TaxInvoice3
          empty_row_height={180 - viewItem?.products?.length * 25}
          previewRef={previewRef}
          irn={viewItem?.IRN}
          listdata={{
            SR_NO: viewItem?.SR_NO,
            SR_NO_DATE: viewItem?.SI_DATE,
            REF_NUMBER: viewItem?.PO_NO,
            REF_DATE: viewItem?.PO_DATE,
            CVO_NAME: viewItem?.cvo_name,
            CVO_ADDRESS: viewItem?.CVO_ADDRESS || viewItem?.BILLING_ADDRESS,
            CVO_DISTRICT: viewItem?.B_DISTRICT,
            CVO_LOCATION: viewItem?.B_LOCATION,
            B_STATE_CODE: viewItem?.B_STATE,
            B_STATE_NAME: viewItem?.B_STATE_NAME,
            CVO_PINCODE: viewItem?.PINCODE,
            CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
            EMAIL: viewItem?.CVO_EMAIL,
            MOBILE: viewItem?.CVO_CONTACT,
            SHIP_CVO_NAME: viewItem?.cvo_name,
            SHIP_CVO_ADDRESS: viewItem?.S_ADDRESS || viewItem?.BILLING_ADDRESS,
            SHIP_CVO_DISTRICT: viewItem?.S_DISTRICT,
            SHIP_CVO_LOCATION: viewItem?.S_LOCATION,
            SHIP_B_STATE: viewItem?.S_STATE,
            SHIP_B_STATE_NAME: viewItem?.S_STATE_NAME,
            SHIP_CVO_PINCODE: viewItem?.S_PINCODE,
            SHIP_CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
            vehicle_num: viewItem?.vehicle_number,
            // Other info
            irn_date: viewItem?.ACK_DATE,
            eway_bill: viewItem?.EWAY_BILL_NO,
            eway_bill_date: viewItem?.EWAY_BILL_DATE,
            irn_qr: viewItem?.QRCODE_URL,
            pay_terms:
              viewItem?.PAYMENT_TERMS == "1"
                ? "Cash"
                : viewItem?.PAYMENT_TERMS == "2"
                ? "Credit"
                : "",
          }}
          table_body={
            <>
              {viewItem?.products?.map((v, i) => (
                <tr key={i}>
                  <td className="both">{i + 1}</td>
                  <td
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      minWidth: "250px",
                    }}
                    className="both"
                  >{`${v?.CAT_NAME} - ${v?.CAT_DESC}`}</td>
                  <td className="both">{v?.HSNCODE}</td>
                  <td className="both">
                    {Number(v?.QUANTITY) - Number(v?.PSV_CYLS)}{" "}
                    {UnitFunction(v?.UNITS || v?.UOM, "title")}
                  </td>
                  <td className="both">{v?.UNIT_RATE}</td>
                  <td className="both">{v?.DISC_UNIT_RATE}</td>
                  <td className="both">{v?.GSTP} %</td>
                  <td className="both" style={{ textAlign: "right" }}>
                    {(
                      (Number(v?.QUANTITY) - Number(v?.PSV_CYLS)) *
                      (Number(v?.UNIT_RATE) - Number(v?.DISC_UNIT_RATE))
                    ).toFixed(2)}
                  </td>
                </tr>
              ))}
            </>
          }
          Total_tax={ReduceFunction(viewItem?.hsn_groups, "TOTAL_AMOUNT")}
          net_total={Number(viewItem?.SI_AMOUNT).toFixed(2)}
          taxable_amt={ReduceFunction(viewItem?.products, "TAXABLE_AMOUNT")}
          igst={ReduceFunction(viewItem?.products, "IGST_AMOUNT")}
          cgst={ReduceFunction(viewItem?.products, "CGST_AMOUNT")}
          sgst={ReduceFunction(viewItem?.products, "SGST_AMOUNT")}
          other={(
            Number(ReduceFunction(viewItem?.products, "TRANSPORT_CHARGES")) +
            (Number(ReduceFunction(viewItem?.products, "TRANSPORT_CHARGES")) *
              18) /
              100 +
            (viewItem?.SYNC_FLAG == 1
              ? Number(ReduceFunction(viewItem?.products, "TCS_AMOUNT"))
              : viewItem?.SYNC_FLAG == 2
              ? -Number(ReduceFunction(viewItem?.products, "TCS_AMOUNT"))
              : 0)
          ).toFixed(2)}
        />
      </div>
      {cvoClick && <CVOList cvoClick={cvoClick} setCvoClick={setCvoClick} />}
      {EquipClick && (
        <EquipmentList EquipClick={EquipClick} setEquipClick={setEquipClick} />
      )}
      {StaffClick && (
        <StaffList StaffClick={StaffClick} setStaffClick={setStaffClick} />
      )}
      {PriceClick && (
        <PriceList PriceClick={PriceClick} setPriceClick={setPriceClick} />
      )}
      {AreaClick && (
        <AreaCodeList AreaClick={AreaClick} setAreaClick={setAreaClick} />
      )}

      {openView && (
        <Modal
          isOpen={openView}
          onCancel={() => {
            setViewItem({});
          }}
          header_right={
            <div className="flex gap10 ">
              {viewItem?.EWAY_BILL_NO && (
                <Button
                  style={{ cursor: "pointer" }}
                  title={"CANCLE E-WAY BILL"}
                  onClick={() => cancle_e_way_bill(viewItem?.EWAY_BILL_NO)}
                />
              )}

              {viewItem?.IRN && (
                <Button
                  style={{ cursor: "pointer" }}
                  title={"CANCLE E-INVOICE "}
                  onClick={() => cancle_e_invoice(viewItem?.IRN)}
                />
              )}

              <ReactToPrint
                trigger={() => (
                  <Button style={{ cursor: "pointer" }} title={"PRINT"} />
                )}
                content={() => previewRef.current}
                documentTitle={`${viewItem?.cvo_name || viewItem?.CUST_NAME}-${
                  viewItem?.SR_NO
                }`}
              />
            </div>
          }
          title={viewItem?.cvo_name || viewItem?.CUST_NAME}
          setIsOpen={setOpenView}
          body={
            <>
              {/* {viewItem?.CUSTOMER_STATUS == "2" && ( */}
              {/* <>
                {viewItem?.CUST_GSTIN && (
                  <div className="rowtxt">
                    <div
                      className="card-label card-label-width"
                      style={{ maxWidth: "100px", minWidth: "100px" }}
                    >
                      GSTIN No.
                    </div>
                    <div className="card-label">:</div>
                    <div className="card-title" style={{ textWrap: "wrap" }}>
                      {viewItem?.CUST_GSTIN}
                    </div>
                  </div>
                )}
                {viewItem?.BILLING_ADDRESS && (
                  <div className="rowtxt">
                    <div
                      className="card-label card-label-width"
                      style={{ maxWidth: "100px", minWidth: "100px" }}
                    >
                      Address
                    </div>
                    <div className="card-label">:</div>
                    <div className="card-title" style={{ textWrap: "wrap" }}>
                      {viewItem?.BILLING_ADDRESS}
                    </div>
                  </div>
                )}
              </> */}
              {viewItem?.CUSTOMER_ID == "0" && (
                <>
                  {viewItem?.CUST_GSTIN && (
                    <div className="rowtxt">
                      <div
                        className="card-label "
                        style={{ maxWidth: "100px", minWidth: "100px" }}
                      >
                        GSTIN No.
                      </div>
                      <div className="card-label">:</div>
                      <div className="card-title" style={{ textWrap: "wrap" }}>
                        {viewItem?.CUST_GSTIN}
                      </div>
                    </div>
                  )}
                  <div className="rowtxt">
                    <div
                      className="card-label "
                      style={{ maxWidth: "100px", minWidth: "100px" }}
                    >
                      Address
                    </div>
                    <div className="card-label">:</div>
                    <div className="card-title" style={{ textWrap: "wrap" }}>
                      {viewItem?.BILLING_ADDRESS}
                    </div>
                  </div>
                </>
              )}
              {/* )} */}
              <Table
                mt={10}
                headData={[
                  "PRODUCT",
                  "DELIVERED BY",
                  // "BANK ACCOUNT",
                  "AREA CODE",
                  "NARRATION",
                  "GST%",
                  "UNIT PRICE",
                  "DISCOUNT ON UNIT PRICE",
                  "QUANTITY",
                  "PSV CYLINDERS",
                  "EMPTIES RECEIVED",
                  "TRANSPORT CHARGES",
                  "TDS/TCS",
                  "TOTAL AMOUNT",
                ]}
                body={
                  <>
                    {viewItem?.products?.map((v, i) => (
                      <tr key={i}>
                        <td>{`${v?.CAT_NAME} - ${v?.CAT_DESC}`}</td>
                        <td>{v?.EMP_NAME}</td>
                        {/* <td>{v?.BANK_ACC_NO || "NA"}</td> */}
                        <td>{v?.AREA_CODE}</td>
                        <td>{v?.NARRATION}</td>
                        <td>{v?.GSTP}</td>
                        <td>{Number(v?.UNIT_RATE).toFixed(2)}</td>

                        <td>{Number(v?.DISC_UNIT_RATE).toFixed(2)}</td>
                        <td>{v?.QUANTITY}</td>
                        <td>{Number(v?.PSV_CYLS).toFixed(0)}</td>
                        <td>{Number(v?.EMPTY_RECEIVED).toFixed(0)}</td>
                        <td>{Number(v?.TRANSPORT_CHARGES).toFixed(2)}</td>
                        <td>
                          {viewItem?.SYNC_FLAG == "2" ? "-" : ""}{" "}
                          {Number(v?.TCS_AMOUNT).toFixed(2)}
                        </td>
                        <td>{Number(v?.SALE_AMOUNT).toFixed(2)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={9} />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        TOTAL
                      </td>
                      <td />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v?.TRANSPORT_CHARGES)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v?.SALE_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                    </tr>
                  </>
                }
              />
            </>
          }
        ></Modal>
      )}

      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => onDelete_item()}
        />
      )}
      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}

      {open4 && (
        <SmallModal
          bname={"Yes"}
          cname={"No"}
          title={"Confirm"}
          isOpen={open4}
          setIsOpen={setOpen4}
          mess={mess}
          onClick={() => {
            preview_inv();
          }}
        />
      )}

      {open2 && (
        <SmallModal
          title={"Confirm"}
          isOpen={open2}
          setIsOpen={setOpen2}
          mess={"Are you sure? You want to Change financial Status ?"}
          bname={"SUBMIT"}
          onClick={() => {
            setAddDataHead({ ...addDataHead, type: open2data });
            setOpen2(false);
            setOpen2data("");
            addData.forEach((data, index) => {
              setAddData((prevData) => {
                return prevData.map((item, i) => {
                  if (i === index) {
                    return {
                      ...item,
                      tax_amount: "",
                      gst_amount: "",
                      amount: "",
                      tcs_tds: "",
                    };
                  }
                  return item;
                });
              });
            });
          }}
        />
      )}

      {loading && <Loader />}
    </div>
  );
};
