import React, { useState } from "react";
import PageHeading from "../../components/PageHeading";
import { BackButton, Button, HelpButton } from "../../components/btn/Buttons";
import Dropdown from "../../components/inputs/Dropdown";
import Input from "../../components/inputs/Input";
import "./Gst.css";
import { useSelector } from "react-redux";
import SmallModal from "../../components/modal/SmallModal";
import { Alerts } from "../../components/Alerts/Alerts";
import { gstr_3_API } from "../../utils/apis";
import Loader from "../../components/Loader";
import Table from "../../components/table/Table";
import NoData from "../../components/NoData";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";

import excelData from "../../assets/GSTR3.xlsx";

import { YearData, monthData } from "../../utils/data";
import axios from "axios";
import { base_url } from "../../utils/utils";
const GSTR3 = () => {
  const [addData, setAddData] = useState({});
  const [showData, setShowData] = useState(false);
  const [addDataPre, setAddDataPre] = useState({});
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [list, setList] = useState([]);
  const [calculationData, setCalculationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showCal, setShowCal] = useState(false)

  const [url, setUrl] = useState("");
  const [total_gst_summary, setTotal_gst_summary] = useState({});

  const { user_id } = useSelector((state) => state.other);

  const validate = () => {
    const data = [];
    if (!addData?.month || (addData?.month && addData?.month?.length == 0)) {
      data.push("Please select Month");
    }
    if (!addData?.year || (addData?.year && addData?.year?.length == 0)) {
      data.push("Please select Year");
    }
    return data;
  };
  const fetchGSTR3 = async () => {
    const data = validate();
    if (data?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("agencyid", user_id);
        body.append("action", "generate");
        body.append("month", addData?.month);
        body.append("year", addData?.year);
        body.append("C_IGST", addData?.igst || "");
        body.append("C_SGST", addData?.sgst || "");
        body.append("C_CGST", addData?.cgst || "");
        const res = await gstr_3_API(body);
        setLoading(false);
        if (res.success) {
          setAddDataPre(addData);
          setAddData({
            month: "",
            year: "",
            igst: "",
            cgst: "",
            sgst: "",
          });
          setUrl(res.file);
          setList(res?.data);
          setShowData(true);
          setCalculationData(res?.calculation);
          setTotal_gst_summary(res.total_gst_summary);
        } else if (!res.success) {
          setOpen(true);
          setMess(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen(true);
      setMess(<Alerts data={data} />);
    }
  };

  const handleExport = async () => {
    try {
      const base_url_new = base_url;
      const a = document.createElement("a");
      const fullUrl = base_url_new + url;
      const newWindow = window.open(fullUrl, "_blank");
      newWindow.onload = function () {
        const downloadLink = newWindow.document.createElement("a");
        downloadLink.href = fullUrl;
        downloadLink.download = "GSTR-3B-Filled.xlsx";
        downloadLink.click();
        newWindow.close();
      };
    } catch (error) {
      console.error("Error loading the template:", error);
    }
  };

  return (
    <div className="container">
      <div className="ReportsMain">
        <PageHeading
          title={"GSTR3 REPORT :"}
          right={
            <div className="ReportMenu-btn">
              <div>
                <BackButton />
              </div>
              <div>
                <HelpButton
                  link={[
                    "https://youtu.be/A971FsmllM4",
                    "https://youtu.be/v5AAVwSlxqs",
                  ]}
                />
              </div>
            </div>
          }
        />
        <div style={{ height: "30px" }}></div>
        <p style={{ fontSize: "11px" }}>
          Please Enter Your Monthly Carry forwards of GST in Creditors IGST,
          CGST and SGST :
        </p>

        <div>
          <div style={{ height: "10px" }}></div>
          <div className="tb1">
            <div className="GSTR1Mian">
              <div className="DR">
                <Dropdown
                  label={"MONTH"}
                  value={addData?.month}
                  onChange={(e) => {
                    const input = e.target.value;
                    setAddData({ ...addData, month: input });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {monthData.map((v, i) => (
                        <option value={v?.id} key={i}>
                          {v?.label}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>

              <div className="DR">
                <Dropdown
                  label={"YEAR"}
                  value={addData?.year}
                  onChange={(e) => {
                    const input = e.target.value;
                    setAddData({ ...addData, year: input });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {YearData.map((v, i) => (
                        <option value={v?.id} key={i}>
                          {v?.lable}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <div className="df gap10">
                  <Input
                    value={addData?.igst}
                    onChange={(e) => {
                      const input = e.target.value;
                      setAddData({ ...addData, igst: input });
                    }}
                    label={"Creditors IGST"}
                    type={"text"}
                    placeholder={"IGST"}
                  />
                  <Input
                    value={addData?.cgst}
                    onChange={(e) => {
                      const input = e.target.value;
                      setAddData({ ...addData, cgst: input });
                    }}
                    label={"Creditors CGST"}
                    type={"text"}
                    placeholder={"CGST"}
                  />
                  <Input
                    value={addData?.sgst}
                    onChange={(e) => {
                      const input = e.target.value;
                      setAddData({ ...addData, sgst: input });
                    }}
                    label={"Creditors SGST"}
                    type={"text"}
                    placeholder={"SGST"}
                  />
                </div>
                <button className="GST-btn" onClick={fetchGSTR3}>
                  FETCH GSTR3
                </button>
                <button
                  className="GST-btn"
                  onClick={() => {
                    if (showData) {
                      handleExport();
                    }
                  }}
                >
                  GENERATE GSTR3
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showData && (
        <>
          <div className="SFromDate">
            <span style={{ fontSize: "16px" }}>Month :</span>
            <p className="FDATE">
              {monthData?.filter((v) => v?.id == addDataPre?.month)?.[0]?.label}
            </p>
            <span style={{ fontSize: "16px" }}>Year :</span>
            <p className="FDATE">{addDataPre?.year}</p>
          </div>

          {list?.length == 0 ? (
            <NoData mt={30} />
          ) : (
            <>
              <Table
                headers={
                  <thead>
                    <tr>
                      <th rowspan="2">
                        <font>GST RATE</font>
                      </th>
                      <th rowspan="2">
                        <font>TAXABLE</font>
                      </th>
                      <th colspan="4">
                        <font>PURCHASE GST</font>
                      </th>
                      <th rowspan="2">
                        <font>TAXABLE</font>
                      </th>
                      <th colspan="4">
                        <font>SALE GST</font>
                      </th>
                      <th rowspan="2">
                        <font>NET GST</font>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <font>IGST</font>
                      </th>
                      <th>
                        <font>CGST</font>
                      </th>
                      <th>
                        <font>SGST</font>
                      </th>
                      <th>
                        <font>TOTAL</font>
                      </th>
                      <th>
                        <font>IGST</font>
                      </th>
                      <th>
                        <font>CGST</font>
                      </th>
                      <th>
                        <font>SGST</font>
                      </th>
                      <th>
                        <font>TOTAL</font>
                      </th>
                    </tr>
                  </thead>
                }
                body={
                  <>
                    {list?.map((v, i) => (
                      <tr key={i}>
                        <td>{v?.["GST RATE"]}</td>
                        <td>{v?.["PURCHASE TAXABLE"]}</td>
                        <td>{v?.["PURCHASE IGST"]}</td>
                        <td>{v?.["PURCHASE CGST"]}</td>
                        <td>{v?.["PURCHASE SGST"]}</td>
                        <td>{v?.["TOTAL PURCHASE GST"]}</td>
                        <td>{v?.["SALES TAXABLE"]}</td>
                        <td>{v?.["SALES IGST"]}</td>
                        <td>{v?.["SALES CGST"]}</td>
                        <td>{v?.["SALES SGST"]}</td>
                        <td>{v?.["TOTAL SALES GST"]}</td>
                        <td>{v?.["NET GST"]}</td>
                      </tr>
                    ))}
                  </>
                }
              />
              <div style={{ marginTop: "2rem" }} className="ReportMenu-btn">
                <Button
                  disabled={true}
                  bgColor={"#0797ad"}
                  title={"SAVE GST AMOUNTS"}
                />
                <Button
                  disabled={true}
                  bgColor={"#0797ad"}
                  title={"CACULATE"}
                  onClick={()=>setShowCal(true)}
                />
                <Button disabled={true} bgColor={"#0797ad"} title={"SET OFF"} />
                <Button
                  disabled={true}
                  bgColor={"#0797ad"}
                  title={"PAY OFF GST AMOUNT"}
                />
              </div>
            </>
          )}

         {
          showCal &&
          <>
           { calculationData?.length == 0 ? (
            <NoData mt={30} />
          ) : (
            <>
              <Table
                headers={
                  <thead>
                    <tr>
                      <th rowspan="2">
                        <font>DESCRIPTION</font>
                      </th>
                      <th rowspan="2">
                        <font>TAX PAYABLE</font>
                      </th>
                      <th colspan="4">
                        <font>PAID THROUGH ITC</font>
                      </th>
                      <th rowspan="2">
                        <font>TAX / CESS PAID IN CASH</font>
                      </th>
                      <th rowspan="2">
                        <font>INTREST PAID IN CASH</font>
                      </th>
                      <th rowspan="2">
                        <font>LATE FEE IN CASH</font>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <font>IGST</font>
                      </th>
                      <th>
                        <font>CGST</font>
                      </th>
                      <th>
                        <font>SGST</font>
                      </th>
                      <th>
                        <font>CESS</font>
                      </th>
                    </tr>
                  </thead>
                }
                body={
                  <>
                    {calculationData?.map((v, i) => (
                      <tr key={i}>
                        <td>{v?.DESCRIPTION}</td>
                        <td>{v?.Tax_Payable}</td>
                        <td>{v?.IGST}</td>
                        <td>{v?.CGST}</td>
                        <td>{v?.SGST}</td>
                        <td>{v?.CESS}</td>
                        <td>{v?.CASH}</td>
                        <td>{"0.00"}</td>
                        <td>{"0.00"}</td>
                      </tr>
                    ))}
                  </>
                }
              />
              {/*
NET GST AMOUNT: 3346069.34
TOTAL CGST: 474961.3
TOTAL GST: -2394700.74
TOTAL IGST: -3346069.34
TOTAL SGST: 476407.3
*/}
              <div style={{padding:"2rem 0 0 0"}}>GST AMOUNT PAYABLES :</div>
              <div className="gstr_main">
                <div className="gstr_1">
                  <div className="gstr_1_1">
                    <div style={{ flex: 8 }}>IGST AMOUNT</div>
                    <div style={{ flex: 1 }}>:</div>
                    <div style={{ flex: 3 }}>
                      {total_gst_summary?.["TOTAL IGST"]}
                    </div>
                  </div>
                  <div className="gstr_1_1">
                    <div style={{ flex: 8 }}>CGST AMOUNT</div>
                    <div style={{ flex: 1 }}>:</div>
                    <div style={{ flex: 3 }}>
                      {total_gst_summary?.["TOTAL CGST"]}
                    </div>
                  </div>
                  <div className="gstr_1_1">
                    <div style={{ flex: 8 }}>SGST AMOUNT</div>
                    <div style={{ flex: 1 }}>:</div>
                    <div style={{ flex: 3 }}>
                      {total_gst_summary?.["TOTAL SGST"]}
                    </div>
                  </div>
                  <div className="gstr_1_1">
                    <div style={{ flex: 8 }}>TOTAL PAYABLE GST AMOUNT</div>
                    <div style={{ flex: 1 }}>:</div>
                    <div style={{ flex: 3 }}>
                      {total_gst_summary?.["TOTAL GST"]}
                    </div>
                  </div>
                </div>
                <div className="gstr_1">
                  <div className="gstr_1">
                    <div className="gstr_1_1">
                      <div style={{ flex: 8 }}>NET GST AMOUNT</div>
                      <div style={{ flex: 1 }}>:</div>
                      <div style={{ flex: 8 }}>
                        {total_gst_summary?.["NET GST AMOUNT"]}
                      </div>
                    </div>
                    <div className="gstr_1_1">
                      <div style={{ flex: 8 }}>VERIFIED BY</div>
                      <div style={{ flex: 1 }}>:</div>
                      <div style={{ flex: 8 }}>
                        <Dropdown
                          width2=""
                          // label={"CUSTOMER REG."}
                          options={
                            <>
                              <option value={""}>SELECT</option>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          </>
         }
        </>
      )}

      {open && (
        <SmallModal
          title={"Alerts"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={mess}
          okbtn={true}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default GSTR3;
