import React, { useEffect, useState } from "react";
import { BackButton } from "../../components/btn/Buttons";
import PageHeading from "../../components/PageHeading";
import { Input } from "../../components/inputs/Inputs";
import Dropdown from "../../components/inputs/Dropdown";
import Button from "../../components/btn/Button";
import { useSelector } from "react-redux";
import Table from "../../components/table/Table";
import store from "../../redux/store";
import { get_purchase_report_list } from "./transactionReportSlice";
import { DateValidator2, DateValidatorReports } from "../../utils/validation";
import moment from "moment";
import SmallModal from "../../components/modal/SmallModal";
import Loader from "../../components/Loader";
import {
  ledger_report_api,
  ledger_report_api2,
  purchase_report_api,
} from "../../utils/apis";
import { Pagination2 } from "../../components/pagination/pagination";
import NoData from "../../components/NoData";
import { downloadExcel, exportPDF } from "../../utils/utils";
import {
  get_arb_list,
  get_master_complete_list,
} from "../MasterData/masterdataSlice";
import { get_transition_complete_list } from "../transactions/trasactionsSlice";
import {
  AHData,
  LedgerHeaders,
  MHData,
  PaymentToParty,
  UOMData,
  selectPaymentType,
} from "../../utils/data";
import { Alerts } from "../../components/Alerts/Alerts";

const Ledger = () => {
  const [addData, setAddData] = useState({});
  const [addDataPre, setAddDataPre] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [serchInput, setSerchInput] = useState("");
  const [showData, setShowData] = useState(false);
  const [ledger_report_item_list, setLedger_report_item_list] = useState([]);
  const [totalData, setTotalData] = useState({});
  const { master_complete_list, arb_list } = useSelector(
    (state) => state.masterdata
  );

  const { firm_name } = useSelector((state) => state.profile);
  const { transition_complete_list } = useSelector(
    (state) => state.transactions
  );

  const { eff_date, user_id, today } = useSelector((state) => state.other);
  useEffect(() => {
    store.dispatch(get_master_complete_list());
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_arb_list());
  }, []);

  useEffect(() => {
    if (showData) {
      get_list(addDataPre, "");
    }
  }, [pageNo, entries]);

  const table_data = (data, total, addData) => {
    const filterData = data?.map((v, i) => {
      if (addData?.type == "1") {
        return [
          i + 1,
          // moment(Number(v?.TRANS_DATE)).format("DD/MM/YYYY"),
          v?.TRANS_DATE,
          v?.INV_NO,
          v?.GODOWN_NAME || "-",
          v?.TRANS_TYPE == "1" ? "PURCHASE" : "SALE",
          v?.FULLS || "-",
          v?.PARTY_NAME,
          v?.CS_FULLS,
          v?.CS_EMPTYS,
          v?.DISCOUNT,
          v?.CREATED_DATE,
          v?.CREATED_NAME
        ];
      } else if (addData?.type == "2") {
        return [
          i + 1,
          // moment(Number(v?.INV_DATE)).format("DD/MM/YYYY"),
          v?.INV_DATE,
          v?.INV_REF_NO,
          v?.TRANSACTION_TYPE,
          v?.DEBIT,
          v?.CREDIT,
          v?.CLOSING_BALANCE,
          v?.DISCOUNT,
          v?.CREATED_NAME
        ];
      } else if (addData?.type == "3") {
        return [
          i + 1,
          moment(Number(v?.T_DATE)).format("DD/MM/YYYY"),
          v?.INV_NO,
          v?.TRANSACTION_TYPE,
          v?.DEBIT,
          v?.CREDIT,
          v?.CLOSING_BALANCE,
          // v?.DISCOUNT || "",
          v?.CREATED_NAME
        ];
      } else if (addData?.type == "4") {
        return [
          i + 1,
          moment(Number(v?.PDATE)).format("DD/MM/YYYY"),
          v?.TAX_NAME,
          v?.DEBIT,
          v?.CREDIT,
          v?.BALANCE,
          v?.CREATED_NAME
        ];
      } else if (addData?.type == "5") {
        return [
          i + 1,
          moment(Number(v?.INV_DATE)).format("DD/MM/YYYY"),
          // v?.INV_DATE,
          v?.INV_REF_NO,
          v?.EXPENSE_ITEM,
          MHData.find((item) => item?.MH == v?.MINOR_HEAD)?.title,
          AHData.find((item) => item?.AH == v?.ACCOUNT_HEAD)?.title,
          v?.QUANTITY || "-",
          UOMData.find((item) => item?.UOM == v?.UOM)?.title,
          v?.UNIT_RATE,
          v?.PAYMENT_MODE,
          v?.BANK_ACCOUNT_NUMBER || "-",
          v?.TOTAL_VALUE,
          v?.CREATED_NAME
        ];
      } else if (addData?.type == "6") {
        return [
          i + 1,
          moment(Number(v?.INV_DATE)).format("DD/MM/YYYY"),
          // v?.INV_DATE,
          v?.INV_REF_NO,
          v?.INV_REF_NO,
          v?.GODOWN_NAME || "-",
          v?.TRANSACTION_TYPE,
          v?.OMC_NAME,
          `${v?.PROD_NAME} - ${v?.PROD_DESC}`,
          MHData.find((item) => item?.MH == v?.MINOR_HEAD)?.title,
          AHData.find((item) => item?.AH == v?.ACCOUNT_HEAD)?.title,
          v?.HSN_CODE,
          v?.UNIT_PRICE,
          v?.QUANTITY || "-",
          v?.GSTP || "-",
          v?.TAXABLE_AMOUNT,
          v?.IGST_AMOUNT,
          v?.CGST_AMOUNT,
          v?.SGST_AMOUNT,
          v?.OTHER_CHARGES,
          v?.TOTAL_AMOUNT,
          v?.CREATED_NAME
        ];
      }
    });
    return filterData;
  };

  const table_header = LedgerHeaders?.filter(
    (v) => v?.id == addDataPre?.type
  )?.[0]?.data;
  const export_pdf = async (addData, data, total) => {
    exportPDF({
      table_data: await table_data(data, total, addData),
      table_headers: ["S.No.", ...table_header],
      fileName: `${firm_name} - LEDGER REPORTS -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: PRODUCT / VENDOR SELECTED : : ${addData?.custProdDetails}`,
      tableName: `${firm_name} - LEDGER REPORTS -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: PRODUCT / VENDOR SELECTED : : ${addData?.custProdDetails}`,
    });
  };

  const export_excel = (addData, data, total) => {
    downloadExcel(
      table_data(data, total, addData),
      ["S.No.", ...table_header],
      `${firm_name} - LEDGER REPORTS -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: PRODUCT / VENDOR SELECTED : : ${addData?.custProdDetails}`
    );
  };

  const get_list = async (addData, data1 = "") => {
    const data = validate(addData);
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "fetchledgerreport");
        body.append("agencyid", user_id);
        body.append("fd", addData?.from_date);
        body.append("td", addData?.to_date);
        // body.append("page", data1?.length > 1 ? 1 : pageNo);
        // body.append("limit", data1?.length > 1 ? totalCount : entries);
        body.append("page", data1?.length > 1 || data1 == "main" ? 1 : pageNo);
        body.append(
          "limit",
          data1?.length > 1 ? (data1 == "main" ? entries : totalCount) : entries
        );
        body.append("stype", addData?.type);
        if (addData?.type == "1") {
          body.append("prod_code", addData?.product_id || "");
        } else if (addData?.type == "2") {
          body.append("cvo_id", addData?.customer_id);
        } else if (addData?.type == "4") {
          body.append("tax_type", addData?.tax_id);
        } else if (addData?.type == "6") {
          body.append("omc_id", addData?.omc_id || "");
          body.append("omc_type", addData?.omc_type || "");
        }
        const response = await ledger_report_api2(body);
        setShowLoader(false);
        if (response && response.success) {
          if (data1 == "excel") {
            export_excel(addData, response?.list, response?.total);
          } else if (data1 == "pdf") {
            export_pdf(addData, response?.list, response?.total);
          } else {
            setLedger_report_item_list(response?.list);
            setTotalCount(response?.totalcount);
            setShowData(true);
            setAddDataPre(addData);
          }
        } else if (response && !response.success) {
          setMess(response?.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const validate = (addData) => {
    const data = [];
    if (
      !addData?.from_date ||
      (addData?.from_date && addData?.from_date?.length != 10)
    ) {
      data.push("Please Enter From Date");
    }
    //  else if (
    //   DateValidator2(
    //     moment(addData?.from_date).format("DD/MM/YYYY"),
    //     today,
    //     eff_date
    //   ) != true
    // ) {
    //   data.push(
    //     ...DateValidator2(
    //       moment(addData?.from_date).format("DD/MM/YYYY"),
    //       today,
    //       eff_date,
    //       `From Date Date cant be a before as ${eff_date}`,
    //       `From Date Date cant be a future date`
    //     )
    //   );
    // }
    // const fromDate = moment(addData?.from_date); // Convert to moment object
    // const oneMonthAfterDate = fromDate.add(1, "months").format("DD/MM/YYYY");
    // if (
    //   !addData?.to_date ||
    //   (addData?.to_date && addData?.to_date?.length != 10)
    // ) {
    //   data.push("Please Enter To Date");
    // } else if (
    //   DateValidator2(
    //     moment(addData?.to_date).format("DD/MM/YYYY"),
    //     today,
    //     addData?.from_date
    //   ) != true
    // ) {
    //   data.push(
    //     ...DateValidator2(
    //       moment(addData?.to_date).format("DD/MM/YYYY"),
    //       today,
    //       addData?.from_date,
    //       `To Date cant be a before as ${moment(addData?.from_date).format(
    //         "DD/MM/YYYY"
    //       )}`,
    //       `To Date cant be a future date`
    //     )
    //   );
    // } else if (
    //   DateValidator2(
    //     moment(addData?.to_date).format("DD/MM/YYYY"),
    //     oneMonthAfterDate,
    //     addData?.from_date
    //   ) != true
    // ) {
    //   data.push(
    //     ...DateValidator2(
    //       moment(addData?.to_date).format("DD/MM/YYYY"),
    //       oneMonthAfterDate,
    //       addData?.from_date,
    //       `To Date cant be a before as ${moment(addData?.from_date).format(
    //         "DD/MM/YYYY"
    //       )}`,
    //       `To Date can't be greater than one month from the From Date.`
    //     )
    //   );
    // }
    const DateValidatorReportsData = DateValidatorReports(
      moment(addData?.from_date).format("DD/MM/YYYY"),
      moment(addData?.to_date).format("DD/MM/YYYY")
    );
    if (
      !addData?.from_date ||
      (addData?.from_date && addData?.from_date?.length != 10)
    ) {
      data.push("Please Enter From Date");
    } else if (
      !addData?.to_date ||
      (addData?.to_date && addData?.to_date?.length != 10)
    ) {
      data.push("Please Enter TO DATE");
    } else if (DateValidatorReportsData != true) {
      data.push(...DateValidatorReportsData);
    }
    if (!addData?.type || (addData?.type && addData?.type?.length == 0)) {
      data.push("Please Select Type of Ledger");
    } else if (
      addData?.type == "1" &&
      (!addData?.product_id ||
        (addData?.product_id && addData?.product_id?.length == 0))
    ) {
      data.push("Please Select Product");
    } else if (
      addData?.type == "2" &&
      (!addData?.customer_id ||
        (addData?.customer_id && addData?.customer_id?.length == 0))
    ) {
      data.push("Please Select Party Name");
    } else if (
      addData?.type == "4" &&
      (!addData?.tax_id || (addData?.tax_id && addData?.tax_id?.length == 0))
    ) {
      data.push("Please Select Tax Type");
    } else if (addData?.type == "6") {
      if (
        !addData?.omc_id ||
        (addData?.omc_id && addData?.omc_id?.length == 0)
      ) {
        data.push("Please Select OMC");
      }
      // if (
      //   !addData?.omc_type ||
      //   (addData?.omc_type && addData?.omc_type?.length == 0)
      // ) {
      //   data.push("Please Select OMC Type");
      // }
    }

    return data;
  };

  return (
    <div className="container ">
      <PageHeading title={"LEDGER REPORT"} right={<BackButton />} />
      <div className="transactionMain">
        <div style={{ width: "180px" }}>
          <Input
            label={"FROM DATE"}
            type={"date"}
            value={addData?.from_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, from_date: e.target.value });
              }
            }}
          />
        </div>
        <div style={{ width: "180px" }}>
          <Input
            label={"TO DATE"}
            value={addData?.to_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, to_date: e.target.value });
              }
            }}
            type={"date"}
          />
        </div>

        <div style={{ width: "220px" }}>
          <Dropdown
            label={"SELECT TYPE OF LEDGER"}
            value={addData?.type}
            onChange={(e) => {
              const input = e.target.value;
              setAddData({
                ...addData,
                type: input,
                custProdDetails:
                  input == "3" ? "CASH" : input == "5" ? "EXPENSES" : "",
              });
            }}
            options={
              <>
                <option value={""}>SELECT</option>
                <option value="1">PRODUCT-WISE LEDGER</option>
                <option value="2">PARTY-WISE LEDGER</option>
                <option value="3">CASH LEDGER</option>
                <option value="4">TAX LEDGER</option>
                <option value="5">EXPENSES</option>
                <option value="6">OMC REPORT</option>
              </>
            }
          />
        </div>
        {addData?.type == "1" && (
          <div style={{ width: "220px" }}>
            <Dropdown
              label={"PRODUCT"}
              value={addData?.product_id}
              onChange={(e) => {
                const data = [
                  ...master_complete_list?.equipment,
                  ...arb_list,
                ]?.filter((v, i) => v?.ID == e.target.value);
                setAddData({
                  ...addData,
                  product_id: e.target.value,
                  customer_id: "",
                  tax_id: "",
                  omc_id: "",
                  omc_type: "",
                  custProdDetails:
                    data[0]?.CAT_NAME && data[0]?.CAT_DESC
                      ? `${data[0]?.CAT_NAME} - ${data[0]?.CAT_DESC}`
                      : data[0]?.category_description &&
                        data[0]?.PROD_BRAND &&
                        data[0]?.PROD_NAME
                      ? `${data[0]?.category_description} - ${data[0]?.PROD_BRAND} - ${data[0]?.PROD_NAME}`
                      : "",
                });
              }}
              options={
                <>
                  <option value={""}>SELECT</option>
                  {master_complete_list?.equipment?.map((v, i) => (
                    <option key={i} value={v?.ID}>
                      {v?.CAT_NAME} - {v?.CAT_DESC}
                    </option>
                  ))}
                  {arb_list?.map((v, i) => (
                    <option value={v?.ID} key={i}>
                      {v?.category_description} - {v?.PROD_BRAND} -{" "}
                      {v?.PROD_NAME}
                    </option>
                  ))}
                </>
              }
            />
          </div>
        )}

        {addData?.type == "2" && (
          <div style={{ width: "220px" }}>
            <Dropdown
              label={"PARTY NAME"}
              value={addData?.customer_id}
              onChange={(e) => {
                const data = [
                  ...transition_complete_list?.Customer,
                  ...transition_complete_list?.Vendor,
                  ...transition_complete_list?.OMC,
                  ...transition_complete_list?.Others,
                ]?.filter((v, i) => v?.ID == e.target.value);
                setAddData({
                  ...addData,
                  customer_id: e.target.value,
                  product_id: "",
                  tax_id: "",
                  omc_id: "",
                  omc_type: "",
                  custProdDetails: data?.length > 0 ? data[0]?.cvo_name : "",
                });
              }}
              options={
                <>
                  <option value={""}>SELECT</option>
                  {(transition_complete_list?.Customer?.length > 0 ||
                    transition_complete_list?.Vendor?.length > 0 ||
                    transition_complete_list?.OMC?.length > 0 ||
                    transition_complete_list?.Others?.length > 0) &&
                    [
                      ...transition_complete_list?.Customer,
                      ...transition_complete_list?.Vendor,
                      ...transition_complete_list?.OMC,
                      ...transition_complete_list?.Others,
                    ]?.map((v, i) => (
                      <option key={i} value={v?.ID}>
                        {v?.cvo_name?.toUpperCase()}
                      </option>
                    ))}
                </>
              }
            />
          </div>
        )}

        {addData?.type == "4" && (
          <div style={{ width: "220px" }}>
            <Dropdown
              label={"SELECT TAX TYPE"}
              value={addData?.tax_id}
              onChange={(e) => {
                const input = e.target.value;
                const data = PaymentToParty?.filter((v) => v?.id == input);
                setAddData({
                  ...addData,
                  tax_id: e.target.value,
                  customer_id: "",
                  product_id: "",
                  omc_id: "",
                  omc_type: "",
                  custProdDetails: data?.length > 0 ? data[0]?.lable : "",
                });
              }}
              options={
                <>
                  <option value={""}>SELECT</option>
                  <option value={"3"}>GST</option>
                  <option value={"4"}>INCOME TAX</option>
                  <option value={"5"}>TDS/TCS</option>
                  <option value={"6"}>PROFSSIONAL TAX</option>
                  <option value={"7"}>LOAN AND ADVANCE</option>
                  {/* <option value={"8"}>OTHERS</option> */}
                </>
              }
            />
          </div>
        )}

        {addData?.type == "6" && (
          <>
            <div style={{ width: "220px" }}>
              <Dropdown
                label={"SELECT OMC"}
                value={addData?.omc_id}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    omc_id: e.target.value,
                    customer_id: "",
                    product_id: "",
                    tax_id: "",
                  });
                }}
                options={
                  <>
                    <option value={""}>SELECT</option>
                    {transition_complete_list?.OMC?.length > 0 &&
                      transition_complete_list?.OMC?.map((v, i) => (
                        <option key={i} value={v?.ID}>
                          {v?.cvo_name?.toUpperCase()}
                        </option>
                      ))}
                  </>
                }
              />
            </div>
            <div style={{ width: "220px" }}>
              <Dropdown
                label={"SELECT TYPE"}
                value={addData?.omc_type}
                onChange={(e) => {
                  const data = selectPaymentType?.filter(
                    (v) => v?.id == e.target.value
                  );
                  setAddData({
                    ...addData,
                    omc_type: e.target.value,
                    customer_id: "",
                    product_id: "",
                    tax_id: "",
                    custProdDetails: data?.length > 0 ? data[0]?.lable : "",
                  });
                }}
                options={
                  <>
                    <option value="">SELECT</option>
                    {/* <option value="1">CYLINDER PURCHASES</option>
                    <option value="2">BLPG / ARB / NFR PURCHASES</option>
                    <option value="3">OTHER PURCHASES</option>
                    <option value="4">CREDIT NOTE</option>
                    <option value="5">DEBIT NOTE</option>
                    <option value="6">BLPG / ARB / NFR SALES</option> */}
                    {selectPaymentType?.map((v, i) => (
                      <option key={i} value={v?.id}>
                        {v?.lable}
                      </option>
                    ))}
                  </>
                }
              />
            </div>
          </>
        )}

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Button
            title={"SEARCH"}
            //  onClick={() => get_list(addData)}
            onClick={async () => {
              await setShowData(false);
              await setPageNo(1);
              await setEntries(5);
              get_list(addData, "main");
            }}
          />
        </div>
      </div>

      {showData && ledger_report_item_list?.length > 0 ? (
        <>
          <div className="SFromDate">
            <span style={{ fontSize: "11PX" }}>FROM DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.from_date).format("DD/MM/YY")}
            </p>
            <span style={{ fontSize: "11PX" }}>TO DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.to_date).format("DD/MM/YY")}
            </p>
            <span style={{ fontSize: "11PX" }}>
              {addDataPre?.type == "1"
                ? "PRODUCT "
                : addDataPre?.type == "2"
                ? "PARTY "
                : addDataPre?.type == "3"
                ? "PARTY "
                : addDataPre?.type == "4"
                ? "PARTY "
                : addDataPre?.type == "5"
                ? "PARTY "
                : addDataPre?.type == "6"
                ? "PARTY "
                : ""}
              SELECTED:
            </span>
            <h6 className="PDS">{addDataPre?.custProdDetails}</h6>
          </div>

          <Pagination2
            count={0}
            download={true}
            PDFDown={() => get_list(addDataPre, "pdf")}
            excelDown={() => get_list(addDataPre, "excel")}
            top={true}
            totalCount={totalCount || ledger_report_item_list?.length}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          {ledger_report_item_list?.length == 0 ? (
            <NoData mt={30} />
          ) : (
            <Table
              headData={table_header}
              body={
                <>
                  {ledger_report_item_list?.map((v, i) => (
                    <tr key={i}>
                      {addDataPre?.type == "1" ? (
                        <>
                          <td>
                            {v?.TRANS_DATE}
                          </td>
                          <td>{v?.INV_NO}</td>
                          <td>{v?.GODOWN_NAME}</td>
                          <td>{v?.TRANS_TYPE == "1" ? "PURCHASE" : "SALE"}</td>
                          <td>{v?.FULLS || "-"}</td>
                          <td>{v?.PARTY_NAME}</td>
                          <td>{v?.CS_FULLS}</td>
                          <td>{v?.CS_EMPTYS}</td>
                          <td>{v?.DISCOUNT}</td>
                          <td>{v?.CREATED_DATE}</td>
                           <td>{ v?.CREATED_NAME}</td>
                        </>
                      ) : addDataPre?.type == "2" ? (
                        <>
                          <td>
                            {/* {moment(Number(v?.INV_DATE)).format("DD-MMM-YYYY")} */}
                            {v?.INV_DATE}
                          </td>
                          <td>{v?.INV_REF_NO}</td>
                          <td>{v?.TRANSACTION_TYPE}</td>
                          <td>{v?.DEBIT}</td>
                          <td>{v?.CREDIT}</td>
                          <td>{v?.CLOSING_BALANCE}</td>
                          <td>{v?.DISCOUNT}</td>
                           <td>{ v?.CREATED_NAME}</td>
                        </>
                      ) : addDataPre?.type == "3" ? (
                        <>
                          <td>
                            {/* {moment(Number(v?.T_DATE)).format("DD-MMM-YYYY")} */}
                            {v?.T_DATE}
                          </td>
                          <td>{v?.INV_NO}</td>
                          <td>{v?.TRANSACTION_TYPE}</td>
                          <td>{v?.DEBIT}</td>
                          <td>{v?.CREDIT}</td>
                          <td>{v?.CLOSING_BALANCE}</td>
                          {/* <td>{v?.DISCOUNT}</td> */}
                           <td>{ v?.CREATED_NAME}</td>
                        </>
                      ) : addDataPre?.type == "4" ? (
                        <>
                          <td>
                            {/* {moment(Number(v?.PDATE)).format("DD-MMM-YYYY")} */}
                            {v?.PDATE}
                          </td>
                          <td>{v?.TAX_NAME}</td>
                          <td>{v?.DEBIT}</td>
                          <td>{v?.CREDIT}</td>
                          <td>{v?.BALANCE}</td>
                           <td>{ v?.CREATED_NAME}</td>
                        </>
                      ) : addDataPre?.type == "5" ? (
                        <>
                          <td>
                            {moment(Number(v?.INV_DATE)).format("DD/MM/YYYY")}
                            {/* {v?.INV_DATE} */}
                          </td>
                          <td>{v?.INV_REF_NO}</td>
                          <td>{v?.EXPENSE_ITEM}</td>
                          <td>
                            {
                              MHData.filter(
                                (item) => item?.MH == v?.MINOR_HEAD
                              )[0]?.title
                            }
                          </td>
                          <td>
                            {
                              AHData.filter(
                                (item) => item?.AH == v?.ACCOUNT_HEAD
                              )[0]?.title
                            }
                          </td>
                          <td>{v?.QUANTITY || "-"}</td>
                          <td>
                            {
                              UOMData.filter((item) => item?.UOM == v?.UOM)[0]
                                ?.title
                            }
                          </td>
                          <td>{v?.UNIT_RATE}</td>
                          <td>{v?.PAYMENT_MODE_NAME}</td>
                          <td>{v?.BANK_ACCOUNT_NUMBER || "-"}</td>
                          <td>{v?.TOTAL_VALUE}</td>
                           <td>{ v?.CREATED_NAME}</td>
                        </>
                      ) : addDataPre?.type == "6" ? (
                        <>
                          <td>
                            {moment(Number(v?.INV_DATE)).format("DD-MMM-YYYY")}
                          </td>
                          <td>{v?.INV_REF_NO}</td>
                          <td>{v?.INV_REF_NO}</td>
                          <td>{v?.GODOWN_NAME}</td>
                          <td>{v?.TRANSACTION_TYPE}</td>
                          <td>{v?.OMC_NAME}</td>
                          <td>
                            {v?.PROD_NAME} - {v?.PROD_DESC}
                          </td>
                          <td>
                            {MHData.filter(
                              (item) => item?.MH == v?.MINOR_HEAD
                            )[0]?.title || "NA"}
                          </td>
                          <td>
                            {AHData.filter(
                              (item) => item?.AH == v?.ACCOUNT_HEAD
                            )[0]?.title || "NA"}
                          </td>
                          <td>{v?.HSN_CODE}</td>
                          <td>{v?.UNIT_PRICE}</td>
                          <td>{v?.QUANTITY || "-"}</td>
                          <td>{v?.GSTP}</td> <td>{v?.TAXABLE_AMOUNT}</td>
                          <td>{v?.IGST_AMOUNT}</td>
                          <td>{v?.CGST_AMOUNT}</td> <td>{v?.SGST_AMOUNT}</td>
                          <td>{v?.OTHER_CHARGES || 0}</td>
                          <td>{v?.TOTAL_AMOUNT}</td>
                           <td>{ v?.CREATED_NAME}</td>
                        </>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))}
                </>
              }
            />
          )}
          <Pagination2
            count={0}
            bottom={true}
            totalCount={totalCount}
            SerchInput={serchInput}
            setSearchInput={setSerchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
        </>
      ) : showData ? (
        <NoData title={"NO RECORDS FOUND"} color="red" mt={30} />
      ) : (
        ""
      )}

      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alerts"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default Ledger;
