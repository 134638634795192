import React, { useEffect, useState } from "react";
import PageHeading from "../../components/PageHeading";
import { BackButton, HelpButton } from "../../components/btn/Buttons";
import Button from "../../components/btn/Button";
import Input from "../../components/inputs/Input";
import "./DayEnd.css";
import Table from "../../components/table/Table";
import { useSelector } from "react-redux";
import moment from "moment";
import { type } from "@testing-library/user-event/dist/type";
import { AHData, Filter_comp_data, MHData, UOMData } from "../../utils/data";
import store from "../../redux/store";
import { get_day_end_day_list } from "./DayendDaySlice";
import PageLoading from "../../components/PageLoading";
import { Pagination2 } from "../../components/pagination/pagination";
import RowTxt from "../../components/RowTxt";
import NoData from "../../components/NoData";
import { motion } from "framer-motion";
import { HiOutlineArrowRight } from "react-icons/hi";
import Modal from "../../components/modal/Modal";
import { Switch } from "antd";
import { toast } from "react-toastify";
import { day_end_date_api } from "../../utils/apis";
import Loader from "../../components/Loader";
import { get_home_data } from "../profile/UsersSlice";
import RadioBtn from "../../components/radio/RadioBtn";
import { CheckBox } from "../../components/inputs/CheckBox";

const DayEnd = () => {
  const [date, setDate] = useState("");
  const [viewItem, setViewItem] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [show, setShow] = useState(false);
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [showStatus, setShowStatus] = useState("0");
  const [loading, setLoading] = useState(false);
  const { day_end_date } = useSelector((state) => state.profile);

  const [selectLink, setSelectLink] = useState([]);

  const [addData, setAddData] = useState([]);
  const [addDataBank, setAddDataBank] = useState([]);
  const [addDataOther, setAddDataOther] = useState([]);
  const [addDataCash, setAddDataCash] = useState([]);

  const { user_id, eff_date, omc_data, today } = useSelector(
    (state) => state.other
  );

  const {
    day_end_date_list,
    day_end_date_loading,
    cylinder_stock_list,
    arb_stock_list,
    bank_transaction_day_list,
    cash_transaction_day_list,
    expencess_day_list,
  } = useSelector((state) => state.day_end);

  useState(() => {
    setDate(moment(day_end_date).format("YYYY-MM-DD"));
  }, [day_end_date]);

  // useEffect(() => {
  //   store.dispatch(get_home_data(user_id));
  // }, []);

  const handle_update = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "updatedayendlist");
      body.append("agencyid", user_id);
      body.append("date", date);

      addData?.forEach((v) => {
        if (v) {
          body.append("id[]", v?.id);
          body.append("STOCK_FLAG[]", v?.flag_status);
        }
      });
      addDataBank?.forEach((v) => {
        if (v) {
          body.append("id[]", v?.id);
          body.append("BTFLAG[]", v?.flag);
        }
      });
      addDataOther?.forEach((v) => {
        if (v) {
          body.append("id[]", v?.id);
          body.append("BTFLAG[]", v?.flag);
        }
      });
      addDataCash?.forEach((v) => {
        if (v) {
          body.append("acd_id[]", v?.id);
        }
      });

      const res = await day_end_date_api(body);
      setLoading(false);
      if (res.success) {
        toast.success(res.message);
        setAddData([]);
        setAddDataBank([]);
        setAddDataOther([]);
        setAddDataCash([]);
        await store.dispatch(
          get_day_end_day_list({ date: date, selectLink: selectLink })
        );
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"DAY END REPORT"}
        right={
          <>
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/oEzSuwSIkRg",
                "https://youtu.be/rrX-pqA7h68",
              ]}
            />
          </>
        }
      />
      <div style={{ height: "20px" }}></div>
      <div>
        <p className="DayEndText">
          LAST DAY END REPORT SUBMITTED DATE :{" "}
          <b>{moment(day_end_date).format("DD-MMM-YYYY")}</b>
        </p>
      </div>
      <div
        style={{
          // width: "180px",
          display: "flex",
          alignItems: "flex-end",
          gap: "20px",
          marginTop: "1rem",
          alignItems: "center",
        }}
      >
        <Input
          mw={"150px"}
          type={"date"}
          value={date}
          disabled={loading || day_end_date_loading}
          // disabled={true}
          onChange={(e) => {
            if (e.target.value.length <= 10) {
              setDate(e.target.value);
            }
          }}
        />
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <RadioBtn label={"Select List's "} />
          <CheckBox
            disabled={loading || day_end_date_loading}
            lable={"Cylinder Stock"}
            Checked={selectLink?.includes("1")}
            value={selectLink?.includes("1")}
            onChange={() => {
              const data = selectLink?.filter((v) => v === "1");
              const data2 = selectLink?.filter((v) => v !== "1");
              if (data?.length > 0) {
                setSelectLink(data2);
              } else {
                setSelectLink([...selectLink, "1"]);
              }
            }}
          />
          <CheckBox
            disabled={loading || day_end_date_loading}
            lable={"ARB Stock"}
            Checked={selectLink?.includes("2")}
            value={selectLink?.includes("2")}
            onChange={() => {
              const data = selectLink?.filter((v) => v === "2");
              const data2 = selectLink?.filter((v) => v !== "2");
              if (data?.length > 0) {
                setSelectLink(data2);
              } else {
                setSelectLink([...selectLink, "2"]);
              }
            }}
          />
          <CheckBox
            disabled={loading || day_end_date_loading}
            lable={"Bank Transactions"}
            Checked={selectLink?.includes("3")}
            value={selectLink?.includes("3")}
            onChange={() => {
              const data = selectLink?.filter((v) => v === "3");
              const data2 = selectLink?.filter((v) => v !== "3");
              if (data?.length > 0) {
                setSelectLink(data2);
              } else {
                setSelectLink([...selectLink, "3"]);
              }
            }}
          />
          <CheckBox
            disabled={loading || day_end_date_loading}
            lable={"Cash Bank Transactions"}
            Checked={selectLink?.includes("4")}
            value={selectLink?.includes("4")}
            onChange={() => {
              const data = selectLink?.filter((v) => v === "4");
              const data2 = selectLink?.filter((v) => v !== "4");
              if (data?.length > 0) {
                setSelectLink(data2);
              } else {
                setSelectLink([...selectLink, "4"]);
              }
            }}
          />
          <CheckBox
            disabled={loading || day_end_date_loading}
            lable={"Expencess"}
            Checked={selectLink?.includes("5")}
            value={selectLink?.includes("5")}
            onChange={() => {
              const data = selectLink?.filter((v) => v === "5");
              const data2 = selectLink?.filter((v) => v !== "5");
              if (data?.length > 0) {
                setSelectLink(data2);
              } else {
                setSelectLink([...selectLink, "5"]);
              }
            }}
          />
        </div>
        <Button
          disable={loading || day_end_date_loading}
          title={"GENERATE"}
          onClick={async () => {
            setLoading(true);
            await store.dispatch(
              get_day_end_day_list({ date: date, selectLink: selectLink })
            );
            setShow(true);
            setLoading(false);
          }}
        />
      </div>
      {show &&
        (day_end_date_loading || loading ? (
          <PageLoading />
        ) : (
          <>
            {day_end_date_list?.length == 0 &&
            cylinder_stock_list?.length == 0 &&
            arb_stock_list?.length == 0 &&
            bank_transaction_day_list?.length == 0 &&
            expencess_day_list?.length == 0 &&
            cash_transaction_day_list?.length == 0 ? (
              <NoData mt={20} />
            ) : (
              <>
                <div style={{ height: "20px" }}></div>
                <p className="DayEndText"></p>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      fontSize: "14px",
                      borderRadius: "30px",
                      border: "1px solid ",
                      borderColor: showStatus == "0" ? "#f5796c" : "#18b389",
                      overflow: "hidden",
                      maxWidth: "300px",
                    }}
                  >
                    <motion.div
                      className="toggle_div"
                      style={{
                        color: showStatus == "0" ? "#fff" : "#000",
                        fontWeight: showStatus == "0" ? "bold" : "",
                      }}
                      animate={{
                        backgroundColor: showStatus == "0" ? "#f5796c" : "#fff",
                      }}
                      transition={{
                        backgroundColor: { duration: 0.2 },
                      }}
                      onClick={() => {
                        setShowStatus("0");
                        setPageNo(1);
                        setEntries(5);
                      }}
                    >
                      Un-Verified
                    </motion.div>
                    <motion.div
                      className="toggle_div"
                      style={{
                        color: showStatus == "1" ? "#fff" : "#000",
                        fontWeight: showStatus == "1" ? "bold" : "",
                      }}
                      animate={{
                        backgroundColor: showStatus == "1" ? "#18b389" : "#fff",
                      }}
                      transition={{
                        backgroundColor: { duration: 0.2 },
                      }}
                      onClick={() => {
                        setShowStatus("1");
                        setPageNo(1);
                        setEntries(5);
                      }}
                    >
                      Verified
                    </motion.div>
                  </div>
                </div>

                <div style={{ height: "10px" }}></div>

                {day_end_date_loading ? (
                  <PageLoading />
                ) : day_end_date_list?.filter(
                    (s, k) => s?.STATUS_FLAG == showStatus
                  )?.length == 0 ? (
                  <NoData mt={20} />
                ) : (
                  <>
                    <Pagination2
                      top={true}
                      totalCount={
                        day_end_date_list?.filter(
                          (s, k) => s?.STATUS_FLAG == showStatus
                        )?.length
                      }
                      search={false}
                      pageNo={pageNo}
                      setPageNo={setPageNo}
                      entries={entries}
                      setEntries={setEntries}
                    />
                    <div className="grid">
                      {day_end_date_list
                        ?.filter((s, k) => s?.STATUS_FLAG == showStatus)
                        ?.slice(pageNo * entries - entries, pageNo * entries)
                        ?.map((v, i) => (
                          <div className="card" key={i}>
                            <div>
                              {v?.TRANS_ID == "1" ? (
                                <>
                                  <div className="card-heading">
                                    {v?.cvo_name}
                                  </div>
                                  <RowTxt
                                    title={"Invoice No."}
                                    value={v?.INV_REF_NO}
                                  />
                                  <RowTxt
                                    title={"Invoice Date."}
                                    value={moment(v?.invoice_date).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  <RowTxt
                                    title={"Stock Recieve Date"}
                                    value={moment(v?.stock_receive_date).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  <RowTxt
                                    title={"TDS/TCS"}
                                    value={
                                      v?.TCS_FLAG == "1"
                                        ? "TDS"
                                        : v?.TCS_FLAG == "2"
                                        ? "TCS"
                                        : "NA"
                                    }
                                  />
                                </>
                              ) : v?.TRANS_ID == "2" ? (
                                <>
                                  <div className="card-heading">
                                    {v?.cvo_name?.toUpperCase()}
                                  </div>
                                  <RowTxt
                                    title={"Invoice No."}
                                    value={v.INV_REF_NO}
                                  />
                                  <RowTxt
                                    title={"Invoice Date"}
                                    value={moment(v.Invoice_date).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  {v.PO_NUMBER && v?.PO_DATE && (
                                    <>
                                      <RowTxt
                                        title={"PO No."}
                                        value={v?.PO_NUMBER}
                                      />
                                      <RowTxt
                                        title={"PO Date"}
                                        value={moment(v?.PO_DATE).format(
                                          "DD-MMM-YYYY"
                                        )}
                                      />
                                    </>
                                  )}
                                  <RowTxt
                                    title={"Reverse Charges"}
                                    value={
                                      v.REVERSE_CHARGE == "1" ? "YES" : "NO"
                                    }
                                  />

                                  <RowTxt
                                    title={"Stock Recieve Date"}
                                    value={moment(v.stock_receive_date).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                </>
                              ) : v?.TRANS_ID == "3" ? (
                                <>
                                  <div className="card-heading">
                                    {v?.CVO_NAME ? v?.CVO_NAME : "EXPENSES"}
                                  </div>
                                  <RowTxt
                                    title={"Invoice No."}
                                    value={v?.INV_REF_NO}
                                  />
                                  <RowTxt
                                    title={"Invoice No."}
                                    value={moment(Number(v?.INV_DATE)).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  <RowTxt
                                    title={"Total Amount"}
                                    value={Number(v?.total_amount)?.toFixed(2)}
                                  />
                                </>
                              ) : v?.TRANS_ID == "4" ? (
                                <>
                                  <div className="card-heading">
                                    {v?.cvo_name}
                                  </div>
                                  <RowTxt
                                    title={"Invoice No."}
                                    value={v?.INV_REF_NO}
                                  />
                                  <RowTxt
                                    title={"Invoice Date"}
                                    value={moment(v?.invoice_date).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  <RowTxt
                                    title={"Return Amount"}
                                    value={v?.INV_AMT}
                                  />
                                  <RowTxt
                                    title={"Return Date"}
                                    value={moment(v?.invoice_date).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                </>
                              ) : v?.TRANS_ID == "5" ? (
                                <>
                                  <div className="card-heading">
                                    {v?.cvo_name || "CASH SALES / HOUSEHOLDS"}
                                  </div>
                                  <RowTxt
                                    title={"Invoice No."}
                                    value={v?.SR_NO}
                                  />
                                  <RowTxt
                                    title={"Invoice Date"}
                                    value={moment(v?.SI_DATE).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  <RowTxt
                                    title={"Total Amount"}
                                    value={v?.SI_AMOUNT}
                                  />
                                  <RowTxt
                                    title={"Payment Terms"}
                                    value={
                                      v?.PAYMENT_TERMS == "1"
                                        ? "Cash"
                                        : v?.PAYMENT_TERMS == "2"
                                        ? "Credit"
                                        : ""
                                    }
                                  />
                                  <RowTxt
                                    title={"Godown Address"}
                                    value={v?.GODOWN_NAME}
                                  />
                                  {v?.PAYMENT_TERMS == "1" && (
                                    <>
                                      <RowTxt
                                        title={"Cash Recieved"}
                                        value={v?.CASH_AMOUNT}
                                      />
                                      <RowTxt
                                        title={"Online Recieved"}
                                        value={v?.AMOUNT_RECEIVED_ONLINE}
                                      />
                                      <RowTxt
                                        title={"Transaction Id"}
                                        value={v?.TRANSACTION_ID}
                                      />
                                      <RowTxt
                                        title={"Cash Recieved From"}
                                        // value={v?.SI_AMOUNT}
                                        value={
                                          v?.RECEIVED_FROM == "1"
                                            ? "Phone Pay"
                                            : v?.RECEIVED_FROM == "2"
                                            ? "Google Pay"
                                            : v?.RECEIVED_FROM == "3"
                                            ? "Paytm"
                                            : v?.RECEIVED_FROM == "4"
                                            ? "Cred"
                                            : v?.RECEIVED_FROM == "5"
                                            ? "Others"
                                            : ""
                                        }
                                      />
                                      <RowTxt
                                        title={"Bank Account"}
                                        value={v?.BANK_ACC_NO}
                                      />
                                    </>
                                  )}
                                </>
                              ) : v?.TRANS_ID == "6" ? (
                                <>
                                  <div className="card-heading">
                                    {v?.cvo_name || v?.CUST_NAME}
                                  </div>
                                  <RowTxt
                                    title={"Invoice No."}
                                    value={v?.SR_NO}
                                  />
                                  <RowTxt
                                    title={"Invoice Date"}
                                    value={moment(v?.SI_DATE).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  <RowTxt
                                    title={"Total Amount"}
                                    value={Number(v?.SI_AMOUNT).toFixed(2)}
                                  />
                                  <RowTxt
                                    title={"Payment Terms"}
                                    value={
                                      v?.PAYMENT_TERMS == "1"
                                        ? "Cash"
                                        : v?.PAYMENT_TERMS == "2"
                                        ? "Credit"
                                        : ""
                                    }
                                  />
                                  <RowTxt
                                    title={"Godown Address"}
                                    value={v?.GODOWN_NAME}
                                  />
                                  {v?.PO_NO && (
                                    <RowTxt
                                      title={"PO Number"}
                                      value={v?.PO_NO}
                                    />
                                  )}
                                  {v?.PAYMENT_TERMS == "1" && (
                                    <>
                                      <RowTxt
                                        title={"Cash Recieved"}
                                        value={v?.CASH_AMOUNT}
                                      />
                                      <RowTxt
                                        title={"Online Recieved"}
                                        value={v?.AMOUNT_RECEIVED_ONLINE}
                                      />
                                      <RowTxt
                                        title={"Transaction Id"}
                                        value={v?.TRANSACTION_ID}
                                      />
                                      <RowTxt
                                        title={"Cash Recieved From"}
                                        value={
                                          v?.RECEIVED_FROM == "1"
                                            ? "Phone Pay"
                                            : v?.RECEIVED_FROM == "2"
                                            ? "Google Pay"
                                            : v?.RECEIVED_FROM == "3"
                                            ? "Paytm"
                                            : v?.RECEIVED_FROM == "4"
                                            ? "Cred"
                                            : v?.RECEIVED_FROM == "5"
                                            ? "Others"
                                            : ""
                                        }
                                      />
                                      <RowTxt
                                        title={"Bank Account"}
                                        value={v?.BANK_ACC_NO}
                                      />
                                    </>
                                  )}
                                </>
                              ) : v?.TRANS_ID == "7" ? (
                                <>
                                  <div className="card-heading">
                                    {v?.CUSTOMER?.toUpperCase() || "NA"}
                                  </div>
                                  <RowTxt
                                    title={"Invoice No."}
                                    value={v?.SR_NO}
                                  />
                                  <RowTxt
                                    title={"Invoice Date"}
                                    value={moment(v?.INVOICE_DATE).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  <RowTxt
                                    title={"Total Amount"}
                                    value={v?.SI_AMOUNT}
                                  />
                                  <RowTxt
                                    title={"Staff Name"}
                                    value={v?.STAFF_NAME}
                                  />
                                  <RowTxt
                                    title={"Vehicle No."}
                                    value={v?.VEHICLE_NO}
                                  />
                                  <RowTxt
                                    title={"Payment Terms "}
                                    value={
                                      v?.PAYMENT_TERMS == "1"
                                        ? "CASH"
                                        : "CREDIT"
                                    }
                                  />
                                  <RowTxt
                                    title={"Customer Type"}
                                    value={
                                      v?.CUSTOMER_STATUS == "2"
                                        ? "Un Registered Customer"
                                        : "Registered Customer"
                                    }
                                  />
                                  {v?.PAYMENT_TERMS == "1" && (
                                    <>
                                      <RowTxt
                                        title={"Address"}
                                        value={v?.address || "NA"}
                                      />
                                      <RowTxt
                                        title={"GSTIN No."}
                                        value={v?.GST_NO || "NA"}
                                      />
                                      <RowTxt
                                        title={"Cash Recieved"}
                                        value={v?.CASH_RECIEVED}
                                      />
                                      <RowTxt
                                        title={"Cash Recieved From"}
                                        value={
                                          v?.CASH_RECIVED_FROM == "1"
                                            ? "Phone Pay"
                                            : v?.CASH_RECIVED_FROM == "2"
                                            ? "Google Pay"
                                            : v?.CASH_RECIVED_FROM == "3"
                                            ? "Paytm"
                                            : v?.CASH_RECIVED_FROM == "4"
                                            ? "Cred"
                                            : v?.CASH_RECIVED_FROM == "5"
                                            ? "Others"
                                            : ""
                                        }
                                      />
                                      <RowTxt
                                        title={"Online Recieved"}
                                        value={v?.ONLINE_RCVD_AMOUNT || 0}
                                      />
                                      <RowTxt
                                        title={"Online Recieved From"}
                                        value={v?.BANK_ACCOUNT}
                                      />
                                      <RowTxt
                                        title={"Transaction Id"}
                                        value={v?.TRANSACTION_ID}
                                      />
                                    </>
                                  )}
                                </>
                              ) : v?.TRANS_ID == "8" ? (
                                <>
                                  <div className="card-heading">
                                    {v?.CVO_NAME ? `${v?.CVO_NAME} -` : ""}{" "}
                                    {v?.SR_NO}
                                  </div>
                                  <RowTxt
                                    title={"Invoice Ref No."}
                                    value={v?.INV_REF_NO}
                                  />
                                  <RowTxt
                                    title={"Invoice Date"}
                                    value={moment(v?.INV_DATE).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  <RowTxt
                                    title={"Total Amount"}
                                    value={v?.INV_AMT}
                                  />
                                  <RowTxt
                                    title={"Narration"}
                                    value={v?.NARRATION}
                                  />
                                </>
                              ) : v?.TRANS_ID == "9" ? (
                                <>
                                  <div className="card-heading">
                                    {v.CUSTOMER_NAME || v?.CUSTOMER_NO}{" "}
                                  </div>
                                  <RowTxt
                                    title={"Connection Type"}
                                    value={v?.CONN_NAME}
                                  />
                                  <RowTxt
                                    title={"Invoice No."}
                                    value={v?.SR_NO}
                                  />
                                  <RowTxt
                                    title={"Invoice Date"}
                                    value={moment(v?.INV_DATE).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  <RowTxt
                                    title={"Invoice Amt."}
                                    value={v?.INV_AMOUNT}
                                  />
                                  <RowTxt
                                    title={"Godown Address "}
                                    value={v?.GODOWN_NAME}
                                  />
                                  <RowTxt
                                    title={"Staff "}
                                    value={v?.STAFF_NAME}
                                  />
                                  <RowTxt
                                    title={"No. of Connections"}
                                    value={v?.NO_OF_CONNS}
                                  />
                                  <RowTxt
                                    title={"Cash Recieved"}
                                    value={v?.CASH_AMOUNT}
                                  />
                                  <RowTxt
                                    title={"Online Recieved"}
                                    value={v?.ONLINE_AMOUNT}
                                  />
                                  {v?.ONLINE_AMOUNT == "0.00" ? (
                                    ""
                                  ) : (
                                    <RowTxt
                                      title={"Bank Account"}
                                      value={v?.BANK_ACC_NO}
                                    />
                                  )}
                                </>
                              ) : v?.TRANS_ID == "10" ? (
                                <>
                                  <div className="card-heading">
                                    {v?.CUSTOMER_NAME}{" "}
                                  </div>
                                  <RowTxt
                                    title={"Invoice No."}
                                    value={v?.SR_NO}
                                  />
                                  <RowTxt
                                    title={"Invoice Date"}
                                    value={moment(v?.RC_DATE).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  <RowTxt
                                    title={"Invoice Amt."}
                                    value={v?.RC_AMOUNT}
                                  />
                                  <RowTxt
                                    title={"Godown Address "}
                                    value={v?.GODOWN_NAME}
                                  />
                                  <RowTxt
                                    title={"Staff Name"}
                                    value={v?.STAFF_NAME}
                                  />
                                </>
                              ) : v?.TRANS_ID == "11" ? (
                                <>
                                  <div className="card-heading">
                                    {v?.CUSTOMER || v?.CUSTOMER_NAME}{" "}
                                  </div>
                                  <RowTxt
                                    title={"Invoice No."}
                                    value={v?.SR_NO}
                                  />
                                  <RowTxt
                                    title={"Invoice Date"}
                                    value={moment(v?.TV_DATE).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  />
                                  <RowTxt
                                    title={"Invoice Amt."}
                                    value={v?.TV_AMOUNT}
                                  />
                                  <RowTxt
                                    title={"Godown Address "}
                                    value={v?.GODOWN_NAME}
                                  />
                                  <RowTxt
                                    title={"Staff Name"}
                                    value={v?.STAFF_NAME}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                              <RowTxt
                                title={"Transaction Name"}
                                value={
                                  Filter_comp_data({
                                    type: v?.TRANS_ID,
                                    omc_data: omc_data,
                                  })?.title
                                }
                              />
                              <RowTxt
                                title={"Created By"}
                                value={v?.CREATED_NAME || "-"}
                              />
                            </div>

                            <div className="df jcsb mt20">
                              <div
                                style={{
                                  fontSize: "14px",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                  color: "#526484",
                                }}
                              >
                                <div>Un-Verified</div>
                                <Switch
                                  checked={
                                    addData?.filter((s) => s?.id == v?.ID)?.[0]
                                      ?.status
                                      ? true
                                      : false || v?.STATUS_FLAG == 1
                                      ? true
                                      : false
                                  }
                                  defaultChecked={
                                    v?.STATUS_FLAG == 1 ? true : false
                                  }
                                  onChange={(check) => {
                                    const filterData = addData?.filter(
                                      (s) => s?.id == v?.ID
                                    );
                                    const filterDataWithout = addData?.filter(
                                      (s) => s?.id != v?.ID
                                    );
                                    if (filterData?.length == 0) {
                                      setAddData([
                                        ...addData,
                                        {
                                          id: v?.ID,
                                          TRANS_ID: v?.TRANS_ID,
                                          status: check,
                                          flag_status: v?.STOCK_FLAG,
                                        },
                                      ]);
                                    } else if (filterData?.length > 0) {
                                      setAddData(filterDataWithout);
                                    }
                                  }}
                                />
                                <div>Verified</div>
                              </div>
                              <motion.div
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 1.05 }}
                                className="flex aic gap10 jce cursor-pointer"
                              >
                                <div
                                  className="view-btn"
                                  onClick={() => {
                                    setOpenView(true);
                                    setViewItem(v);
                                  }}
                                >
                                  View
                                </div>
                                <HiOutlineArrowRight
                                  onClick={() => {
                                    setOpenView(true);
                                    setViewItem(v);
                                  }}
                                  size={20}
                                  color="#1b64af"
                                />
                              </motion.div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <Pagination2
                      bottom={true}
                      totalCount={
                        day_end_date_list?.filter(
                          (s, k) => s?.STATUS_FLAG == showStatus
                        )?.length
                      }
                      pageNo={pageNo}
                      setPageNo={setPageNo}
                      entries={entries}
                      setEntries={setEntries}
                    />
                  </>
                )}

                {(showStatus == "1" || addData?.length == 0) && (
                  <div style={{ height: "40px" }}></div>
                )}

                {cylinder_stock_list?.length > 0 && (
                  <div className="mt10">
                    <p className="DayEndText mt10">CYLINDER STOCK DETAILS</p>

                    <Table
                      mt={5}
                      headData={[
                        "PRODUCT",
                        "OPENING FULL STOCK",
                        "PURCHASE QUANTITY",
                        "PURCHASE RETURNS",
                        "SALES RETURNS",
                        "STOCK ADJUSTMENT",
                        "SOLD QUANTITY",
                        "CLOSING FULL STOCK",
                      ]}
                      body={cylinder_stock_list?.map((v, i) => (
                        <tr key={i}>
                          <td>{v?.PRODUCT_NAME}</td>
                          <td>{v?.OPENING_STOCK}</td>
                          <td>{v?.TOTAL_PURCHASES}</td>
                          <td>{v?.TOTAL_PURCHASE_RETURNS}</td>
                          <td>{v?.TOTAL_SALES_RETURNS}</td>
                          <td>{v?.STOCK_ADJUSTMENT}</td>
                          <td>{v?.CS_FULLS}</td>
                          <td>{v?.CLOSING_STOCK}</td>
                        </tr>
                      ))}
                    />
                  </div>
                )}

                {arb_stock_list?.length > 0 && (
                  <div>
                    <p className="DayEndText mt10">ARB STOCK DETAILS</p>

                    <Table
                      mt={5}
                      headData={[
                        "ARB PRODUCT",
                        "OPENING FULL STOCK	",
                        "PURCHASE QUANTITY	",
                        "PURCHASE RETURNS	",
                        "SALES RETURNS	",
                        "NC/DBC/RC QUANTITY",
                        "STOCK ADJUSTMENT	",
                        "SOLD QUANTITY	",
                        "CLOSING FULL STOCK",
                      ]}
                      body={arb_stock_list?.map((v, i) => (
                        <tr key={i}>
                          <td>{v?.PRODUCT_NAME}</td>
                          <td>{v?.OPENING_STOCK}</td>
                          <td>{v?.TOTAL_PURCHASES}</td>
                          <td>{v?.TOTAL_PURCHASE_RETURNS}</td>
                          <td>{v?.TOTAL_SALES_RETURNS}</td>
                          <td>{v?.NCDBC_RC_TV}</td>
                          <td>{v?.STOCK_ADJUSTMENT}</td>
                          <td>{v?.CS_FULLS}</td>
                          <td>{v?.CLOSING_STOCK}</td>
                        </tr>
                      ))}
                    />
                  </div>
                )}

                {expencess_day_list?.length > 0 && (
                  <div className="mt10">
                    <p className="DayEndText mt10">EXPENSES DETAILS</p>

                    <Table
                      mt={5}
                      headData={[
                        "VOUCHER NO.",
                        "EXPENSE ITEM",
                        "MINOR HEAD",
                        "ACCOUNT HEAD",
                        "QUANTITY",
                        "TOTAL VALUE",
                        "RECEIVED BY",
                        "STATUS",
                      ]}
                      body={expencess_day_list?.map((v, i) => (
                        <tr key={i}>
                          <td>{v?.INV_REF_NO}</td>
                          <td>{v?.PNAME}</td>
                          <td>
                            {MHData.find((item) => item?.MH == v?.MH)?.title}
                          </td>
                          <td>
                            {AHData.find((item) => item?.AH == v?.AH)?.title}
                          </td>
                          <td>{v?.QUANTITY}</td>
                          <td>{v?.P_AMOUNT}</td>
                          <td>{v?.EMP_NAME}</td>
                          <td>
                            {v?.STATUS_FLAG == 1 ? (
                              "Verified"
                            ) : (
                              <Switch
                                checked={
                                  addDataOther?.filter(
                                    (s) => s?.id == v?.ID
                                  )?.[0]?.status
                                    ? true
                                    : false
                                }
                                onChange={(check) => {
                                  const filterData = addDataOther?.filter(
                                    (s) => s?.id == v?.ID
                                  );
                                  const filterDataWithout =
                                    addDataOther?.filter((s) => s?.id != v?.ID);
                                  if (filterData?.length == 0) {
                                    setAddDataOther([
                                      ...addDataOther,
                                      {
                                        id: v?.ID,
                                        status: check,
                                        flag: v?.BTFLAG,
                                      },
                                    ]);
                                  } else if (filterData?.length > 0) {
                                    setAddDataOther(filterDataWithout);
                                  }
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    />
                  </div>
                )}

                {bank_transaction_day_list?.length > 0 && (
                  <div>
                    <p className="DayEndText mt10">BANK TRANSACTIONS</p>

                    <Table
                      mt={5}
                      headData={[
                        "DATE",
                        "BANK DETAILS",
                        "TRANSACTION DETAILS",
                        "DEBIT",
                        "CREDIT",
                        // "BALANCE",
                        "STATUS",
                      ]}
                      body={bank_transaction_day_list?.map((v, i) => (
                        <tr key={i}>
                          <td
                            style={{
                              backgroundColor: i % 2 == 0 ? "#edeff2" : "#fff",
                            }}
                          >
                            {v?.BT_DATE}
                          </td>
                          <td
                            style={{
                              backgroundColor: i % 2 == 0 ? "#edeff2" : "#fff",
                            }}
                          >
                            {v?.BANK_ACC_NO}
                          </td>
                          <td
                            style={{
                              backgroundColor: i % 2 == 0 ? "#edeff2" : "#fff",
                            }}
                          >
                            {v?.TRANSACTION_DETAILS}
                          </td>
                          <td
                            style={{
                              backgroundColor: i % 2 == 0 ? "#edeff2" : "#fff",
                            }}
                          >
                            {v?.DEBIT}
                          </td>
                          <td
                            style={{
                              backgroundColor: i % 2 == 0 ? "#edeff2" : "#fff",
                            }}
                          >
                            {v?.CREDIT}
                          </td>
                          {/* <td
                          style={{
                            backgroundColor: i % 2 == 0 ? "#edeff2" : "#fff",
                          }}
                        >
                          {v?.BANK_ACB}
                        </td> */}
                          <td
                            style={{
                              backgroundColor: i % 2 == 0 ? "#edeff2" : "#fff",
                            }}
                          >
                            {v?.STATUS_FLAG == 1 ? (
                              "Verified"
                            ) : // v?.TRANSACTION_TYPE == "PAYMENT" ||
                            //   v?.TRANSACTION_TYPE == "RECEIPTS" ||
                            //   v?.TRANSACTION_TYPE == "BANK TRANSACTIONS"
                            v?.TRANSACTION_MODULE == "RECEIPTS DATA" ||
                              v?.TRANSACTION_MODULE == "PAYMENTS DATA" ||
                              v?.TRANSACTION_MODULE == "CREDIT NOTES" ||
                              v?.TRANSACTION_MODULE == "DEBIT NOTES" ||
                              v?.TRANSACTION_MODULE == "ASSETS DATA" ||
                              v?.TRANSACTION_MODULE ==
                                "PARTNER TRANSACTIONS" ? (
                              <Switch
                                checked={
                                  addDataBank?.filter(
                                    (s) => s?.id == v?.ID
                                  )?.[0]?.status
                                    ? true
                                    : false
                                }
                                onChange={(check) => {
                                  const filterData = addDataBank?.filter(
                                    (s) => s?.id == v?.ID
                                  );
                                  const filterDataWithout = addDataBank?.filter(
                                    (s) => s?.id != v?.ID
                                  );
                                  if (filterData?.length == 0) {
                                    setAddDataBank([
                                      ...addDataBank,
                                      {
                                        id: v?.ID,
                                        status: check,
                                        flag: v?.BTFLAG,
                                      },
                                    ]);
                                  } else if (filterData?.length > 0) {
                                    setAddDataBank(filterDataWithout);
                                  }
                                }}
                              />
                            ) : (
                              "Un-verified"
                            )}
                          </td>
                        </tr>
                      ))}
                    />
                  </div>
                )}

                {cash_transaction_day_list?.length > 0 && (
                  <div>
                    <p className="DayEndText mt10">CASH TRANSACTIONS</p>
                    <Table
                      mt={5}
                      headData={[
                        "DATE",
                        "TRANSACTION DETAILS",
                        "CASH AMOUNT",
                        // "BALANCE",
                        "STATUS",
                      ]}
                      body={cash_transaction_day_list?.map((v, i) => (
                        <tr key={i}>
                          <td>{v?.T_DATE}</td>
                          <td>{v?.TRANSACTION_TYPE}</td>
                          <td>{v?.CASH_AMOUNT}</td>
                          {/* <td>{v?.CLOSING_BALANCE}</td> */}
                          <td>
                            {v?.STATUS_FLAG == 1 ? (
                              "Verified"
                            ) : v?.TRANSACTION_TYPE == "PAYMENTS" ||
                              v?.TRANSACTION_TYPE == "RECEIPTS" ||
                              v?.TRANSACTION_TYPE == "OTHER PURCHASE" ? (
                              <Switch
                                checked={
                                  addDataCash?.filter(
                                    (s) => s?.id == v?.ID
                                  )?.[0]?.status
                                    ? true
                                    : false
                                }
                                onChange={(check) => {
                                  const filterData = addDataCash?.filter(
                                    (s) => s?.id == v?.ID
                                  );
                                  const filterDataWithout = addDataCash?.filter(
                                    (s) => s?.id != v?.ID
                                  );
                                  if (showStatus == "0") {
                                    if (filterData?.length == 0) {
                                      setAddDataCash([
                                        ...addDataCash,
                                        {
                                          id: v?.ID,
                                          status: check,
                                        },
                                      ]);
                                    } else if (filterData?.length > 0) {
                                      setAddDataCash(filterDataWithout);
                                    }
                                  }
                                }}
                              />
                            ) : (
                              "Un-verified"
                            )}
                          </td>
                        </tr>
                      ))}
                    />
                  </div>
                )}

                {(addData?.length > 0 ||
                  addDataCash?.length > 0 ||
                  addDataOther?.length > 0 ||
                  addDataBank?.length > 0) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "1.5rem 0",
                    }}
                  >
                    <Button title={"SUBMIT"} onClick={handle_update} />
                  </div>
                )}
              </>
            )}
          </>
        ))}

      {openView && (
        <Modal
          onCancel={() => {
            setViewItem({});
          }}
          isOpen={openView}
          title={
            Filter_comp_data({ type: viewItem?.TRANS_ID, omc_data: omc_data })
              ?.title
          }
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={
                  Filter_comp_data({
                    type: viewItem?.TRANS_ID,
                    omc_data: omc_data,
                  })?.table_header
                }
                body={
                  viewItem?.TRANS_ID == "1" ? (
                    <>
                      {viewItem?.products?.map((v, i) => (
                        <tr key={i}>
                          <td>{v?.CAT_Name + "-" + v?.CAT_DESC}</td>
                          <td>
                            {v?.EMR_OR_DELV == "1"
                              ? "ONE WAY LOAD"
                              : "TWO WAY LOAD"}
                          </td>
                          <td>{v?.TRUCK_NUMBER}</td>
                          <td>{v?.DRIVER_NAME}</td>
                          <td>{v?.GODOWN_NAME}</td>
                          <td>{v?.EMP_NAME}</td>
                          <td>{Number(v?.UNIT_PRICE).toFixed(2)}</td>
                          <td>{v?.QUANTITY}</td>
                          <td>{v?.GSTP}</td>
                          <td>{v?.BASIC_AMOUNT}</td>
                          <td>{Number(v?.TCS_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.IGST_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.SGST_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.CGST_AMOUNT).toFixed(2)}</td>
                          <td>{v?.C_AMOUNT}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={7} />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          TOTAL
                        </td>
                        <td />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.BASIC_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.TCS_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.IGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.SGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.CGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.C_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  ) : viewItem?.TRANS_ID == "2" ? (
                    <>
                      {viewItem?.products?.map((v, i) => (
                        <tr key={i}>
                          <td>
                            {`${v?.CAT_DESC} - ${v?.PROD_BRAND} - ${v?.PROD_NAME}`}
                          </td>

                          <td>{v?.TRUCK_NUMBER}</td>
                          <td>{v?.DRIVER_NAME}</td>
                          <td>{v?.EMP_NAME}</td>
                          <td>{v?.GST}</td>
                          <td>{Number(v?.UNIT_PRICE).toFixed(2)}</td>
                          <td>{v?.QUANTITY}</td>
                          <td>{Number(v?.BASIC_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.TDS).toFixed(2)}</td>
                          <td>
                            {Number(v?.IGST_AMOUNT.replace(/,/g, "")).toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {Number(v?.SGST_AMOUNT.replace(/,/g, "")).toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {Number(v?.CGST_AMOUNT.replace(/,/g, "")).toFixed(
                              2
                            )}
                          </td>
                          <td>{v?.C_AMOUNT}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={5} />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          TOTAL
                        </td>
                        <td />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.BASIC_AMOUNT.replace(/,/g, ""))
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.TDS)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.IGST_AMOUNT.replace(/,/g, ""))
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.SGST_AMOUNT.replace(/,/g, ""))
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.CGST_AMOUNT.replace(/,/g, ""))
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {Number(viewItem?.TOTAL_AMOUNT)?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  ) : viewItem?.TRANS_ID == "3" ? (
                    <>
                      {
                        //  showData == "2"
                        true ? (
                          <>
                            {viewItem?.products.map((v, i) => (
                              <tr key={i}>
                                <td>{v?.PNAME}</td>
                                <td>
                                  {v?.PAYMENT_MODE == "1"
                                    ? "CASH"
                                    : v?.PAYMENT_MODE == "2"
                                    ? "CHEQUE"
                                    : v?.PAYMENT_MODE == "3"
                                    ? "ONLINE"
                                    : ""}
                                </td>
                                <td>{v?.BANK_ACC_NO || "NA"}</td>
                                <td>{v?.EMP_NAME}</td>
                                <td>{v?.NARRATION}</td>
                                <td>
                                  {
                                    MHData.filter(
                                      (item) => item?.MH == v?.MH
                                    )[0]?.title
                                  }
                                </td>
                                <td>
                                  {
                                    AHData.filter(
                                      (item) => item?.AH == v?.AH
                                    )[0]?.title
                                  }
                                </td>
                                <td>{v?.QUANTITY}</td>
                                <td>
                                  {
                                    UOMData.filter(
                                      (item) => item?.UOM == v?.UOM
                                    )[0]?.title
                                  }
                                </td>
                                <td>{v?.UNIT_RATE}</td>
                                {/* <td>{v?.TDS_AMT}</td> */}
                                <td>{v?.TCS_AMOUNT}</td>
                                <td>{v?.P_AMOUNT}</td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={8} />
                              <td
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                TOTAL
                              </td>
                              <td />
                              <td
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                {viewItem?.products
                                  .map((v) => v.TCS_AMOUNT)
                                  .reduce((a, b) => Number(a) + Number(b), 0)
                                  ?.toFixed(2)}
                              </td>
                              <td
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                {viewItem?.products
                                  .map((v) => v.P_AMOUNT)
                                  .reduce((a, b) => Number(a) + Number(b), 0)
                                  ?.toFixed(2)}
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            {viewItem?.products.map((v, i) => (
                              <tr key={i}>
                                <td>{v?.PNAME}</td>
                                <td>{v?.HSN_CODE}</td>
                                <td>{v?.GSTP}</td>
                                <td>
                                  {MHData.filter((item) => item?.MH == v?.MH)[0]
                                    ?.title || ""}
                                </td>
                                <td>
                                  {AHData.filter((item) => item?.AH == v?.AH)[0]
                                    ?.title || ""}
                                </td>
                                <td>{v?.QUANTITY}</td>
                                <td>
                                  {UOMData.filter(
                                    (item) => item?.UOM == v?.UOM
                                  )[0]?.title || ""}
                                </td>
                                <td>{v?.UNIT_RATE}</td>
                                <td>{v?.BASIC_AMOUNT}</td>
                                <td>{v?.IGST_AMOUNT}</td>
                                <td>{v?.SGST_AMOUNT}</td>
                                <td>{v?.CGST_AMOUNT}</td>
                                <td>{v?.P_AMOUNT || "NA"}</td>
                              </tr>
                            ))}
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                TOTAL
                              </td>
                              <td />
                              <td
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                {viewItem?.products
                                  .map((v) => v.IGST_AMOUNT)
                                  .reduce((a, b) => Number(a) + Number(b), 0)
                                  ?.toFixed(2)}
                              </td>
                              <td
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                {viewItem?.products
                                  .map((v) => v.SGST_AMOUNT)
                                  .reduce((a, b) => Number(a) + Number(b), 0)
                                  ?.toFixed(2)}
                              </td>
                              <td
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                {viewItem?.products
                                  .map((v) => v.CGST_AMOUNT)
                                  .reduce((a, b) => Number(a) + Number(b), 0)
                                  ?.toFixed(2)}
                              </td>
                              <td
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                {viewItem?.products
                                  .map((v) => v.P_AMOUNT)
                                  .reduce((a, b) => Number(a) + Number(b), 0)
                                  ?.toFixed(2)}
                              </td>
                            </tr>
                          </>
                        )
                      }
                    </>
                  ) : viewItem?.TRANS_ID == "4" ? (
                    <>
                      {viewItem?.products?.map((v, i) => (
                        <tr key={i}>
                          <td>
                            {v?.CAT_NAME
                              ? `${v?.CAT_NAME} - ${v?.CAT_DESC}`
                              : `${v?.PROD_BRAND} - ${v?.PROD_NAME}`}
                          </td>
                          <td>
                            {v?.REASON == "1"
                              ? " DEFECTIVE ONLY"
                              : v?.REASON == "2"
                              ? "DEFECTIVE AND RETURN UNSOLD"
                              : ""}
                          </td>
                          <td>{v?.VEHICLE_NO}</td>
                          <td>{Number(v?.UNIT_RATE).toFixed(2)}</td>
                          <td>{v?.GSTP}</td>
                          <td>{v?.RTN_QTY}</td>
                          <td>{v?.CYL_GROSS_WEIGHT || "NA"}</td>
                          <td>{v?.CYL_TARE_WEIGHT || "NA"}</td>
                          <td>{v?.SR_NO || "NA"}</td>
                          <td>{v?.NET_WEIGHT || "NA"}</td>
                          <td>{v?.AMOUNT}</td>
                          <td>{Number(v?.IGST_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.SGST_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.CGST_AMOUNT).toFixed(2)}</td>
                          <td>{v?.AAMOUNT}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={8} />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          TOTAL
                        </td>
                        <td />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.IGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.SGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.CGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.AAMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  ) : viewItem?.TRANS_ID == "5" ? (
                    <>
                      {viewItem?.products?.map((v, i) => (
                        <tr key={i}>
                          <td>{v?.CAT_NAME + "-" + v?.CAT_DESC}</td>
                          <td>{v?.EMP_NAME || "NA"}</td>
                          <td>{v?.AREA_CODE || "NA"}</td>
                          <td>{v?.GSTP}</td>
                          <td>{Number(v?.UNIT_RATE).toFixed(2)}</td>
                          <td>{Number(v?.DISC_UNIT_RATE).toFixed(2)}</td>
                          <td>{v?.QUANTITY}</td>
                          <td>{v?.PRE_CYLS}</td>
                          <td>{v?.PSV_CYLS}</td>
                          <td>{Number(v?.TRANSPORT_CHARGES).toFixed(2)}</td>
                          <td>{Number(v?.SALE_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.SALE_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.AMOUNT_RCVD_ONLINE).toFixed(2)}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={7} />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          TOTAL
                        </td>
                        <td />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.TRANSPORT_CHARGES)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.SALE_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.SALE_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.AMOUNT_RCVD_ONLINE)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  ) : viewItem?.TRANS_ID == "6" ? (
                    <>
                      {viewItem?.products?.map((v, i) => (
                        <tr key={i}>
                          <td>{`${v?.CAT_NAME} - ${v?.CAT_DESC}`}</td>
                          <td>{v?.EMP_NAME}</td>
                          {/* <td>{v?.BANK_ACC_NO || "NA"}</td> */}
                          <td>{v?.AREA_CODE}</td>
                          <td>{v?.NARRATION}</td>
                          <td>{v?.GSTP}</td>
                          <td>{Number(v?.UNIT_RATE).toFixed(2)}</td>

                          <td>{Number(v?.DISC_UNIT_RATE).toFixed(2)}</td>
                          <td>{v?.QUANTITY}</td>
                          <td>{Number(v?.PSV_CYLS).toFixed(0)}</td>
                          <td>{Number(v?.PRE_CYLS).toFixed(0)}</td>
                          <td>{Number(v?.TRANSPORT_CHARGES).toFixed(2)}</td>
                          <td>{Number(v?.TCS_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.SALE_AMOUNT).toFixed(2)}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={9} />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          TOTAL
                        </td>
                        <td />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.TRANSPORT_CHARGES)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.SALE_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  ) : viewItem?.TRANS_ID == "7" ? (
                    <>
                      {viewItem?.products?.map((v, i) => (
                        <tr key={i}>
                          <td>{`${v?.CAT_DESC}-${v?.PROD_BRAND}-${v?.PROD_NAME}`}</td>
                          <td>{v?.UNIT_RATE}</td>
                          <td>{v?.GSTP}</td>
                          <td>{v?.DISC_UNIT_RATE}</td>
                          <td>{v?.QUANTITY}</td>
                          <td>{v?.TAX_AMOUNT}</td>
                          <td>{v?.TDS}</td>
                          <td>{v?.IGST_AMOUNT}</td>
                          <td>{v?.CGST_AMOUNT}</td>
                          <td>{v?.SGST_AMOUNT}</td>
                          <td>{v?.SALE_AMOUNT}</td>
                        </tr>
                      ))}
                      <tr>
                        <td
                          colSpan={5}
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                        >
                          TOTAL
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v?.TAX_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.TDS)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.IGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.SGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.CGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {Number(viewItem?.SI_AMOUNT)?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  ) : viewItem?.TRANS_ID == "8" ? (
                    <>
                      {viewItem?.products?.map((v, i) => (
                        <tr key={i}>
                          <td>{`${v?.CAT_NAME} - ${v?.PRODUCT_NAME}`}</td>
                          <td>{v?.BANK_ID == 0 ? "NA" : v?.BANK_ID}</td>
                          <td>{v?.GSTP}</td>
                          <td>{v?.RTN_QTY}</td>
                          <td>{Number(v?.NET_WEIGHT).toFixed(2)}</td>
                          <td>{Number(v?.UNIT_RATE).toFixed(2)}</td>
                          <td>{v?.AMOUNT}</td>
                          <td>{Number(v?.IGST_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.SGST_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.CGST_AMOUNT).toFixed(2)}</td>
                          <td>{Number(v?.AAMOUNT).toFixed(2)}</td>
                        </tr>
                      ))}
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          TOTAL
                        </td>
                        <td />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.IGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.SGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.CGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.AAMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  ) : viewItem?.TRANS_ID == "9" ? (
                    <>
                      {viewItem?.details?.map((v, i) => (
                        <tr key={i}>
                          <td>
                            {v?.CAT_NAME} - {v?.CAT_DESC}
                          </td>
                          <td>{v?.QUANTITY}</td>
                          <td>{v?.UNIT_RATE}</td>
                          <td>{v?.GSTP}</td>
                          <td>{v?.DEPOSIT_AMOUNT}</td>
                          <td>{v?.DISC_UNIT_RATE}</td>
                          <td>{v?.BASIC_PRICE || 0}</td>
                          <td>{v?.CGST_AMOUNT || 0}</td>
                          <td>{v?.SGST_AMOUNT || 0}</td>
                          <td>{v?.PRODUCT_AMOUNT}</td>
                        </tr>
                      ))}

                      <tr>
                        <td
                          colSpan={6}
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                        >
                          TOTAL
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.details
                            .map((v) => v?.BASIC_PRICE)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.details
                            .map((v) => v?.SGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.details
                            .map((v) => v?.CGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.details
                            .map((v) => v?.PRODUCT_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  ) : viewItem?.TRANS_ID == "10" ? (
                    <tr>
                      <td>{viewItem?.PRODUCT_NAME}</td>
                      <td>{viewItem?.NO_OF_CYL}</td>
                      <td>{viewItem?.NO_OF_REG}</td>
                      <td>{viewItem?.CYL_DEPOSIT}</td>
                      <td>{viewItem?.REG_DEPOSIT}</td>
                      <td>{viewItem?.ADMIN_CHARGES}</td>
                      <td>{viewItem?.DGCC_AMOUNT}</td>
                      <td>{viewItem?.IGST_AMOUNT}</td>
                      <td>{viewItem?.CGST_AMOUNT}</td>
                      <td>{viewItem?.SGST_AMOUNT}</td>
                      <td>Cash</td>
                    </tr>
                  ) : viewItem?.TRANS_ID == "11" ? (
                    <tr>
                      <td>{viewItem?.PRODUCT_NAME}</td>
                      <td>{viewItem?.NO_OF_CYL}</td>
                      <td>{viewItem?.NO_OF_REG}</td>
                      <td>{viewItem?.CYL_DEPOSIT}</td>
                      <td>{viewItem?.REG_DEPOSIT}</td>
                      <td>{viewItem?.ADMIN_CHARGES}</td>
                      <td>{viewItem?.IMP_AMOUNT}</td>
                      <td>{viewItem?.IGST_AMOUNT}</td>
                      <td>{viewItem?.CGST_AMOUNT}</td>
                      <td>{viewItem?.SGST_AMOUNT}</td>
                      <td>Cash</td>
                    </tr>
                  ) : (
                    <></>
                  )
                }
              />
            </>
          }
        ></Modal>
      )}
      {loading && !show && <Loader loading={loading && !show} />}
    </div>
  );
};

export default DayEnd;
