import React, { useState } from "react";
import { FiChevronRight, FiUsers } from "react-icons/fi";
import { LuCalculator, LuCreditCard } from "react-icons/lu";
import { AnimatePresence, motion } from "framer-motion";
import CustomList from "./CustomList";
import { useNavigate } from "react-router-dom";
import { BsBox, BsBoxSeam, BsTruck } from "react-icons/bs";
import { GrStorage } from "react-icons/gr";
import { BiDockBottom, BiPurchaseTag, BiShield } from "react-icons/bi";
import store from "../../redux/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const MasterDataList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);
  const { emp_data, user_type } = useSelector((state) => state.other);

  const navigate = useNavigate();
  const userIdString = localStorage.getItem("userId");
  const OMC = JSON.parse(userIdString)?.OMC;

  const handleNavigate = (path, type) => {
    if (user_type == "admin" || emp_data?.[type]) {
      navigate(path);
      close();
    } else {
      toast.info("You're not allowed to access this page", {
        toastId: "not-allowed-access", // Set a unique id for this toast
      });
    }
  };

  const menuItems = [
    {
      title: "Product Master",
      icon: <BsBox size={15} color="#6e82a5" />,
      name: "Product Master",
      children: [
        {
          title: "Equipment Master",
          permission: "EQUIPMENT_MASTER_PERMISSION",
          route: "/MasterData/EquipmentMaster",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
        {
          title:
            OMC === 1 ? "NFR Master" : OMC === 2 ? "ARB Master" : "BLPG Master",
          permission: "ARB_MASTER_PERMISSION",
          route: "/MasterData/BLPG/ARB/NFR-Master",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
        {
          title: "Services Master",
          permission: "SERVICE_MASTER_PERMISSION",
          route: "/MasterData/ServicesMaster",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
        {
          title: "Area Code Master",
          permission: "AREA_CODE_MASTER_PERMISSION",
          route: "/MasterData/AreaCodeMaster",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
        {
          title: "BOM/NC Packaging",
          permission: "BOM_PERMISSION",
          route: "/MasterData/NC/DBC-Packaging",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
      ],
    },
    {
      title: "Price Master",
      icon: <BiPurchaseTag size={15} color="#6e82a5" />,
      name: "Price Master",
      children: [
        {
          title: "Refill Price",
          permission: "REFILL_PRICE_PERMISSION",
          route: "/MasterData/Price-Refill",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
        {
          title:
            OMC === 1 ? "NFR Price" : OMC === 2 ? "ARB Price" : "BLPG Price",
          permission: "ARB_PRICE_PERMISSION",
          route: "/MasterData/BLPG/ARB/NFR-price",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
      ],
    },
    {
      title: "Cust/Vend Master",
      icon: <BiDockBottom size={15} color="#6e82a5" />,
      permission: "CUST_VEND_PERMISSION",
      route: "/MasterData/custVendMaster",
    },
    {
      title: "Discount/Credit Limit",
      icon: <LuCreditCard size={15} color="#6e82a5" />,
      route: "/MasterData/SetCreditLimit",
      permission: "DISC_CREDIT_PERMISSION",
    },
    {
      title: "Bank Master",
      icon: <BiShield size={15} color="#6e82a5" />,
      route: "/MasterData/BankMaster",
      permission: "BANK_PERMISSION",
    },
    {
      title: "Vehicles Master",
      icon: <BsTruck size={15} color="#6e82a5" />,
      route: "/MasterData/VehicalMaster",
      permission: "VEHICLE_PERMISSION",
    },
    {
      title: "Staff Master",
      icon: <FiUsers size={15} color="#6e82a5" />,
      route: "/MasterData/StaffMaster",
      permission: "STAFF_PERMISSION",
    },
    user_type === "admin" && {
      title: "Employee Master",
      icon: <FiUsers size={15} color="#6e82a5" />,
      route: "/MasterData/EmployeeMaster",
    },
    {
      title: "Stock Adjustments Module",
      icon: <BsBoxSeam size={15} color="#6e82a5" />,
      route: "/MasterData/StockAdjustMent",
      permission: "STOCK_ADJ_PERMISSION",
    },
    {
      title: "Set Alert",
      icon: <LuCalculator size={15} color="#6e82a5" />,
      route: "/MasterData/StatutoryMaster",
      permission: "ALERT_PERMISSION",
    },
  ];

  const permission_handle = (permissions = []) => {
    return (
      user_type === "admin" || permissions.some((perm) => emp_data?.[perm])
    );
  };

  const extractPermissions = (menu) => {
    let permissions = [];

    menu.forEach((item) => {
      if (item.permission) {
        permissions.push(item.permission);
      }
      if (item.children && item.children.length > 0) {
        permissions = permissions.concat(
          item.children.map((child) => child.permission)
        );
      }
    });

    return permissions;
  };


  return (
    <>
      {permission_handle(extractPermissions(menuItems)) && (
        <div
          className="list-main mt10"
          onClick={() =>
            setFocused((pre) =>
              pre.find((v) => v == "masterdata")
                ? pre.filter((v) => v != "masterdata")
                : ["masterdata"]
            )
          }
        >
          <div className="list-icon">
            <GrStorage size={18} color="#6e82a5" />
          </div>
          <div className="list-title fs15">Master Data</div>
          <div className="list-arrow">
            <FiChevronRight
              size={18}
              className={
                focused?.includes("masterdata") ? "rotate-down" : "rotate-right"
              }
            />
          </div>
        </div>
      )}

      <AnimatePresence initial={false}>
        {focused?.includes("masterdata") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <>
              {permission_handle(
                menuItems[0]?.children?.map((v) => v?.permission)
              ) && (
                <div
                  className="list-main"
                  onClick={() =>
                    setFocused((pre) =>
                      pre.find((v) => v == "Product Master")
                        ? pre.filter((v) => v != "Product Master")
                        : [...pre, "Product Master"]
                    )
                  }
                >
                  <div className="list-icon">
                    <BsBox size={15} color="#6e82a5" />
                  </div>
                  <div className="list-title fs14">Product Master</div>
                  <div className="list-arrow">
                    <FiChevronRight
                      size={18}
                      className={
                        focused.includes("Product Master")
                          ? "rotate-down"
                          : "rotate-right"
                      }
                    />
                  </div>
                </div>
              )}

              <AnimatePresence>
                {focused.includes("Product Master") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    {menuItems[0]?.children.map((v, i) => (
                      <>
                        {permission_handle([v?.permission]) && (
                          <CustomList
                            title={v?.title}
                            style={{
                              backgroundColor: path == v?.route ? "white" : "",
                            }}
                            type={v?.permission}
                            onClick={() => {
                              navigate(v?.route);
                              close();
                            }}
                          />
                        )}
                      </>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            <>
              {permission_handle(
                menuItems[1]?.children?.map((v) => v?.permission)
              ) && (
                <div
                  className="list-main"
                  onClick={() =>
                    setFocused((pre) =>
                      pre.find((v) => v == "Price Master")
                        ? pre.filter((v) => v != "Price Master")
                        : [...pre, "Price Master"]
                    )
                  }
                >
                  <div className="list-icon">
                    <BiPurchaseTag size={15} color="#6e82a5" />
                  </div>
                  <div className="list-title fs14">Price Master</div>
                  <div className="list-arrow">
                    <FiChevronRight
                      size={18}
                      className={
                        focused.includes("Price Master")
                          ? "rotate-down"
                          : "rotate-right"
                      }
                    />
                  </div>
                </div>
              )}

              <AnimatePresence>
                {focused.includes("Price Master") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    {menuItems[1]?.children.map((v, i) => (
                      <>
                        {permission_handle([v?.permission]) && (
                          <CustomList
                            title={v?.title}
                            style={{
                              backgroundColor: path == v?.route ? "white" : "",
                            }}
                            type={v?.permission}
                            onClick={() => {
                              navigate(v?.route);
                              close();
                            }}
                          />
                        )}
                      </>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            {menuItems
              ?.filter((v) => v?.permission)
              .map((v, i) => (
                <>
                  {permission_handle([v?.permission]) && (
                    <div
                      key={i}
                      className="list-main"
                      style={{
                        backgroundColor: path == v?.route ? "white" : "",
                      }}
                    >
                      <div className="list-icon">{v?.icon}</div>
                      <div
                        className="list-title fs14"
                        onClick={() =>
                          handleNavigate(v?.route, "CUST_VEND_PERMISSION")
                        }
                      >
                        {v?.title}
                      </div>
                    </div>
                  )}
                </>
              ))}
            {/* {user_type == "admin" && (
              <div
                className="list-main"
                style={{
                  backgroundColor:
                    path == "/MasterData/EmployeeMaster" ? "white" : "",
                }}
              >
                <div className="list-icon">
                  <FiUsers size={15} color="#6e82a5" />
                </div>
                <div
                  className="list-title fs14"
                  onClick={() => {
                    navigate("MasterData/EmployeeMaster");
                    close();
                  }}
                >
                  Employee Master
                </div>
              </div>
            )} */}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default MasterDataList;
