import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";
import moment from "moment";

const userIdString = localStorage.getItem("userId");
const userId = JSON.parse(userIdString)?.aid;

export const get_hsn_list_2 = createAsyncThunk("otherhsnlist", async (obj) => {
  const body = new FormData();
  body.append("search", obj?.search);
  body.append("type", obj?.type);
  const response = await fetch(base_url + "hsn_search.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

const initialState = {
  hsn_list_2: [],
};

const OtherListSlice = createSlice({
  name: "otherhsnlist",
  initialState: initialState,
  extraReducers: (builder) => {
    // HSN Code List
    builder.addCase(get_hsn_list_2.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.hsn_list_2 = data?.result;
      } else {
        state.hsn_list_2 = [];
      }
    });
  },
});

export default OtherListSlice.reducer;
