import React, { useEffect, useRef, useState } from "react";
import "./sidebar.css";
import TransactionList from "../list/TransactionList";
import logoicon from "../../assets/logo512.png";
import logotxt from "../../assets/mylpgtxt.png";
import CashnBankList from "../list/CashnBankList";
import TransReportList from "../list/TransReportList";
import { MdDelete, MdOutlineWatchLater } from "react-icons/md";
import MasterDataList from "../list/MasterDataList";
import PayRollList from "../list/PayRollList";
import { useLocation, useNavigate } from "react-router-dom";
import FinacialReportsList from "../list/FinacialReportsLinst";
import GstReturnList from "../list/GstReturnList";
import TdsTclList from "../list/TdsTcsList";
import { FiHome } from "react-icons/fi";
// import { useWindowSize } from "../../utils/data";
import store from "../../redux/store";
import { setSidebarProfileWidth } from "../../redux/slice/actionSlice";
import { useSelector } from "react-redux";
import { ProfileList } from "../list/ProfileList";
import { useWindowSize } from "../../utils/Hook/UseWindowSizeHook";
import { FaCamera } from "react-icons/fa6";
import Loader from "../Loader";
import { profile_update_api } from "../../utils/apis";
import {
  get_emp_profile_data,
  get_profile_data,
} from "../../screens/profile/UsersSlice";
import { toast } from "react-toastify";
import SmallModal from "../modal/SmallModal";
import { Input } from "../inputs/Inputs";
import Button from "../btn/Button";
import { FaRegEdit } from "react-icons/fa";
import { BiSolidUserCircle } from "react-icons/bi";

const SidebarProfile = ({ onClose }) => {
  const [showUpdatePic, setShowUpdatePic] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [rmvPic, setRmvPic] = useState(false);
  const [mess, setMess] = useState("");
  const [openView, setOpenView] = useState(false);
  const dropdownRef = useRef(null);
  const windowSize = useWindowSize();
  const { user_id, emp_id, user_type } = useSelector((state) => state.other);
  const { focused, smallSidebarProfileWidth } = useSelector(
    (state) => state.actions
  );
  const { img_url, emp_img_url, profile_data } = useSelector(
    (state) => state.profile
  );
  const userIdString = localStorage.getItem("userId");
  const Name = JSON.parse(userIdString)?.Name;
  const location = useLocation();
  const pathName = location?.pathname;
  const navigate = useNavigate();
  const handle_close_drawer = () => {
    if (windowSize?.width < 907) {
      {
        store.dispatch(setSidebarProfileWidth(!smallSidebarProfileWidth));
      }
    }
  };

  useEffect(() => {
    update_profile_data();
  }, [user_id]);

  const update_profile_data = () => {
    if (user_id) {
      store.dispatch(get_profile_data(user_id));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handle_pic_update = async () => {
    // empimageupdate(agencyid,emp_code,image)
    if (selectedFile !== null) {
      try {
        setShowLoader(true);
        const formData = new FormData();
        formData.append(
          "action",
          user_type == "employee" ? "empimageupdate" : "imageedit"
        );
        formData.append("agencyid", user_id);
        formData.append("emp_code", emp_id);
        formData.append("image", selectedFile);
        const responce = await profile_update_api(formData);
        setShowLoader(false);
        if (responce.success) {
          setSelectedFile("");
          setShowUpdatePic(false);
          toast.success(responce?.message);

          update_profile_data();
          if (user_type == "employee") {
            store.dispatch(get_emp_profile_data({ user_id, emp_id }));
          }
        } else {
          toast.error(responce?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess("Please select file first");
      setOpen(true);
    }
  };

  const remove_pic = async () => {
    try {
      try {
        setShowLoader(true);
        // empimageremove(agencyid,emp_code)
        const formData = new FormData();
        formData.append(
          "action",
          user_type == "employee" ? "empimageremove" : "imageremove"
        );
        formData.append("agencyid", user_id);
        formData.append("emp_code", emp_id);
        const responce = await profile_update_api(formData);
        setShowLoader(false);

        if (responce.success) {
          toast.success(responce?.message);
          update_profile_data();
          if (user_type == "employee") {
            store.dispatch(get_emp_profile_data({ user_id, emp_id }));
          }
          setRmvPic(false);
        } else {
          toast.error(responce?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="sidebar-main" style={{ padding: "1rem 0" }}>
      <div className="profile_pic">
        <div className="profile_pic_main">
          {user_type == "employee" ? (
            <>
              {emp_img_url ? (
                <img
                  src={emp_img_url}
                  style={{ backgroundColor: emp_img_url ? "" : "#D9D9D9" }}
                />
              ) : (
                <BiSolidUserCircle
                  style={{ height: "105%", width: "105%" }}
                  color="#6e82a5"
                />
              )}
            </>
          ) : (
            <>
              {img_url ? (
                <img
                  src={img_url}
                  style={{ backgroundColor: img_url ? "" : "#D9D9D9" }}
                />
              ) : (
                <BiSolidUserCircle
                  style={{ height: "105%", width: "105%" }}
                  color="#6e82a5"
                />
              )}
            </>
          )}

          <div className="camera_icon_div">
            <div
              onClick={() => setShowOptions(!showOptions)}
              style={{ cursor: "pointer" }}
            >
              <FaCamera size={20} />
            </div>
            {showOptions && (
              <div
                ref={dropdownRef}
                className="dropdown_menu"
                style={{
                  position: "absolute",
                  top: "40px",
                  width: "170px",
                  right: "0",
                  backgroundColor: "#fff",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 1,
                }}
              >
                <ul style={{ listStyle: "none", padding: "10px", margin: "0" }}>
                  <li
                    style={{ padding: "8px", cursor: "pointer" }}
                    onClick={() => {
                      if (img_url != "" && user_type == "admin") {
                        setOpenView(true);
                      } else if (user_type == "employee" && emp_img_url != "") {
                        setOpenView(true);
                      } else {
                        toast.error("Please Upload Image First");
                      }
                    }}
                  >
                    View Pic
                  </li>
                  <li
                    style={{ padding: "8px", cursor: "pointer" }}
                    onClick={() => {
                      setShowUpdatePic(true);
                    }}
                  >
                    Upload Pic
                  </li>
                  <li
                    style={{ padding: "8px", cursor: "pointer" }}
                    onClick={() => {
                      if (img_url != "" && user_type == "admin") {
                        setRmvPic(true);
                      } else if (user_type == "employee" && emp_img_url != "") {
                        setRmvPic(true);
                      } else {
                        toast.error("Please Upload Image First");
                      }
                    }}
                  >
                    Remove Pic
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="firm_name">
          {user_type == "admin" ? profile_data?.name : Name}
        </div>
      </div>

      {user_type === "admin" && (
        <ProfileList close={onClose} path={pathName} focused={focused} />
      )}

      {showUpdatePic && (
        <SmallModal
          okBtnHide={false}
          isOpen={showUpdatePic}
          setIsOpen={setShowUpdatePic}
          style={{ width: "25rem" }}
          okbtn={setShowUpdatePic}
          title={`UPLOAD PICTURE `}
          mess={
            <div className="pin-ver">
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      value={selectedFile?.name}
                      placeholder={"File Name"}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "30%",
                    padding: "0 1% ",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <label className="label">
                    <input
                      type="file"
                      accept=".jpg, .jpeg"
                      required
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        if (selectedFile) {
                          const fileType = selectedFile.type;
                          if (
                            fileType === "image/jpeg" ||
                            fileType === "image/jpg"
                          ) {
                            setSelectedFile(selectedFile);
                          } else {
                            alert(
                              "Please select a valid image file (either .jpg or .jpeg)."
                            );
                            e.target.value = null;
                          }
                        }
                      }}
                    />

                    <span style={{ fontSize: "13px" }}>Browse</span>
                  </label>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: "fit-content", margin: "1.5rem 0" }}>
                  <Button
                    title={"UPLOAD"}
                    onClick={() => handle_pic_update()}
                  />
                </div>
              </div>
            </div>
          }
        />
      )}

      {rmvPic && (
        <SmallModal
          isOpen={rmvPic}
          setIsOpen={setRmvPic}
          onClick={() => remove_pic()}
          title={"Confirm"}
          mess={`ARE YOU SURE TO REMOVE PROFILE PIC`}
        />
      )}
      {openView && (
        <SmallModal
          okbtn={true}
          okBtnHide={false}
          isOpen={openView}
          setIsOpen={setOpenView}
          title={"Profile Pic"}
          mess={
            <div>
              <img
                style={{
                  height: "200px",
                  width: "200px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                src={user_type == "employee" ? emp_img_url : img_url}
                alt=""
              />
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <MdDelete
                  size={24}
                  color="rgb(255, 0, 0)"
                  cursor="pointer"
                  onClick={() => {
                    setOpenView(false);
                    setRmvPic(true);
                  }}
                />
                <FaRegEdit
                  size={24}
                  color="#007bff"
                  cursor="pointer"
                  onClick={() => {
                    setOpenView(false);
                    setShowUpdatePic(true);
                  }}
                />
              </div>
            </div>
          }
        />
      )}
      {open && (
        <SmallModal
          title={"Alerts"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={mess}
          okbtn={true}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default SidebarProfile;
