import React, { useEffect, useMemo, useRef, useState } from "react";
import PageHeading from "../../components/PageHeading";
import RowTxt from "../../components/RowTxt";
import { motion } from "framer-motion";
import Button from "../../components/btn/Button";
import { MdAdd } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";
import { HiOutlineArrowRight } from "react-icons/hi";
import Dropdown from "../../components/inputs/Dropdown";
import Input from "../../components/inputs/Input";
import Modal from "../../components/modal/Modal";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import {
  get_bank_master_list,
  get_staff_list,
} from "../MasterData/masterdataSlice";
import { BackButton, SaveButton } from "../../components/btn/Buttons";
import RadioBtn from "../../components/radio/RadioBtn";
import { BankCodeFinder, DeleteIcon, Radio } from "../../utils/utils";
import { get_payment_list } from "./cashBankSlice";
import {
  Pagination2,
  Pagination3,
} from "../../components/pagination/pagination";
import PageLoading from "../../components/PageLoading";
import NoData from "../../components/NoData";
import moment from "moment";
import SmallModal from "../../components/modal/SmallModal";
import { payment_API } from "../../utils/apis";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import Table from "../../components/table/Table";
import { DropInput } from "../../components/inputs/DropInput";
import { get_transition_complete_list } from "../transactions/trasactionsSlice";
import { DateValidator2 } from "../../utils/validation";
import { Alerts } from "../../components/Alerts/Alerts";
import { TaxInvoice } from "../OtherComponent/TaxInvoice/TaxInvoice";
import ReactToPrint from "react-to-print";
import { PaymentToParty } from "../../utils/data";
import SearchApiHook from "../../CustomHooks/SearchApiHook";
import TaxInvoice4 from "../OtherComponent/TaxInvoice/TaxInvoice4";

const Payments = () => {
  const previewRef = useRef(null);
  const [isAdd, setIsAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [mess, setMess] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [entries, setEntries] = useState("5");
  const [viewItem, setViewItem] = useState({});
  const [openView, setOpenView] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [addDataHead, setAddDataHead] = useState({
    instruction: "NA",
  });

  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };
  const { bank_master_list, staff_list } = useSelector(
    (state) => state.masterdata
  );
  const { transition_complete_list } = useSelector(
    (state) => state.transactions
  );

  const { day_end_date, user_turnover } = useSelector((state) => state.profile);
  const { user_id, today, emp_id, user_type } = useSelector(
    (state) => state.other
  );
  const { payment_list, payment_loading } = useSelector(
    (state) => state.cashbanks
  );

  useEffect(() => {
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_bank_master_list());
    store.dispatch(get_staff_list());
  }, []);

  const fetchData = () => {
    store.dispatch(get_payment_list(obj));
  };

  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const onDelete_item = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", DeleteId);
      if (emp_id) {
        body.append("emp_code", emp_id);
      }
      const response = await payment_API(body);
      setShowLoader(false);
      if (response?.success) {
        setPageNo(
          payment_list?.length < 2 && pageNo != 1 ? pageNo - 1 : pageNo
        );
        store.dispatch(
          get_payment_list({
            pageNo:
              payment_list?.length < 2 && pageNo != 1 ? pageNo - 1 : pageNo,
            entries: entries,
            setTotalCount: setTotalCount,
            setSearchCount: setSearchCount,
            search: SerchInput,
          })
        );
        toast(response?.message);
        setOpen(false);
      } else {
        setMess(response?.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate = (type) => {
    const data = [];
    if (
      !addDataHead?.date ||
      (addDataHead?.date && addDataHead?.date?.length != 10)
    ) {
      data.push("Please Enter Date");
    } else if (
      DateValidator2(
        moment(addDataHead?.date).format("DD/MM/YYYY"),
        today,
        day_end_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addDataHead?.date).format("DD/MM/YYYY"),
          today,
          day_end_date,
          `Voucher Date cant be befor as a day end date ${day_end_date}`,
          `Voucher Date cant be a future date`
        )
      );
    }
    if (
      !addDataHead?.amount ||
      (addDataHead?.amount && addDataHead?.amount?.length == 0)
    ) {
      data.push("Please Enter Amount");
    }

    if (
      !addDataHead?.recived_from ||
      (addDataHead?.recived_from && addDataHead?.recived_from?.length == 0)
    ) {
      data.push("Please Select Recieved from");
    }

    if (
      !addDataHead?.payer_name ||
      (addDataHead?.payer_name && addDataHead?.payer_name?.length == 0)
    ) {
      data.push("Please Enter Payer Name");
    }

    if (
      !addDataHead?.mode ||
      (addDataHead?.mode && addDataHead?.mode?.length == 0)
    ) {
      data.push("Please Select Mode of Payment");
    }
    if (addDataHead?.mode == "2") {
      if (
        !addDataHead?.cheque_no ||
        (addDataHead?.cheque_no && addDataHead?.cheque_no?.length == 0)
      ) {
        data.push("Please Enter Cheque Number");
      } else if (addDataHead?.cheque_no?.length != 6) {
        data.push("Cheque Number contain 6 digit only");
      } else if (addDataHead?.cheque_no < 100000) {
        data.push("Cheque Number cant be start with 0");
      }

      if (
        !addDataHead?.cheque_clear ||
        (addDataHead?.cheque_clear && addDataHead?.cheque_clear?.length == 0)
      ) {
        data.push("Please Select Cheque Clearence Status");
      }
      if (
        !addDataHead?.cheque_date ||
        (addDataHead?.cheque_date && addDataHead?.cheque_date?.length == 0)
      ) {
        data.push("Please Enter Cheque Date");
      }
    } else if (addDataHead?.mode == "3") {
      if (
        !addDataHead?.instruction ||
        (addDataHead?.instruction && addDataHead?.instruction?.length == 0) ||
        addDataHead?.instruction == "NA"
      ) {
        data.push("INSTRUMENT DETAILS Is Mandatory For Online Transfer.");
      }
      if (
        !addDataHead?.mode_of_trans ||
        (addDataHead?.mode_of_trans && addDataHead?.mode_of_trans?.length == 0)
      ) {
        data.push("Mode of Transaction is Mandatory for Online Transfer.");
      } else if (
        addDataHead?.mode_of_trans >= 1 &&
        addDataHead?.mode_of_trans <= 4 &&
        Number(addDataHead?.amount) > 100000
      ) {
        data.push("UPI-Maximum Limit Was 1,00,000 Per Day");
      }
    }

    if (type != "calculate" || !type) {
      if (
        !addDataHead?.bank_account ||
        (addDataHead?.bank_account && addDataHead?.bank_account?.length == 0)
      ) {
        data.push("Please Select Bank Account");
      }

      if (
        !addDataHead?.emp_code ||
        (addDataHead?.emp_code && addDataHead?.emp_code?.length == 0)
      ) {
        data.push("Please Select PAID By");
      }
      if (
        !(
          addDataHead?.mode == "1" &&
          (addDataHead?.recived_from == "1" || addDataHead?.recived_from == "8")
        )
      ) {
        if (
          !addDataHead?.charges ||
          (addDataHead?.charges && addDataHead?.charges?.length == 0)
        ) {
          data.push("Please Enter Charges");
        }
      }

      if (
        !addDataHead?.narration ||
        (addDataHead?.narration && addDataHead?.narration?.length == 0)
      ) {
        data.push("Please Enter Narration");
      }
      if (
        (addDataHead?.recived_from == "1" &&
          (addDataHead?.mode == "1" || addDataHead?.mode == "2")) ||
        (addDataHead?.recived_from == "2" && addDataHead?.mode == "2")
      ) {
        if (
          (!addDataHead?.tds_app ||
            (addDataHead?.tds_app && addDataHead?.tds_app?.length == 0)) &&
          addDataHead?.tds_app != "0"
        ) {
          data.push("Please Select Tds applicable or not");
        }
      }
    }
    if ((type != "calculate" || !type) && addDataHead?.tds_app == "1") {
      if (
        !addDataHead?.under_section ||
        (addDataHead?.under_section && addDataHead?.under_section?.length == 0)
      ) {
        data.push("Please Select Under Section");
      }
      if (
        !addDataHead?.tds_charges ||
        (addDataHead?.tds_charges && addDataHead?.tds_charges?.length == 0)
      ) {
        data.push("Please Select TDS Charges");
      }
      if (
        !addDataHead?.tds_amt ||
        (addDataHead?.tds_amt && addDataHead?.tds_amt?.length == 0)
      ) {
        data.push("Please Click on Calculate first ");
      }
    }
    return data;
  };

  const add_items = async () => {
    const data = validate();

    if (data?.length == 0) {
      const data1 = bank_master_list?.filter(
        (v) => v?.ID == addDataHead?.bank_account
      );
      // if (Number(data1?.[0]?.ACC_CB) < Number(addDataHead?.amount)) {
      //   setOpen2(true);
      //   return;
      // }
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("payment_amt", addDataHead?.amount);
        body.append("payment_date", addDataHead?.date);
        body.append("cust_ven", addDataHead?.recived_from);
        body.append("tax_type", addDataHead?.recived_from);
        body.append("paid_to", addDataHead?.payer_name_id);
        body.append("paid_to_name", addDataHead?.payer_name);
        body.append("payment_mode", addDataHead?.mode);
        body.append("ins_detail", addDataHead?.instruction);
        body.append("debite_bank", addDataHead?.bank_account);
        body.append("staff_id", addDataHead?.emp_code);
        body.append("bankcharge", addDataHead?.charges);
        body.append("narration", addDataHead?.narration);
        body.append("cheque_no", addDataHead?.cheque_no || "");
        body.append("cheque_date", addDataHead?.cheque_date || "");
        body.append("check_clearance", addDataHead?.cheque_clear || "");
        body.append("MODE_TRANSACTION", addDataHead?.mode_of_trans);
        if (
          (addDataHead?.recived_from == "1" &&
            (addDataHead?.mode == "1" || addDataHead?.mode == "2")) ||
          (addDataHead?.recived_from == "2" && addDataHead?.mode == "2")
        ) {
          body.append("tds_type", addDataHead?.tds_app);
          body.append("under_section", addDataHead?.under_section);
          body.append("tds_amt", addDataHead?.tds_amt);
          body.append("rate_of_tds", addDataHead?.tds_charges);
        }
        if (emp_id) {
          body.append("emp_code", emp_id);
        }
        const responce = await payment_API(body);
        setShowLoader(false);
        if (responce?.success) {
          store.dispatch(get_payment_list(obj));
          setIsAdd(false);
          setAddDataHead({});
          toast(responce.message);
        } else if (!responce?.success) {
          setMess(responce.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const calculate = () => {
    const data = validate("calculate");
    if (data?.length == 0) {
      const amount = Number(addDataHead?.amount);
      const tds_charges = Number(addDataHead?.tds_charges);
      const tds_amt_n = (amount * tds_charges) / 100;
      setAddDataHead({ ...addDataHead, tds_amt: Number(tds_amt_n).toFixed(2) });
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);

  return (
    <div className="container">
      <PageHeading
        title={"PAYMENTS"}
        right={
          <>
            <Button
              title={"ADD"}
              icon={<MdAdd size={20} color="#fff" />}
              onClick={() => {
                setIsAdd(true);
                setAddDataHead({ ...addDataHead, instruction: "NA" });
              }}
            />
            <BackButton />
          </>
        }
      />
      <Pagination3
        top={true}
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {payment_loading ? (
          <PageLoading />
        ) : payment_list?.length == 0 ? (
          <NoData />
        ) : (
          <>
            <div className="grid">
              {payment_list?.map((v, i) => (
                <div className="card" key={i}>
                  <div>
                    <div className="card-heading">{v?.PAID_TO_NAME} </div>
                    <RowTxt
                      title={"Voucher No."}
                      value={
                        <ReactToPrint
                          trigger={() => (
                            <div
                              onTouchStart={() => {
                                setViewItem(v);
                              }}
                              onMouseEnter={() => {
                                setViewItem(v);
                              }}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setViewItem(v);
                              }}
                            >
                              {v?.SR_NO}
                            </div>
                          )}
                          content={() => previewRef.current}
                          documentTitle={`${v?.RCVD_FROM_NAME}-${v?.SR_NO}`}
                        />
                      }
                    />
                    <RowTxt
                      title={"Voucher Date"}
                      value={moment(v?.voucher_Date).format("DD-MMM-YYYY")}
                    />
                    <RowTxt title={"Voucher Amt."} value={v?.PYMT_AMOUNT} />
                    <RowTxt title={"Staff Name"} value={v?.EMP_NAME} />
                    <RowTxt title={"Narration"} value={v?.NARRATION} />
                    {v?.TAX_TYPE >= 3 && v?.TAX_TYPE <= 8 && (
                      <RowTxt
                        title={"Tax Type"}
                        value={
                          PaymentToParty?.filter(
                            (val) => val?.id == v?.TAX_TYPE
                          )?.[0]?.lable
                        }
                      />
                    )}

                    <RowTxt
                      title={"Payment Mode"}
                      value={
                        v?.PAYMENT_MODE == "1"
                          ? "CASH"
                          : v?.PAYMENT_MODE == "2"
                          ? "CHEQUE"
                          : v?.PAYMENT_MODE == "3"
                          ? "ONLINE"
                          : ""
                      }
                    />
                    {v?.PAYMENT_MODE == "3" && (
                      <RowTxt
                        title={"Transaction Mode"}
                        value={
                          v?.TRANSACTION_MODE == "1"
                            ? "Phonepay"
                            : v?.TRANSACTION_MODE == "2"
                            ? "GPay"
                            : v?.TRANSACTION_MODE == "3"
                            ? "Paytm"
                            : v?.TRANSACTION_MODE == "4"
                            ? "Cred"
                            : v?.TRANSACTION_MODE == "6"
                            ? "RTGS"
                            : v?.TRANSACTION_MODE == "7"
                            ? "NEFT"
                            : v?.TRANSACTION_MODE == "8"
                            ? "IMPS"
                            : v?.TRANSACTION_MODE == "5"
                            ? "Others"
                            : ""
                        }
                      />
                    )}
                    <RowTxt
                      title={"Created By"}
                      value={v?.CREATED_NAME || "-"}
                    />
                  </div>
                  <div
                    className="df jcsb mt10"
                    style={{
                      justifyContent:
                        user_type != "admin" && v?.EMPLOYEE_CODE != emp_id
                          ? "flex-end"
                          : "space-between",
                    }}
                  >
                    {v?.STATUS_FLAG == 0 ? (
                      <>
                        {user_type == "admin" || v?.EMPLOYEE_CODE == emp_id ? (
                          <DeleteIcon
                            onClick={() => {
                              setOpen(true);
                              setDeleteId(v?.ID);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <div></div>
                    )}

                    <motion.div
                      onClick={() => {
                        setViewItem(v);
                        setOpenView(true);
                      }}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 1.05 }}
                      className="flex aic gap10 jce cursor-pointer"
                    >
                      <div className="view-btn">View</div>
                      <HiOutlineArrowRight size={20} color="#1b64af" />
                    </motion.div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button title={"CVO"} back />
              <Button title={"BANK"} back />
              <Button title={"STAFF"} back />
            </div>
          }
          title={"PAYMENTS"}
          body={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
              }}
            >
              <div className="flex aic gap10 credit-limit">
                <Input
                  label={"VOUCHER DATE"}
                  type={"date"}
                  value={addDataHead?.date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        date: e.target.value,
                      });
                    }
                  }}
                />
                <Input
                  label={"AMOUNT"}
                  placeholder={"AMOUNT"}
                  value={addDataHead?.amount}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    if (
                      (inputValue?.length <= 10 &&
                        /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(inputValue)) ||
                      inputValue == 0 ||
                      !addDataHead?.amount
                    ) {
                      setAddDataHead({
                        ...addDataHead,
                        amount: /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                          inputValue
                        )
                          ? inputValue
                          : "",
                        tds_amt: "",
                      });
                    }
                  }}
                />
                <Dropdown
                  label={"TO PARTY"}
                  width2=""
                  value={addDataHead?.recived_from}
                  onChange={(e) => {
                    const input = e.target.value;
                    setAddDataHead({
                      ...addDataHead,
                      recived_from: input,
                      payer_name: "",
                      payer_name_id: "",
                      tds_app: input == "1" || input == "2" ? "0" : "",
                      tds_amt:
                        input == "1" || input == "2"
                          ? ""
                          : addDataHead?.tds_amt || "",
                      tds_charges:
                        input == "1" || input == "2"
                          ? ""
                          : addDataHead?.tds_charges || "",
                      under_section:
                        input == "1" || input == "2"
                          ? ""
                          : addDataHead?.under_section || "",
                      mode:
                        input > 2 && input < 7
                          ? "3"
                          : input == 2
                          ? "2"
                          : input == 7
                          ? "3"
                          : "",
                      mode_dis:
                        (input > 2 && input < 7) || input == 2 || input == 7
                          ? true
                          : false,
                      tds_app_dis: false,
                    });
                  }}
                  options={
                    <>
                      <option value="">SELECT</option>
                      {PaymentToParty?.map((v, i) => (
                        <option key={i} value={v?.id}>
                          {v?.lable}
                        </option>
                      ))}
                    </>
                  }
                />
                <DropInput
                  label={"PARTY NAME"}
                  id="custvend"
                  placeholder={"PARTY NAME"}
                  value={addDataHead?.payer_name}
                  onChange={(e) => {
                    const data = [
                      ...transition_complete_list?.Customer,
                      ...transition_complete_list?.Vendor,
                      ...transition_complete_list?.Others,
                    ]?.filter((v) => v?.ID == e.target.value);
                    setAddDataHead({
                      ...addDataHead,
                      payer_name: data[0]?.cvo_name || e.target.value,
                      payer_name_id: data?.length == 0 ? 0 : e.target.value,
                      // tds_app:
                      //   user_turnover < 4 && data?.[0]?.ANNUAL_TURNOVER == 0
                      //     ? 0
                      //     : user_turnover >= 4 &&
                      //       data?.[0]?.ANNUAL_TURNOVER == 1
                      //     ? 1
                      //     : user_turnover >= 4 &&
                      //       data?.[0]?.ANNUAL_TURNOVER == 0
                      //     ? 1
                      //     : user_turnover < 4 && data?.[0]?.ANNUAL_TURNOVER == 1
                      //     ? 1
                      //     : e.target.value != ""
                      //     ? 0
                      //     : "",
                      // tds_app_dis: e.target.value == "" ? false : true,
                    });
                  }}
                  list={(addDataHead?.recived_from == "2"
                    ? transition_complete_list?.Customer
                    : addDataHead?.recived_from == "1"
                    ? transition_complete_list?.Vendor
                    : addDataHead?.recived_from == "8"
                    ? transition_complete_list?.Others
                    : []
                  )?.map((v, i) => (
                    <option key={i} value={v?.ID}>
                      {v?.cvo_name?.toUpperCase()}
                    </option>
                  ))}
                />
                <Dropdown
                  label={"MODE OF PAYMENT"}
                  width2=""
                  value={addDataHead?.mode}
                  disabled={addDataHead?.mode_dis}
                  onChange={(e) => {
                    const input = e.target.value;
                    setAddDataHead({
                      ...addDataHead,
                      mode: input,
                      bank_account:
                        input == "1"
                          ? bank_master_list?.filter(
                              (v) => v?.BANK_CODE == "CASH ACCOUNT"
                            )?.[0]?.ID
                          : "",
                      // tds_app: input == "1" ? "0" : "",
                      // tds_app_dis: input == "1" ? true : false,
                      tds_amt: input == "1" ? "" : addDataHead?.tds_amt || "",
                      tds_charges:
                        input == "1" ? "" : addDataHead?.tds_charges || "",
                      under_section:
                        input == "1" ? "" : addDataHead?.under_section || "",
                      instruction: input == "3" ? "" : "NA",
                      charges:
                        input == "1" &&
                        (addDataHead?.recived_from == "1" ||
                          addDataHead?.recived_from == "8")
                          ? 0.0
                          : "",
                      mode_of_trans: "",
                    });
                  }}
                  options={
                    <>
                      <option value="">SELECT</option>
                      <option value="1">CASH</option>
                      <option value="2">CHEQUE</option>
                      <option value="3">ONLINE</option>
                    </>
                  }
                />
                {addDataHead?.mode == "2" && (
                  <>
                    <Input
                      label={"CHEQUE NO."}
                      placeholder={"CHEQUE NO."}
                      value={addDataHead?.cheque_no}
                      onChange={(e) => {
                        const inputValue = e.target.value.replace(/\D/g, "");
                        if (inputValue?.length <= 6) {
                          setAddDataHead({
                            ...addDataHead,
                            cheque_no: inputValue,
                          });
                        }
                      }}
                    />

                    <Dropdown
                      label={"CHEQUE CLEARENCE"}
                      width2=""
                      value={addDataHead?.cheque_clear}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          cheque_clear: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value="">SELECT</option>
                          <option value="1">IN PROCESS</option>
                          <option value="2">DONE</option>
                        </>
                      }
                    />
                    <Input
                      label={"CHEQUE DATE"}
                      type={"date"}
                      value={addDataHead?.cheque_date}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setAddDataHead({
                            ...addDataHead,
                            cheque_date: e.target.value,
                          });
                        }
                      }}
                    />
                  </>
                )}

                <Input
                  label={"INSTRUMENT DETAILS/TXR NO	"}
                  placeholder={"INSTRUMENT DETAILS/TXR NO	"}
                  value={addDataHead?.instruction}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      instruction: e.target.value,
                    });
                  }}
                />
                {addDataHead?.mode == "3" && (
                  <Dropdown
                    width2=""
                    label={"MODE OF TRANSACTION"}
                    value={addDataHead?.mode_of_trans}
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        mode_of_trans: e.target.value,
                      });
                    }}
                    options={
                      <>
                        <option value="">SELECT</option>
                        <option disabled>---------UPI--------</option>
                        <option value="1">Phonepay</option>
                        <option value="2">GPay</option>
                        <option value="3">Paytm</option>
                        <option value="4">Cred</option>
                        <option disabled>-----Net Banking----</option>
                        <option value="6">RTGS</option>
                        <option value="7">NEFT</option>
                        <option value="8">IMPS</option>
                        <option value="5">Others</option>
                      </>
                    }
                  />
                )}

                <Dropdown
                  label={"DEBITED (BANK)"}
                  disabled={addDataHead?.mode == "1"}
                  width2=""
                  value={addDataHead?.bank_account}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      bank_account: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value="">SELECT</option>
                      {bank_master_list
                        ?.filter((v, i) => v?.BANK_CODE != "STAR ACCOUNT")
                        // ?.filter((v, i) => v?.BANK_CODE != "OVER DRAFT")
                        ?.filter((v, i) => v?.BANK_CODE != "TAR ACCOUNT")
                        ?.filter((v, i) => v?.BANK_CODE != "LOAN")
                        ?.map((v, i) => (
                          <option
                            disabled={
                              (addDataHead?.mode == 2 ||
                                addDataHead?.mode == 3) &&
                              v?.BANK_CODE == "CASH ACCOUNT"
                            }
                            key={i}
                            value={v?.ID}
                          >
                            {bankCode(v?.BANK_CODE)} -
                            {v?.BANK_ACC_NO == "NA" ? user_id : v?.BANK_ACC_NO}
                          </option>
                        ))}
                    </>
                  }
                />
                <Dropdown
                  label={"PAID BY	"}
                  width2=""
                  value={addDataHead?.emp_code}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      emp_code: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value=""> SELECT</option>
                      {staff_list?.map((v, i) => (
                        <option key={i} value={v?.ID}>
                          {v?.EMP_NAME}
                        </option>
                      ))}
                    </>
                  }
                />
                <Input
                  label={"CHARGES"}
                  placeholder={"CHARGES"}
                  value={addDataHead?.charges}
                  disabled={
                    addDataHead?.mode == "1" &&
                    (addDataHead?.recived_from == "1" ||
                      addDataHead?.recived_from == "8")
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    if (
                      (inputValue?.length <= 10 &&
                        /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(inputValue)) ||
                      inputValue == 0 ||
                      !addDataHead?.charges
                    ) {
                      setAddDataHead({
                        ...addDataHead,
                        charges: /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                          inputValue
                        )
                          ? inputValue
                          : "",
                      });
                    }
                  }}
                />
                <Input
                  label={"NARRATION"}
                  placeholder={"NARRATION"}
                  value={addDataHead?.narration}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      narration: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <div
                  className="flex aic gap10 credit-limit"
                  style={{
                    flexWrap: "wrap",
                    rowGap: "0",
                  }}
                >
                  {/* {addDataHead?.tds_app_dis_show && ( */}
                  {((addDataHead?.recived_from == "1" &&
                    (addDataHead?.mode == "2" || addDataHead?.mode == "3")) ||
                    (addDataHead?.recived_from == "2" &&
                      addDataHead?.mode == "2")) && (
                    <div className="flex aic gap10">
                      <RadioBtn label={"TDS :"} />
                      <Radio
                        disabled={addDataHead?.tds_app_dis}
                        title={"YES"}
                        show={addDataHead?.tds_app == "1" ? "YES" : ""}
                        setShow={() => {
                          setAddDataHead({
                            ...addDataHead,
                            tds_app: 1,
                            under_section: "1",
                            tds_charges: "0.1",
                          });
                        }}
                      />
                      <Radio
                        disabled={addDataHead?.tds_app_dis}
                        title={"NO"}
                        show={addDataHead?.tds_app == "0" ? "NO" : ""}
                        setShow={() => {
                          setAddDataHead({
                            ...addDataHead,
                            tds_app: 0,
                            under_section: "",
                            tds_charges: "",
                            tds_amt: "",
                          });
                        }}
                      />
                    </div>
                  )}
                  {addDataHead?.tds_app == "1" && (
                    <>
                      <Dropdown
                        label={"UNDER SECTION"}
                        width2=""
                        value={addDataHead?.under_section}
                        onChange={(e) => {
                          setAddDataHead({
                            ...addDataHead,
                            under_section: e.target.value,
                          });
                        }}
                        disabled={addDataHead?.tds_app == "1" ? false : true}
                        options={
                          <>
                            <option value=""> SELECT</option>
                            <option value="1"> us 206 c1 (H1)</option>
                            <option value="2"> 194Q Section</option>
                          </>
                        }
                      />
                      <Dropdown
                        label={"RATE OF TDS"}
                        width2=""
                        value={addDataHead?.tds_charges}
                        onChange={(e) => {
                          setAddDataHead({
                            ...addDataHead,
                            tds_charges: e.target.value,
                            tds_amt:"",
                          });
                        }}
                        disabled={addDataHead?.tds_app == "1" ? false : true}
                        options={
                          <>
                            <option value=""> SELECT</option>
                            <option value="0.1"> 0.1%</option>
                            <option value="1"> 1%</option>
                            <option value="2"> 2%</option>
                            <option value="5"> 5%</option>
                            <option value="10"> 10%</option>
                            <option value="20"> 20%</option>
                            <option value="30"> 30%</option>
                            <option value="40"> 40%</option>
                            <option value="50"> 50%</option>
                            {/* 0.1,1,2,5,10,20,30,40,50 */}
                          </>
                        }
                      />
                      <Input
                        label={"TDS AMOUNT"}
                        placeholder={"TDS AMOUNT"}
                        value={addDataHead?.tds_amt}
                        disabled={true}
                      />
                    </>
                  )}
                </div>
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <Button
                  disable={addDataHead?.tds_app == "1" ? false : true}
                  onClick={calculate}
                  title={"CALCULATE"}
                />
                <SaveButton back onClick={add_items} />
              </div>
            </div>
          }
        ></Modal>
      )}

      {openView && (
        <Modal
          isOpen={openView}
          onCancel={() => {
            setViewItem({});
          }}
          header_right={
            <ReactToPrint
              trigger={() => (
                <Button style={{ cursor: "pointer" }} title={"PRINT"} />
              )}
              content={() => previewRef.current}
              documentTitle={`${viewItem?.RCVD_FROM_NAME}-${viewItem?.SR_NO}`}
            />
          }
          title={viewItem?.PAID_TO_NAME}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={[
                  "MODE OF PAYMENT",
                  "CHEQUE NO.",
                  "CHEQUE CLEARENCE",
                  "CHEQUE DATE",
                  "INSTRUMENT DETAILS/TXR NO",
                  "DEBITED TO BANK",
                  "PAID BY",
                  "CHARGES",
                  "NARRATION",
                  "TDS ",
                  "UNDER SECTION",
                  "RATE OF TDS",
                  "TDS AMOUNT",
                ]}
                body={
                  <tr>
                    <td>
                      {viewItem?.PAYMENT_MODE == "1"
                        ? "CASH"
                        : viewItem?.PAYMENT_MODE == "2"
                        ? "CHEQUE"
                        : viewItem?.PAYMENT_MODE == "3"
                        ? "ONLINE"
                        : ""}
                    </td>
                    <td>{viewItem?.CHEQUE_NO || "NA"}</td>
                    <td>
                      {viewItem?.CHEQUE_CLEARANCE == "1"
                        ? "IN PROCESS"
                        : viewItem?.CHEQUE_CLEARANCE == "2"
                        ? "DONE"
                        : "NA"}
                    </td>
                    <td>
                      {viewItem?.CLEARED_DATE
                        ? moment(viewItem?.CLEARED_DATE).format("DD-MMM-YYYY")
                        : "NA"}
                    </td>
                    <td>{viewItem?.INSTR_DETAILS}</td>
                    <td>{viewItem?.BANK_ACC_NO}</td>
                    <td>{viewItem?.EMP_NAME}</td>
                    <td>{viewItem?.BANK_CHARGES}</td>
                    <td>{viewItem?.NARRATION}</td>
                    <td>{viewItem?.TDS_TYPE == "1" ? "YES" : "NO"}</td>
                    <td>
                      {viewItem?.UNDER_SECTION == "1"
                        ? " us 206 c1 (H1)"
                        : viewItem?.UNDER_SECTION == "2"
                        ? "194Q Section"
                        : "NA"}
                    </td>
                    <td>{viewItem?.RATE_OF_TDS || "NA"}</td>
                    <td>{viewItem?.TDS_AMOUNT || "NA"}</td>
                  </tr>
                }
              />
            </>
          }
        ></Modal>
      )}

      <div style={{ display: "none" }}>
        <TaxInvoice4
          previewRef={previewRef}
          doc_name={"Payment Voucher"}
          time_of={"PAYMENT"}
          terms={false}
          listdata={{
            SR_NO: viewItem?.SR_NO,
            SR_NO_DATE: viewItem?.voucher_Date,
            CVO_NAME: viewItem?.CVO_NAME,
            CVO_ADDRESS: viewItem?.BILLING_ADDRESS,
            CVO_DISTRICT: viewItem?.B_DISTRICT,
            CVO_LOCATION: viewItem?.B_LOCATION,
            B_STATE_CODE: viewItem?.B_STATE_CODE,
            B_STATE_NAME: viewItem?.B_STATE_NAME,
            CVO_PINCODE: viewItem?.B_PINCODE,
            CVO_TIN: viewItem?.CVO_TIN,
            EMAIL: viewItem?.CVO_EMAIL,
            MOBILE: viewItem?.CVO_CONTACT,
            NARRATION: viewItem?.NARRATION,
            make_of_payment:
              viewItem?.PAYMENT_MODE == "1"
                ? "CASH"
                : viewItem?.PAYMENT_MODE == "2"
                ? "CHEQUE"
                : viewItem?.PAYMENT_MODE == "3"
                ? "ONLINE"
                : "",
            AMOUNT: viewItem?.PYMT_AMOUNT,
            cheque_number: viewItem?.CHEQUE_NO,
            intrument_details: viewItem?.INSTR_DETAILS,
            tr_mode:
              viewItem?.TRANSACTION_MODE == "1"
                ? "Phonepay"
                : viewItem?.TRANSACTION_MODE == "2"
                ? "GPay"
                : viewItem?.TRANSACTION_MODE == "3"
                ? "Paytm"
                : viewItem?.TRANSACTION_MODE == "4"
                ? "Cred"
                : viewItem?.TRANSACTION_MODE == "6"
                ? "RTGS"
                : viewItem?.TRANSACTION_MODE == "7"
                ? "NEFT"
                : viewItem?.TRANSACTION_MODE == "8"
                ? "IMPS"
                : viewItem?.TRANSACTION_MODE == "5"
                ? "Others"
                : "",
          }}
          net_total={viewItem?.PYMT_AMOUNT}
        />
      </div>

      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => onDelete_item()}
        />
      )}

      {open2 && (
        <SmallModal
          okbtn={true}
          okBtnText="OK"
          title={"Confirm"}
          isOpen={open2}
          setIsOpen={setOpen2}
          mess={
            "Your Selected Bank Account Closing Amount is Very Low, Please Check and try again?"
          }
        />
      )}

      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {showLoader && <Loader loading={showLoader} />}
    </div>
  );
};

export default Payments;
