import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomList from "./CustomList";
import { FiChevronRight } from "react-icons/fi";
import styles from "../../screens/profile/profile.module.css";

export const ProfileList = ({ close }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.pathname;
  return (
    <div className="profile_main">
      <CustomListProfile
        icon={<div />}
        title={"Agency Information"}
        fW={path == "/profile/Ainfo" ? "bold" : ""}
        onClick={() => {
          navigate("/profile/Ainfo");
          close();
        }}
      />
      <CustomListProfile
        icon={<div />}
        title={"Personal Information"}
        fW={path == "/profile/Pinfo" ? "bold" : ""}
        onClick={() => {
          navigate("/profile/Pinfo");
          close();
        }}
      />
      <CustomListProfile
        icon={<div />}
        title={"User Settings"}
        fW={path == "/profile/Uinfo" ? "bold" : ""}
        onClick={() => {
          navigate("/profile/Uinfo");
          close();
        }}
      />
      <CustomListProfile
        icon={<div />}
        title={"E-Invoice and EWay Bill Enabling"}
        fW={path == "/profile/InvoiceBillInfo" ? "bold" : ""}
        onClick={() => {
          navigate("/profile/InvoiceBillInfo");
          close();
        }}
      />
      <CustomListProfile
        icon={<div />}
        title={"Security Settings"}
        fW={path == "/profile/Settinginfo" ? "bold" : ""}
        onClick={() => {
          navigate("/profile/Settinginfo");
          close();
        }}
      />
      <CustomListProfile
        icon={<div />}
        title={"Personal Preferences"}
        fW={path == "/profile/PPinfo" ? "bold" : ""}
        // style={{
        //   borderBottom: " 1px solid #6e82a5",
        // }}
        onClick={() => {
          navigate("/profile/PPinfo");
          close();
        }}
      />
      <CustomListProfile
        icon={<div />}
        title={"Employee Master"}
        fW={path == "/profile/EMaster" ? "bold" : ""}
        style={{
          borderBottom: " 1px solid #6e82a5",
        }}
        onClick={() => {
          navigate("/profile/EMaster");
          close();
        }}
      />
    </div>
  );
};

export const CustomListProfile = ({
  icon,
  title,
  onClick,
  style,
  fd = "row",
  fW,
}) => {
  return (
    <div
      className="list-main-single-profile"
      style={{ ...style, flexDirection: fd ? fd : "row" }}
      onClick={onClick}
    >
      <div className="list-title-profile fs12" style={{ fontWeight: fW }}>
        {title}
      </div>
    </div>
  );
};

export const CardDataRowProfile = ({ title, value }) => {
  return (
    <div className={styles.p_data_row}>
      <div className={styles.p_row_title_profile}>{title}</div>
      <div>:</div>
      <div className={styles.p_row_value_profile}>{value}</div>
    </div>
  );
};
