import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  Button,
  EditButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import Table from "../../../components/table/Table";
import Input from "../../../components/inputs/Input";
import "../../../CSS/masterData.css";
import Dropdown from "../../../components/inputs/Dropdown";
import Modal from "../../../components/modal/Modal";
import { base_url, DeleteIcon, Radio } from "../../../utils/utils";
import SmallModal from "../../../components/modal/SmallModal";
import axios from "axios";
import {
  GST_data_api,
  custVendMasterAPI,
  cust_vend_master_api,
  distrct_data_api,
  excel_upload_download_api,
  pincode_verify_api,
} from "../../../utils/apis";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { EmailValidation, MobileValidation } from "../../../utils/validator";
import Loader from "../../../components/Loader";
import PageLoading from "../../../components/PageLoading";
import TableNew from "../../../components/table/TableWithPagination";
import { Pagination3 } from "../../../components/pagination/pagination";
import store from "../../../redux/store";
import { get_customer_vender_list } from "../masterdataSlice";
import RadioBtn from "../../../components/radio/RadioBtn";
import { CheckBox } from "../../../components/inputs/CheckBox";
import { Alerts } from "../../../components/Alerts/Alerts";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
import { downloadFile } from "../../../utils/Hook/DownloadFile";
import { UploadFile } from "../../../components/UploadFile";

export const CustVendMaster = () => {
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [ShowValue, setShowValue] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMess, setAlertMess] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [addData, setAddData] = useState([]);
  const [objData, setObjData] = useState({
    ctype: "",
    openbal: "0.0",
  });
  const [locationListArr, setLocationListArr] = useState([]);
  const [distList1, setDistList1] = useState([]);
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [SerchInput, setSearchInput] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [showEditUjj, setShowEditUjj] = useState(false);
  const [editItemUjj, setEditItemUjj] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [EditItem, setEditItem] = useState({});
  const [showEditShipping, setShowEditShipping] = useState(false);
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput?.trim(),
  };

  const [locationList, setLocationList] = useState([]);
  const [locationList2, setLocationList2] = useState([]);
  const navigate = useNavigate();
  const stateList = useSelector((state) => state.completeList.stateList);
  const { cust_vend_list, cust_vend_loading } = useSelector(
    (state) => state.masterdata
  );
  const { user_id, omc_name, today, emp_id, user_type } = useSelector(
    (state) => state.other
  );

  const fetchData = () => {
    store.dispatch(get_customer_vender_list(obj));
  };

  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  useEffect(() => {
    if (
      (objData.state1 || objData.state1 == "") &&
      (EditItem?.S_STATE || EditItem?.S_STATE != "")
    ) {
      handleDistrict1();
    }
  }, [objData.state1, EditItem?.S_STATE]);

  const handleDistrict1 = async () => {
    try {
      const body = new FormData();
      body.append("stateid", objData.state1 || EditItem?.S_STATE);
      const response = await distrct_data_api(body);
      if (response.success) {
        setDistList1(response.data.reponselist.districts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDistrict2 = async (i) => {
    try {
      const body = new FormData();
      body.append("stateid", addData[i].state2);
      const response = await distrct_data_api(body);
      if (response.success) {
        setAddData((prevData) => {
          const updatedData = [...prevData];
          updatedData[i] = {
            ...updatedData[i],
            distList2Arr: response.data.reponselist.districts,
          };
          return updatedData;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDistrict3 = async (i) => {
    try {
      const body = new FormData();
      body.append("stateid", EditItem?.shipping_addresses[i].S_STATE);
      const response = await distrct_data_api(body);
      if (response.success) {
        setEditItem((prevData) => ({
          ...prevData,
          shipping_addresses: prevData.shipping_addresses.map(
            (address, index) => {
              if (index === i) {
                return {
                  ...address,
                  distList2Arr: response.data.reponselist.districts,
                };
              }
              return address;
            }
          ),
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGSTData = async (e) => {
    let GSTInputValue = e.target.value.toUpperCase();
    const validInput = GSTInputValue.replace(/[^a-zA-Z0-9]/g, "");
    if (validInput.length < 15) {
      setObjData({ ...objData, gstin: validInput });
    } else if (validInput.length === 15) {
      setObjData({ ...objData, gstin: validInput });
      const body = new FormData();
      body.append("gstin", validInput);
      const response = await GST_data_api(body);

      if (response.error === false) {
        const filtData = stateList.filter(
          (item) => item.name === response.state.toUpperCase()
        )[0].code;
        setObjData((preObjData) => ({
          ...preObjData,
          pan: response.pan,
          cname: response.legalname,
          address1: response.address,
          state1: filtData,
          district1: response.district,
          pincode1: response.pincode,
        }));
      } else if (response.error === true) {
        setOpenAlert(true);
        setAlertMess(
          response.errmsg == null
            ? "Please enter valid GSTIN"
            : response?.errmsg
        );
      }
    } else {
      setObjData((preObjData) => ({
        ...preObjData,
        pan: "",
        cname: "",
        address1: "",
        state1: "",
        district1: "",
        pincode1: "",
      }));
    }
  };

  const handleGSTData2 = async (e) => {
    let GSTInputValue = e.target.value.toUpperCase();
    const validInput = GSTInputValue.replace(/[^a-zA-Z0-9]/g, "");
    if (validInput.length < 15) {
      setEditItem({ ...EditItem, CVO_TIN: validInput });
    } else if (validInput.length === 15) {
      setEditItem({ ...EditItem, CVO_TIN: validInput });
      const body = new FormData();
      body.append("gstin", validInput);
      const response = await GST_data_api(body);

      if (response.error === false) {
        const filtData = stateList.filter(
          (item) => item.name === response.state.toUpperCase()
        )[0].code;
        setEditItem((preEditItem) => ({
          ...preEditItem,
          CVO_PAN: response.pan,
          cvo_name: response.legalname,
          cvo_address: response.address,
          B_STATE_NAME: filtData,
          B_DISTRICT: response.district,
          B_PINCODE: response.pincode,
        }));
      } else if (response.error === true) {
        setOpenAlert(true);
        setAlertMess(
          response.errmsg == null
            ? "Please enter valid GSTIN"
            : response?.errmsg
        );
      }
    } else {
      setEditItem((preEditItem) => ({
        ...preEditItem,
        CVO_PAN: "",
        cvo_name: "",
        cvo_address: "",
        B_STATE_NAME: "",
        B_DISTRICT: "",
        B_PINCODE: "",
      }));
    }
  };

  const handleDeleteCustVend = async (deleteId) => {
    setShowLoader(true);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    body.append("id", deleteId);
    if (emp_id) {
      body.append("emp_code", emp_id);
    }
    const response = await axios.post(custVendMasterAPI, body);
    setShowLoader(false);
    if (response.data.success) {
      setShowDeleteAlert(false);
      toast(response.data.message, {
        position: "top-center",
      });
      if (cust_vend_list?.length < 2) {
        setPageNo(pageNo - 1);
        store.dispatch(
          get_customer_vender_list({
            pageNo: pageNo - 1,
            entries: entries,
            setTotalCount: setTotalCount,
          })
        );
      } else {
        store.dispatch(get_customer_vender_list(obj));
      }
    }
  };

  const valiDate = () => {
    const alertArr = [];
    if (objData?.ctype?.length === 0 || !objData?.ctype) {
      alertArr.push("Please Select PARTY TYPE.");
    }
    if (objData?.gstreg?.length === 0) {
      alertArr.push("Please Select GST REGISTERED OR NOT.");
    }
    if (objData?.gstreg === "1" ? objData?.gstin?.length !== 15 : false) {
      if (objData?.gstin?.length === 0) {
        alertArr.push("Please enter GSTIN");
      } else {
        alertArr.push("Please enter valid GSTIN");
      }
    }
    if (
      objData?.gstreg === "2"
        ? objData?.pan?.length != 10 && objData?.pan?.length > 0
        : false || /^[A-Z]{5}[0-9]{4}[A-Z]$/.test(objData?.pan) != true
    ) {
      if (objData?.pan?.length > 0 && objData?.pan?.length != 10) {
        alertArr.push("Please enter valid PAN");
      }
    }
    if (objData?.cname?.trim()?.length == 0) {
      alertArr.push("Please enter PARTY NAME");
    }
    if (objData?.type?.length == 0) {
      alertArr.push(
        "Is your annual turnover crossed 10 crores in any of last 3 years?"
      );
    }
    if (!objData?.DOM && !objData?.COM && !objData?.ARB) {
      alertArr.push("Please Select atleast One Product");
    }
    if (objData?.purchase?.length == 0) {
      alertArr.push("Please Select Estimated annual purchase");
    }
    if (objData?.address1?.trim()?.length == 0) {
      alertArr.push("Please enter party address");
    } else if (objData?.address1?.trim()?.length < 25) {
      alertArr.push("Address should accept min. Of 25 chars");
    } else if (objData?.address1?.trim()?.length > 200) {
      alertArr.push("Address should accept max. Of 200 chars");
    }
    if (objData?.location1?.trim()?.length == 0) {
      alertArr.push("Please Enter Location of Billing Address.");
    }
    if (objData?.pincode1?.length !== 6) {
      if (objData?.pincode1?.length == 0) {
        alertArr.push("Please Enter Pincode of Billing Address.");
      } else {
        alertArr.push("Pincode of Billing Address Must Be 6 Digit Number.");
      }
    }
    if (!EmailValidation.test(objData?.email)) {
      if (objData?.email?.length == 0) {
        alertArr.push("please enter valid email id");
      } else {
        alertArr.push(" Please enter valid data for EMAIL ID field.");
      }
    }
    if (objData?.contact?.length == 0) {
      alertArr.push("Please enter valid CONTACT NO.");
    } else if (
      !MobileValidation.test(objData?.contact) ||
      objData?.contact?.length != 10
    ) {
      alertArr.push("Please enter Valid CONTACT NO. field");
    }
    if (objData?.state1?.length === 0) {
      alertArr.push(
        "Pincode of Billing Address is not belongs to given State."
      );
    }
    if (objData?.district1?.length === 0) {
      alertArr.push("District is Required");
    }
    if (ShowValue == "no") {
      for (let v of addData) {
        if (v?.unit_name?.trim()?.length == 0) {
          alertArr.push("Please Enter Unit Name.");
        }
        if (v?.address2?.trim()?.length == 0) {
          alertArr.push("Please Enter Shipping Address.");
        } else if (v?.address2?.trim()?.length < 25) {
          alertArr.push("Address should accept min. Of 25 chars");
        } else if (v?.address2?.trim()?.length > 200) {
          alertArr.push("Address should accept max. Of 200 chars");
        }
        if (v?.state2?.length == 0) {
          alertArr.push("Please Select State of Shipping Address.");
        }
        if (v?.district2?.length == 0) {
          alertArr.push("Please Select District of Shipping Address.");
        }
        if (v?.location2?.length == 0) {
          alertArr.push("Please Enter Location of Shipping Address.");
        }
        if (v?.pincode2?.length != 6) {
          if (v?.pincode2?.length == 0) {
            alertArr.push("Please Enter Pincode of Shipping Address.");
          } else {
            alertArr.push(
              "Pincode of Shipping Address Must Be 6 Digit Number."
            );
          }
        }
      }
    }
    return alertArr;
  };

  const handleSubmit = async () => {
    const alertArr = valiDate();
    if (alertArr?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("ctype", objData?.ctype);
        body.append("gstreg", objData?.gstreg);
        body.append("gstin", objData?.gstin || "");
        body.append("pan", objData?.pan || "");
        body.append("cname", objData?.cname?.trim());
        body.append("openbal", objData?.openbal);
        body.append("closebal", objData?.closebal || objData?.openbal);
        body.append("address1", objData?.address1?.trim());
        body.append("state1", objData?.state1);
        body.append("district1", objData?.district1);
        body.append("location1", objData?.location1?.trim());
        body.append("pincode1", objData?.pincode1);
        body.append("dom", objData?.DOM ? "1" : "0" || "0");
        body.append("com", objData?.COM ? "1" : "0" || "0");
        body.append("arb", objData?.ARB ? "1" : "0" || "0");
        body.append("previous_fy_turnover", objData?.type);
        body.append("estimated_sales_value", objData?.purchase);
        body.append(
          "sametype",
          (ShowValue == "yes" && "1") || (ShowValue == "no" && "2")
        );
        body.append("email", objData?.email);
        body.append("contact", objData?.contact);
        if (ShowValue == "yes") {
          body.append("unit_name[]", objData?.cname?.trim());
          body.append("address2[]", objData?.address1?.trim());
          body.append("state2[]", objData?.state1);
          body.append("district2[]", objData?.district1);
          body.append("location2[]", objData?.location1?.trim());
          body.append("pincode2[]", objData?.pincode1);
        } else {
          addData.forEach((item) => {
            if (item) {
              body.append("unit_name[]", item?.unit_name?.trim());
              body.append("address2[]", item?.address2?.trim());
              body.append("state2[]", item?.state2);
              body.append("district2[]", item?.district2);
              body.append("location2[]", item?.location2?.trim());
              body.append("pincode2[]", item?.pincode2);
            }
          });
        }
        if (emp_id) {
          body.append("emp_code", emp_id);
        }
        const response = await cust_vend_master_api(body);
        setShowLoader(false);
        if (response.success) {
          store.dispatch(get_customer_vender_list(obj));
          toast(response.message);
          setOpen(false);
          setObjData({});
          setAddData([]);
        } else {
          setOpenAlert(true);
          setAlertMess(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpenAlert(true);
      setAlertMess(<Alerts data={alertArr} />);
    }
    setShowLoader(false);
  };

  const validation_check = () => {
    if (
      addData.find(
        (v) =>
          !v?.unit_name ||
          (v?.unit_name && v?.unit_name?.trim()?.length == 0) ||
          !v?.address2 ||
          (v?.address2 && v?.address2?.trim()?.length == 0) ||
          !v?.state2 ||
          (v?.state2 && v?.state2?.length == 0) ||
          !v?.district2 ||
          (v?.district2 && v?.district2?.length == 0) ||
          !v?.location2 ||
          (v?.location2 && v?.location2?.trim()?.length == 0) ||
          !v?.pincode2 ||
          (v?.pincode2 && v?.pincode2?.length == 0)
      )
    ) {
      return true;
    }
  };

  const validation_check2 = () => {
    if (
      EditItem?.shipping_addresses.some((v) => {
        return (
          !v?.UNIT_NAME ||
          (v?.UNIT_NAME && v?.UNIT_NAME?.trim()?.length == 0) ||
          !v?.S_ADDRESS ||
          (v?.S_ADDRESS && v?.S_ADDRESS?.trim()?.length == 0) ||
          !v?.S_STATE ||
          (v?.S_STATE && v?.S_STATE?.length == 0) ||
          !v?.S_DISTRICT ||
          (v?.S_DISTRICT && v?.S_DISTRICT?.length == 0) ||
          !v?.S_LOCATION ||
          (v?.S_LOCATION && v?.S_LOCATION?.trim()?.length == 0) ||
          !v?.S_PINCODE ||
          (v?.S_PINCODE && v?.S_PINCODE?.length == 0)
        );
      })
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddData = () => {
    setShowValue("no");
    if (addData.length === 0) {
      setAddData((prevData) => [
        ...prevData,
        {
          id: addData.length + 1,
          unit_name: "",
          address2: "",
          state2: "",
          district2: "",
          distList2Arr: [],
          location2: "",
          pincode2: "",
        },
      ]);
    } else {
      if (!validation_check()) {
        setAddData((prevData) => [
          ...prevData,
          {
            id: addData.length + 1,
            unit_name: "",
            address2: "",
            state2: "",
            distList2Arr: [],
            district2: "",
            location2: "",
            pincode2: "",
          },
        ]);
      } else if (ShowValue == "no") {
        setOpenAlert(true);
        setAlertMess(
          "Please enter all the values in current row,calculate and then add next row"
        );
      }
    }
  };
  const handleAddData2 = () => {
    if (EditItem?.shipping_addresses.length === 0) {
      setEditItem((prevData) => ({
        ...prevData,
        shipping_addresses: [
          {
            UNIT_NAME: "",
            S_ADDRESS: "",
            S_STATE: "",
            S_DISTRICT: "",
            S_LOCATION: "",
            S_PINCODE: "",
            distList2Arr: [],
          },
        ],
      }));
    } else {
      if (validation_check2()) {
        setOpenAlert(true);
        setAlertMess(
          "Please enter all the values in current row, calculate and then add next row"
        );
      } else {
        const newAddress = {
          UNIT_NAME: "",
          S_ADDRESS: "",
          S_STATE: "",
          S_DISTRICT: "",
          S_LOCATION: "",
          S_PINCODE: "",
          distList2Arr: [],
        };

        setEditItem((prevData) => ({
          ...prevData,
          shipping_addresses: [...prevData.shipping_addresses, newAddress],
        }));
      }
    }
  };

  const handleAddDelete = (deleteId) => {
    const filterData = addData.filter((item) => item.id !== deleteId);
    setAddData(filterData);
  };
  const handleAddDelete2 = async (id, s) => {
    if (id) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "delete_shipping");
        body.append("agencyid", user_id);
        body.append("id", id);
        body.append("cid", EditItem?.ID);
        const response = await cust_vend_master_api(body);
        setShowLoader(false);
        if (response.success) {
          const filterData = EditItem?.shipping_addresses?.filter(
            (item, t) => t !== s
          );
          setEditItem((prevData) => ({
            ...prevData,
            shipping_addresses: filterData,
          }));
          store.dispatch(get_customer_vender_list(obj));
        } else if (!response.success) {
          toast.error(response.message, {
            toastId: "1",
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setShowLoader(true);
      const filterData = EditItem?.shipping_addresses?.filter(
        (item, t) => t !== s
      );
      setEditItem((prevData) => ({
        ...prevData,
        shipping_addresses: filterData,
      }));
      setShowLoader(false);
    }
  };

  const update_account = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "ujjawalupdate");
      body.append("agencyid", user_id);
      body.append("id", editItemUjj?.ID);
      body.append("obal", editItemUjj?.OBAL);
      const response = await cust_vend_master_api(body);
      setShowLoader(false);
      if (response.success) {
        toast.success(response.message, {
          toastId: "1",
        });
        store.dispatch(get_customer_vender_list(obj));
        setShowEditUjj(false);
        setEditItemUjj({});
      } else if (!response.success) {
        toast.error(response.message, {
          toastId: "1",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  function capitalizeEachWord(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const valiDate2 = () => {
    const alertArr = [];

    if (
      EditItem?.IS_GST_REG === "1" ? EditItem?.CVO_TIN.length !== 15 : false
    ) {
      if (EditItem?.CVO_TIN.length === 0) {
        alertArr.push("Please enter GSTIN");
      } else {
        alertArr.push("Please enter valid GSTIN");
      }
    }
    if (
      EditItem?.IS_GST_REG === "2"
        ? EditItem?.CVO_PAN?.length != 10
        : false || /^[A-Z]{5}[0-9]{4}[A-Z]$/.test(EditItem?.CVO_PAN) != true
    ) {
      if (EditItem?.CVO_PAN?.length > 0 && EditItem?.CVO_PAN?.length != 10) {
        alertArr.push("Please enter valid PAN");
      }
      // (EditItem?.CVO_PAN?.length === 0) {
      //   alertArr.push("Please enter data for PAN field");
      // } else
    }
    if (EditItem?.cvo_name?.trim()?.length == 0) {
      alertArr.push("Please enter PARTY NAME");
    }
    if (EditItem?.ANNUAL_TURNOVER?.length == 0) {
      alertArr.push(
        "Is your annual turnover crossed 10 crores in any of last 3 years?"
      );
    }
    if (
      (!EditItem?.DOM || EditItem?.DOM == "0") &&
      (!EditItem?.COM || EditItem?.COM != "0") &&
      (!EditItem?.ARB || EditItem?.COM != "0")
    ) {
      alertArr.push("Please Select atleast One Product");
    }
    if (EditItem?.ANNUAL_PURCHASE?.length == 0) {
      alertArr.push("Please Select Estimated annual purchase");
    }
    if (EditItem?.cvo_address?.trim()?.length == 0) {
      alertArr.push("Please enter party address");
    } else if (EditItem?.cvo_address?.trim()?.length < 25) {
      alertArr.push("Address should accept min. Of 25 chars");
    } else if (EditItem?.cvo_address?.trim()?.length > 200) {
      alertArr.push("Address should accept max. Of 200 chars");
    }
    if (EditItem?.B_LOCATION?.trim()?.length == 0) {
      alertArr.push("Please Enter Location of Billing Address.");
    }
    if (EditItem?.B_PINCODE?.toString()?.length !== 6) {
      if (!EditItem?.B_PINCODE) {
        alertArr.push("Please Enter Pincode of Billing Address.");
      } else {
        alertArr.push("Pincode of Billing Address Must Be a 6-digit Number.");
      }
    }
    if (!EmailValidation.test(EditItem?.CVO_EMAIL)) {
      if (EditItem?.CVO_EMAIL?.length == 0) {
        alertArr.push("please enter valid email id");
      } else {
        alertArr.push(" Please enter valid data for EMAIL ID field.");
      }
    }
    if (EditItem?.CVO_CONTACT?.length == 0) {
      alertArr.push("Please enter valid CONTACT NO.");
    } else if (
      !MobileValidation.test(EditItem?.CVO_CONTACT) ||
      EditItem?.CVO_CONTACT?.length != 10
    ) {
      alertArr.push("Please enter Valid CONTACT NO. field");
    }
    if (showEditShipping == "no") {
      for (let v of EditItem?.shipping_addresses) {
        if (!v?.UNIT_NAME || v?.UNIT_NAME?.trim()?.length == 0) {
          alertArr.push("Please Enter Unit Name.");
        }
        if (!v?.S_ADDRESS || v?.S_ADDRESS?.trim()?.length == 0) {
          alertArr.push("Please Enter Shipping Address.");
        } else if (v?.S_ADDRESS?.trim()?.length < 25) {
          alertArr.push("Address should accept min. Of 25 chars");
        } else if (v?.S_ADDRESS?.trim()?.length > 200) {
          alertArr.push("Address should accept max. Of 200 chars");
        }
        if (!v?.S_STATE || v?.S_STATE?.length == 0) {
          alertArr.push("Please Select State of Shipping Address.");
        }
        if (!v?.S_DISTRICT || v?.S_DISTRICT?.length == 0) {
          alertArr.push("Please Select District of Shipping Address.");
        }
        if (!v?.S_LOCATION || v?.S_LOCATION?.length == 0) {
          alertArr.push("Please Enter Location of Shipping Address.");
        }
        if (v?.S_PINCODE?.toString()?.length !== 6) {
          if (!v?.S_PINCODE) {
            alertArr.push("Please Enter Pincode of Shipping Address.");
          } else {
            alertArr.push(
              "Pincode of Shipping Address Must Be 6 Digit Number."
            );
          }
        }
      }
    }
    return alertArr;
  };
  const update = async () => {
    const data = valiDate2();
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "update");
        body.append("agencyid", user_id);
        body.append("id", EditItem?.ID);
        body.append("address1", EditItem?.cvo_address?.trim());
        body.append("gst", EditItem?.IS_GST_REG);
        body.append("location1", EditItem?.B_LOCATION?.trim());
        body.append("pincode1", EditItem?.B_PINCODE);
        body.append("previous_fy_turnover", EditItem?.PREVIOUS_FY_TURNOVER);
        body.append("dom", EditItem?.DOM);
        body.append("com", EditItem?.COM);
        body.append("arb", EditItem?.ARB);
        body.append("estimated_sales_value", EditItem?.ESTIMATED_SALE_VALUE);
        body.append(
          "sametype",
          (showEditShipping == "yes" && "1") ||
            (showEditShipping == "no" && "2")
        );
        body.append("email", EditItem?.CVO_EMAIL);
        body.append("contact", EditItem?.CVO_CONTACT);
        body.append("CVO_TIN", EditItem?.CVO_TIN || "");
        body.append("CVO_PAN", EditItem?.CVO_PAN || "");
        EditItem?.shipping_addresses?.forEach((item) => {
          if (item) {
            body.append("unit_name[]", item?.UNIT_NAME?.trim());
            body.append("address2[]", item?.S_ADDRESS?.trim());
            body.append("state2[]", item?.S_STATE);
            body.append("district2[]", item?.S_DISTRICT);
            body.append("location2[]", item?.S_LOCATION?.trim());
            body.append("pincode2[]", item?.S_PINCODE);
            body.append("sid[]", item?.ID || "");
          }
        });
        if (emp_id) {
          body.append("emp_code", emp_id);
        }
        const response = await cust_vend_master_api(body);
        setShowLoader(false);
        if (response?.success) {
          store.dispatch(get_customer_vender_list(obj));
          toast.success(response?.message);
          setShowEdit(false);
          setEditItem({});
        } else {
          setOpenAlert(true);
          setAlertMess(response?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpenAlert(true);
      setAlertMess(<Alerts data={data} />);
    }
  };
  const get_location = async (data, stateData) => {
    try {
      const data1 = await data_location(data, stateData);
      setLocationList(data1);
    } catch (error) {
      console.error("Error in get_location:", error);
    }
  };

  const get_location2 = async (data, stateData, index) => {
    try {
      const data1 = await data_location(data, stateData);
      setAddData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          locationList: data1,
        };
        return updatedData;
      });
    } catch (error) {
      console.error("Error in get_location:", error);
    }
  };

  const get_location3 = async (data, stateData) => {
    try {
      const data1 = await data_location(data, stateData);
      setLocationList2(data1);
    } catch (error) {
      console.error("Error in get_location:", error);
    }
  };

  const get_location4 = async (data, stateData, index) => {
    try {
      const data1 = await data_location(data, stateData);

      return data1;
    } catch (error) {
      console.error("Error in get_location:", error);
    }
  };

  const data_location = async (data, stateData) => {
    const data1 = String(data);
    const stateData1 = String(stateData);
    if (stateData1 && stateData1?.length > 0 && data1 && data1?.length === 6) {
      try {
        const body = new FormData();
        body.append("pincode", data1);
        body.append("state", stateData1);

        const response = await pincode_verify_api(body);
console.log(response);

        if (response.success) {
          return response.locations;
        } else {
          setAlertMess(response.message);
          setOpenAlert(true);
          throw new Error(response.message);
        }
      } catch (error) {
        console.error("Error in data_location:", error);
        throw error;
      }
    } else {
      throw new Error("Invalid data or stateData provided.");
    }
  };

  const download_excel = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("agencyid", user_id);
      body.append("action", "downloadCvo");
      const res = await excel_upload_download_api(body);

      setShowLoader(false);
      if (res.success) {
        await downloadFile(base_url + res?.file);
      } else if (!res.success) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const upload_excel = async (file) => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("agencyid", user_id);
      body.append("action", "uploadCvoData");
      body.append("excelFile", file);
      if (emp_id) {
        body.append("emp_code", emp_id);
      }
      const res = await excel_upload_download_api(body);

      setShowLoader(false);
      if (res.success) {
        setOpenUpload(false);
        toast.success(res.message);
        fetchData();
      } else if (!res.success) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"CUSTOMER / VENDOR MASTER"}
        right={
          <>
            <AddButton
              onClick={() => {
                handleAddData();
                setOpen(true);
                setShowValue("yes");
              }}
            />
            <Button title={"DOWNLOAD EXCEL"} onClick={download_excel} />
            <Button
              onClick={() => setOpenUpload(true)}
              title={"UPLOAD EXCEL"}
            />

            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/YZgX2vLx13Y",
                "https://youtu.be/rkT3dSGf6bc",
              ]}
            />
          </>
        }
      />

      {/* List Render */}
      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {cust_vend_loading ? (
          <PageLoading />
        ) : (
          <TableNew
            mt={20}
            nodata={
              cust_vend_list.length != 0 && !cust_vend_loading ? false : true
            }
            headData={[
              "PARTY/PLANT NAME",
              "PARTY TYPE",
              "GST REG",
              "GSTIN",
              "PAN",
              "ADDRESS",
              "MOBILE NO",
              "EMAIL",
              "OPENING BALANCE",
              "CLOSING BALANCE",
              "CREATED BY",
              "ACTIONS",
            ]}
            body={cust_vend_list.map((item, i) => (
              <tr key={i}>
                <td
                  style={{
                    cursor: item?.cvo_name != "UJWALA" ? "pointer" : "",
                  }}
                  onClick={async () => {
                    if (item?.cvo_name != "UJWALA") {
                      setShowEdit(true);
                      setEditItem(item);
                      get_location3(item?.B_PINCODE, item?.B_STATE);
                      if (item?.shipping_addresses?.length == 0) {
                        setShowEditShipping("yes");
                      } else if (item?.shipping_addresses?.length > 0) {
                        setShowEditShipping("no");
                        const arr = [];
                        for (
                          var i = 0;
                          i < item?.shipping_addresses?.length;
                          i++
                        ) {
                          const data = await get_location4(
                            item?.shipping_addresses[i]?.S_PINCODE,
                            item?.shipping_addresses[i]?.S_STATE,
                            i
                          );
                          arr.push(data);
                        }
                        setLocationListArr(arr);
                      }
                    }
                  }}
                >
                  {item?.cvo_name}
                </td>
                <td>
                  {item?.CVO_CAT == "0"
                    ? "VENDOR"
                    : item?.CVO_CAT == "1"
                    ? "CUSTOMER"
                    : item?.CVO_CAT == "2"
                    ? `OMC-${omc_name}`
                    : "OTHERS"}
                </td>
                <td>{item?.IS_GST_REG == "1" ? "YES" : "NO"}</td>
                <td>{item?.IS_GST_REG == "1" ? item?.CVO_TIN : "NA"}</td>
                <td>{item?.CVO_PAN}</td>
                <td>
                  {item?.cvo_name == "UJWALA"
                    ? "NA"
                    : item?.cvo_address
                    ? item?.cvo_address
                    : `${item?.B_LOCATION},${item?.B_DISTRICT},${item?.B_STATE_NAME},
                ${item?.B_PINCODE}`}
                </td>
                <td>{item?.CVO_CONTACT}</td>
                <td>{item?.CVO_EMAIL}</td>
                <td>{Number(item?.OBAL).toFixed(2)}</td>
                <td>{Number(item?.CBAL).toFixed(2)}</td>
                <td>{item?.CREATED_NAME || "-"}</td>
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item?.cvo_name == "UJWALA" ? (
                    "-"
                  ) : item?.OBAL == "0.00" || item?.OBAL == "0" ? (
                    <EditButton
                      onClick={() => {
                        setEditItemUjj(item);
                        setShowEditUjj(true);
                      }}
                    />
                  ) : item?.cvo_name == "UJWALA" ? (
                    "-"
                  ) : (
                    <>
                      {user_type == "admin" || item?.EMPLOYEE_CODE == emp_id ? (
                        <DeleteIcon
                          onClick={() => {
                            setDeleteId(item?.ID);
                            setShowDeleteAlert(true);
                          }}
                        />
                      ) : (
                        "-"
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
          />
        )}
      </Pagination3>

      {/* Add Cust Vend */}
      {open && (
        <Modal
          onCancel={() => {
            setObjData({});
            setAddData([]);
          }}
          isOpen={open}
          setIsOpen={setOpen}
          title={"CUSTOMER / VENDOR MASTER"}
          body={
            <div>
              <div className="credit-limit">
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    label="PARTY TYPE"
                    value={objData?.ctype}
                    onChange={(e) => {
                      {
                        const input = e.target.value;
                        const data = input == "2" ? true : false;
                        setObjData({
                          ...objData,
                          ctype: e.target.value,
                          type: data ? "1" : "",
                          purchase: data ? "1" : "",
                          gstreg: data ? "1" : "",
                          dis: data,
                        });
                      }
                    }}
                    options={
                      <>
                        <option
                          disabled={objData?.ctype?.length > 0}
                          value={""}
                        >
                          SELECT PARTY TYPE
                        </option>
                        <option value={"2"}>OMC-{omc_name}</option>
                        <option value={"0"}>VENDOR</option>
                        <option value={"1"}>CUSTOMER</option>
                        <option value={"3"}>OTHERS</option>
                      </>
                    }
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    label="GST REG"
                    value={objData?.gstreg}
                    disabled={objData?.dis}
                    onChange={(e) => {
                      setObjData({ ...objData, gstreg: e.target.value });
                    }}
                    options={
                      <>
                        <option hidden value={"0"}>
                          SELECT
                        </option>
                        <option value={"1"}>YES</option>
                        <option value={"2"}>NO</option>
                      </>
                    }
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="GST"
                    placeholder={"GSTIN"}
                    value={objData?.gstreg == "2" ? "NA" : objData?.gstin}
                    onChange={handleGSTData}
                    disabled={objData?.gstreg == "2" ? true : false}
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="PAN"
                    placeholder={"PAN"}
                    value={objData?.pan}
                    onChange={(e) => {
                      if (e.target.value?.length <= 10) {
                        const panValue = e.target.value.toUpperCase();
                        const validInput = panValue.replace(/[^A-Z0-9]/g, "");
                        setObjData({
                          ...objData,
                          pan: validInput,
                        });
                      }
                    }}
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="PARTY/PLANT NAME"
                    placeholder={"Party Name"}
                    value={objData?.cname}
                    onChange={(e) => {
                      let inputData = e.target.value;
                      // const validInput = inputData.replace(/[^a-zA-Z ]/g, "");
                      const validInput = inputData
                        .replace(/[^a-zA-Z0-9!@#$%^&*(),?":{}|<> ]/g, "") // Remove non-alphanumeric and special characters
                        .replace(/\s+/g, " ");
                      if (validInput?.length <= 35) {
                        setObjData({ ...objData, cname: validInput });
                      }
                    }}
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="OPENING BALANCE"
                    placeholder={"0.0"}
                    value={objData?.openbal}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (
                        (input?.length < 15 &&
                          /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/.test(input)) ||
                        input == 0 ||
                        !objData?.openbal
                      ) {
                        setObjData({
                          ...objData,
                          openbal: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/.test(input)
                            ? input
                            : "",
                          closebal: /^(0|[1-9]\d{0,9})(\.\d{0,2})?$/.test(input)
                            ? input
                            : "",
                        });
                      }
                    }}
                  />
                  {/* // if (/^-?\d*\.?\d*$/.test(input) || input === "") {
                      //   setObjData({
                      //     ...objData,
                      //     openbal: e.target.value,
                      //     closebal: e.target.value,
                      //   });
                      // } */}
                </div>
                <div
                  className="flex aic gap10"
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <div
                    className="flex aic gap10"
                    style={{
                      width: "600px",
                      flexWrap: "wrap",
                      rowGap: "0",
                      margin: "1rem 0",
                    }}
                  >
                    <RadioBtn
                      label={
                        "Is your annual turnover crossed 10 crores in any of last 3 years?"
                      }
                    />
                    <Radio
                      title={"YES"}
                      disabled={objData?.dis}
                      show={objData?.type == "1" ? true : false}
                      setShow={() =>
                        setObjData({
                          ...objData,
                          type: "1",
                        })
                      }
                    />
                    <Radio
                      title={"NO"}
                      disabled={objData?.dis}
                      show={objData?.type == "0" ? true : false}
                      setShow={() =>
                        setObjData({
                          ...objData,
                          type: "0",
                        })
                      }
                    />
                  </div>
                  <div
                    className="flex aic gap10"
                    style={{
                      width: "600px",
                      flexWrap: "wrap",
                      rowGap: "0",
                      margin: "1rem 0",
                    }}
                  >
                    <RadioBtn label={"Preferred Purchase/Sales"} />
                    <CheckBox
                      lable={"Domestic CYL"}
                      Checked={objData?.DOM}
                      value={objData?.DOM}
                      onChange={() =>
                        setObjData({
                          ...objData,
                          DOM: !objData?.DOM,
                        })
                      }
                    />
                    <CheckBox
                      lable={"Comercial CYL."}
                      Checked={objData?.COM}
                      value={objData?.COM}
                      onChange={() =>
                        setObjData({
                          ...objData,
                          COM: !objData?.COM,
                        })
                      }
                    />
                    <CheckBox
                      lable={"ARB"}
                      Checked={objData?.ARB}
                      value={objData?.ARB}
                      onChange={() =>
                        setObjData({
                          ...objData,
                          ARB: !objData?.ARB,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="flex aic gap10" style={{ width: "100%" }}>
                  <div
                    className="flex aic gap10"
                    style={{
                      flexWrap: "wrap",
                      rowGap: "0",
                    }}
                  >
                    <RadioBtn label={"Estimated annual purchase?"} />

                    <Radio
                      title={"< 50 Lakhs"}
                      show={objData?.purchase == "0" ? true : false}
                      setShow={() =>
                        setObjData({
                          ...objData,
                          purchase: "0",
                        })
                      }
                    />
                    <Radio
                      title={"> 50 Lakhs"}
                      show={objData?.purchase == "1" ? true : false}
                      setShow={() =>
                        setObjData({
                          ...objData,
                          purchase: "1",
                        })
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    padding: " .5rem .5rem",
                    fontWeight: "bold",
                  }}
                >
                  BILLING ADDRESS
                </div>

                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="ADDRESS"
                    placeholder={"ADDRESS"}
                    value={objData?.address1}
                    onChange={(e) => {
                      setObjData({ ...objData, address1: e.target.value });
                    }}
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    label="STATE"
                    value={objData?.state1}
                    onChange={(e) => {
                      setObjData({
                        ...objData,
                        state1: e.target.value,
                        district1: "",
                        location1: "",
                        pincode1: "",
                      });
                    }}
                    options={
                      <>
                        <option>
                          {objData?.state1?.length === 0 &&
                          stateList?.length > 0
                            ? "SELECT"
                            : stateList.filter(
                                (item) => item.code === objData?.state1
                              )[0]?.name || "SELECT"}
                        </option>
                        {stateList.length > 0 &&
                          stateList.map((item, i) => (
                            <option key={i} value={item?.code}>
                              {item.name}
                            </option>
                          ))}
                      </>
                    }
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    value={objData?.district1}
                    onChange={(e) => {
                      setObjData({ ...objData, district1: e.target.value });
                    }}
                    label="DISTRICT"
                    options={
                      <>
                        <option>
                          {objData?.district1?.length === 0 ||
                          !objData?.district1
                            ? "SELECT"
                            : "SELECT"}
                        </option>
                        {distList1?.length > 0 &&
                          distList1?.map((item, i) => (
                            <option key={i} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                      </>
                    }
                  />
                </div>

                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="PINCODE"
                    placeholder={"PINCODE"}
                    value={objData?.pincode1}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (
                        (/^\d+$/.test(input) || input === "") &&
                        input.length <= 6
                      ) {
                        setObjData({
                          ...objData,
                          pincode1: input,
                          location1: "",
                        });
                      }
                      if (input?.length == 6) {
                        get_location(e.target.value, objData?.state1);
                      }
                    }}
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  {/* <Input
                    label="LOCATION"
                    placeholder={"LOCATION"}
                    value={objData?.location1}
                    onChange={(e) => {
                      setObjData({ ...objData, location1: e.target.value });
                    }}
                  /> */}
                  <Dropdown
                    onChange={(e) => {
                      setObjData({ ...objData, location1: e.target.value });
                    }}
                    label="LOCATION"
                    value={objData?.location1}
                    options={
                      <>
                        <option>
                          {objData?.location1?.length === 0 &&
                          locationList?.length > 0
                            ? "SELECT"
                            : "SELECT"}
                        </option>
                        {locationList?.length > 0 &&
                          locationList?.map((v, i) => (
                            <option key={i} value={v}>
                              {v}
                            </option>
                          ))}
                      </>
                    }
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="EMAIL"
                    placeholder={"EMAIL"}
                    value={objData?.email}
                    onChange={(e) => {
                      setObjData({ ...objData, email: e.target.value?.trim() });
                    }}
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="MOBILE NO."
                    placeholder={"CONTACT NO"}
                    value={objData?.contact}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (
                        (/^\d+$/.test(input) || input === "") &&
                        input.length <= 10
                      ) {
                        setObjData({ ...objData, contact: e.target.value });
                      }
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "11px",
                  marginTop: "10px",
                  paddingLeft: "8px",
                }}
              >
                <h4>IS SHIPPING ADDRESS SAME AS BILLING ADDRESS </h4>
                <Radio
                  title={"YES"}
                  show={ShowValue == "yes" ? true : false}
                  setShow={() => {
                    setShowValue("yes");
                    setAddData([]);
                  }}
                />
                <Radio
                  title={"NO"}
                  show={ShowValue == "no" ? true : false}
                  setShow={() => {
                    setShowValue("no");
                  }}
                />
              </div>
              {ShowValue == "no" && addData.length > 0 && (
                <Table
                  headData={[
                    "UNIT NAME",
                    "ADDRESS",
                    "STATE",
                    "DISTRICT",
                    "PINCODE",
                    "LOCATION",
                    "ACTIONS",
                  ]}
                  body={addData.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "250px", padding: " .15rem .5rem" }}>
                        <Input
                          placeholder={"UNIT NAME"}
                          value={item.unit_name}
                          onChange={(e) => {
                            // let inputData = e.target.value;
                            // const validInput = inputData.replace(
                            //   /[^a-zA-Z ]/g,
                            //   ""
                            // );
                            let validInput = e.target.value;
                            setAddData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                unit_name: validInput,
                              };
                              return updatedData;
                            });
                          }}
                        />
                      </td>
                      <td style={{ width: "250px", padding: " .15rem .5rem" }}>
                        <Input
                          placeholder={"ADDRESS"}
                          value={item.address2}
                          onChange={(e) => {
                            setAddData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                address2: e.target.value,
                              };
                              return updatedData;
                            });
                          }}
                        />
                      </td>
                      <td style={{ width: "250px", padding: " .15rem .5rem" }}>
                        <Dropdown
                          options={
                            <>
                              <option>
                                {item.state2.length === 0 &&
                                stateList.length > 0
                                  ? "SELECT"
                                  : stateList.filter(
                                      (item) => item.code === item.state2
                                    )[0]?.name || "SELECT"}
                              </option>
                              {stateList.length > 0 &&
                                stateList.map((item, i) => (
                                  <option key={i} value={item.code}>
                                    {item.name}
                                  </option>
                                ))}
                            </>
                          }
                          onBlur={() => handleDistrict2(index)}
                          value={item.state2}
                          onChange={(e) => {
                            setAddData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                state2: e.target.value,
                                district2: "",
                                pincode2: "",
                                location2: "",
                              };
                              return updatedData;
                            });
                          }}
                        />
                      </td>
                      <td style={{ width: "250px", padding: " .15rem .5rem" }}>
                        <Dropdown
                          value={item.district2}
                          onChange={(e) => {
                            setAddData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                district2: e.target.value,
                                pincode2: "",
                                location2: "",
                              };
                              return updatedData;
                            });
                          }}
                          options={
                            <>
                              <option>
                                {item.district2.length === 0
                                  ? "SELECT"
                                  : item?.district2}
                              </option>
                              {addData[index]?.distList2Arr.length > 0 &&
                                addData[index]?.distList2Arr.map((item, i) => (
                                  <option
                                    key={i}
                                    value={item.name.toUpperCase()}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                            </>
                          }
                        />
                      </td>

                      <td style={{ width: "250px", padding: " .15rem .5rem" }}>
                        <Input
                          placeholder={"PINCODE"}
                          value={item.pincode2}
                          onChange={(e) => {
                            const input = e.target.value;
                            if (input.length <= 6) {
                              let inputData = input;
                              const validInput = inputData.replace(
                                /[^0-9]/g,
                                ""
                              );
                              setAddData((prevData) => {
                                const updatedData = [...prevData];
                                updatedData[index] = {
                                  ...updatedData[index],
                                  pincode2: validInput,
                                  location2: "",
                                };
                                return updatedData;
                              });
                            }
                            if (input?.length == 6) {
                              get_location2(
                                input,
                                addData[index]?.state2,
                                index
                              );
                            }
                          }}
                        />
                      </td>
                      <td style={{ width: "250px", padding: " .15rem .5rem" }}>
                        <Dropdown
                          onChange={(e) => {
                            setAddData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                location2: e.target.value,
                              };
                              return updatedData;
                            });
                          }}
                          value={item.location2}
                          options={
                            <>
                              <option>
                                {item?.location2?.length === 0 &&
                                addData[index]?.locationList?.length > 0
                                  ? "SELECT"
                                  : "SELECT"}
                              </option>
                              {addData[index]?.locationList?.length > 0 &&
                                addData[index]?.locationList?.map((v, i) => (
                                  <option key={i} value={v}>
                                    {v}
                                  </option>
                                ))}
                            </>
                          }
                        />
                      </td>
                      <td>
                        <DeleteIcon onClick={() => handleAddDelete(item.id)} />
                      </td>
                    </tr>
                  ))}
                />
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontSize: "11px",
                  marginTop: "1rem",
                  gap: ".5rem",
                }}
              >
                <AddButton onClick={handleAddData} back />
                <SaveButton onClick={handleSubmit} />
              </div>
            </div>
          }
        />
      )}

      {/* Edit Cust Vend */}
      {showEdit && (
        <Modal
          onCancel={() => {
            setEditItem({});
            setLocationListArr([]);
          }}
          isOpen={showEdit}
          setIsOpen={setShowEdit}
          title={"CUSTOMER / VENDOR MASTER"}
          body={
            <div>
              <div
                className="credit-limit"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    disabled={true}
                    label="PARTY TYPE"
                    value={EditItem?.CVO_CAT}
                    options={
                      <>
                        <option value={"2"}>OMC-{omc_name}</option>
                        <option value={"0"}>VENDOR</option>
                        <option value={"1"}>CUSTOMER</option>
                        <option value={"3"}>OTHERS</option>
                      </>
                    }
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="PARTY/PLANT NAME"
                    placeholder={"Party Name"}
                    disabled={true}
                    value={EditItem?.cvo_name}
                    onChange={(e) => {
                      let inputData = e.target.value;
                      const validInput = inputData.replace(/[^a-zA-Z ]/g, "");
                      if (validInput?.length <= 35) {
                        setEditItem({ ...EditItem, cvo_name: validInput });
                      }
                    }}
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    label="GST REG"
                    value={EditItem?.IS_GST_REG}
                    disabled={
                      cust_vend_list?.filter((v) => v?.ID == EditItem?.ID)[0]
                        ?.IS_GST_REG == 1
                    }
                    onChange={(e) => {
                      setEditItem({ ...EditItem, IS_GST_REG: e.target.value });
                    }}
                    options={
                      <>
                        <option hidden value={"0"}>
                          SELECT
                        </option>
                        <option value={"1"}>YES</option>
                        <option value={"2"}>NO</option>
                      </>
                    }
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="GST"
                    placeholder={"GSTIN"}
                    value={
                      EditItem?.IS_GST_REG == "2" ? "NA" : EditItem?.CVO_TIN
                    }
                    disabled={
                      cust_vend_list?.filter((v) => v?.ID == EditItem?.ID)[0]
                        ?.IS_GST_REG == 1
                    }
                    onChange={handleGSTData2}
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="PAN"
                    placeholder={"PAN"}
                    value={EditItem?.CVO_PAN}
                    disabled={
                      cust_vend_list?.filter((v) => v?.ID == EditItem?.ID)[0]
                        ?.IS_GST_REG == 1
                    }
                    onChange={(e) => {
                      setEditItem({ ...EditItem, CVO_PAN: e.target.value });
                    }}
                  />
                </div>
                <div
                  className="flex aic gap10"
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <div
                    className="flex aic gap10"
                    style={{
                      flexWrap: "wrap",

                      rowGap: "0",
                    }}
                  >
                    <RadioBtn
                      label={
                        "Is your annual turnover crossed 10 crores in any of last 3 years?"
                      }
                    />
                    <Radio
                      title={"YES"}
                      disabled={EditItem?.CVO_CAT == "2" ? true : false}
                      show={
                        EditItem?.PREVIOUS_FY_TURNOVER == "1" ? true : false
                      }
                      setShow={() =>
                        setEditItem({
                          ...EditItem,
                          PREVIOUS_FY_TURNOVER: "1",
                        })
                      }
                    />
                    <Radio
                      title={"NO"}
                      disabled={EditItem?.CVO_CAT == "2" ? true : false}
                      show={
                        EditItem?.PREVIOUS_FY_TURNOVER == "0" ? true : false
                      }
                      setShow={() =>
                        setEditItem({
                          ...EditItem,
                          PREVIOUS_FY_TURNOVER: "0",
                        })
                      }
                    />
                  </div>
                  <div
                    className="flex aic gap10"
                    style={{
                      width: "600px",
                      flexWrap: "wrap",
                      rowGap: "0",
                      margin: "1rem 0",
                    }}
                  >
                    <RadioBtn label={"Preferred Purchase/Sales"} />
                    <CheckBox
                      lable={"Domestic CYL"}
                      Checked={EditItem?.DOM == "1" ? true : false}
                      value={EditItem?.DOM == "1" ? true : false}
                      onChange={() =>
                        setEditItem({
                          ...EditItem,
                          DOM: EditItem?.DOM == "1" ? "0" : "1",
                        })
                      }
                    />
                    <CheckBox
                      lable={"Comercial CYL."}
                      Checked={EditItem?.COM == "1" ? true : false}
                      value={EditItem?.COM == "1" ? true : false}
                      onChange={() =>
                        setEditItem({
                          ...EditItem,
                          COM: EditItem?.COM == "1" ? "0" : "1",
                        })
                      }
                    />
                    <CheckBox
                      lable={"ARB"}
                      Checked={EditItem?.ARB == "1" ? true : false}
                      value={EditItem?.ARB == "1" ? true : false}
                      onChange={() =>
                        setEditItem({
                          ...EditItem,
                          ARB: EditItem?.ARB == "1" ? "0" : "1",
                        })
                      }
                    />
                  </div>
                </div>

                <div className="flex aic gap10" style={{ width: "100%" }}>
                  <div
                    className="flex aic gap10"
                    style={{
                      flexWrap: "wrap",
                      rowGap: "0",
                    }}
                  >
                    <RadioBtn label={"Estimated annual purchase?"} />

                    <Radio
                      title={"< 50 Lakhs"}
                      show={
                        EditItem?.ESTIMATED_SALE_VALUE == "0" ? true : false
                      }
                      setShow={() =>
                        setEditItem({
                          ...EditItem,
                          ESTIMATED_SALE_VALUE: "0",
                        })
                      }
                    />
                    <Radio
                      title={"> 50 Lakhs"}
                      show={
                        EditItem?.ESTIMATED_SALE_VALUE == "1" ? true : false
                      }
                      setShow={() =>
                        setEditItem({
                          ...EditItem,
                          ESTIMATED_SALE_VALUE: "1",
                        })
                      }
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    padding: " .5rem .5rem",
                    fontWeight: "bold",
                  }}
                >
                  BILLING ADDRESS
                </div>

                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="ADDRESS"
                    placeholder={"ADDRESS"}
                    value={EditItem?.cvo_address}
                    onChange={(e) => {
                      setEditItem({ ...EditItem, cvo_address: e.target.value });
                    }}
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    label="STATE"
                    value={EditItem?.B_STATE_NAME?.toUpperCase()}
                    disabled={true}
                    options={
                      <>
                        <option>{EditItem?.B_STATE_NAME?.toUpperCase()}</option>
                      </>
                    }
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    disabled={true}
                    value={EditItem?.B_DISTRICT?.toUpperCase()}
                    label="DISTRICT"
                    options={
                      <>
                        <option>{EditItem?.B_DISTRICT?.toUpperCase()}</option>
                      </>
                    }
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="PINCODE"
                    placeholder={"PINCODE"}
                    value={EditItem?.B_PINCODE}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (
                        (/^\d+$/.test(input) || input === "") &&
                        input.length <= 6
                      ) {
                        setEditItem({
                          ...EditItem,
                          B_PINCODE: input,
                          B_LOCATION: "",
                        });
                      }

                      if (e.target.value?.length == 6) {
                        get_location3(e.target.value, EditItem?.B_STATE);
                      }
                    }}
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Dropdown
                    onChange={(e) => {
                      setEditItem({ ...EditItem, B_LOCATION: e.target.value });
                    }}
                    label="LOCATION"
                    value={EditItem?.B_LOCATION}
                    options={
                      <>
                        <option>
                          {EditItem?.B_LOCATION?.length === 0 &&
                          locationList2?.length > 0
                            ? "SELECT"
                            : "SELECT"}
                        </option>
                        {locationList2?.length > 0 &&
                          locationList2?.map((v, i) => (
                            <option key={i} value={v}>
                              {v}
                            </option>
                          ))}
                      </>
                    }
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="EMAIL"
                    placeholder={"EMAIL"}
                    value={EditItem?.CVO_EMAIL}
                    onChange={(e) => {
                      setEditItem({
                        ...EditItem,
                        CVO_EMAIL: e.target.value?.trim(),
                      });
                    }}
                  />
                </div>
                <div style={{ width: "200px", padding: " .15rem .5rem" }}>
                  <Input
                    label="MOBILE NO."
                    placeholder={"CONTACT NO"}
                    value={EditItem.CVO_CONTACT}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (
                        (/^\d+$/.test(input) || input === "") &&
                        input.length <= 10
                      ) {
                        setEditItem({
                          ...EditItem,
                          CVO_CONTACT: e.target.value,
                        });
                      }
                    }}
                    onBlur={() => {
                      if (EditItem.CVO_CONTACT.length != 10) {
                        setOpenAlert(true);
                        setAlertMess("Enter Valid Mobile No.");
                      }
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "11px",
                  marginTop: "10px",
                  paddingLeft: "8px",
                }}
              >
                <h4>IS SHIPPING ADDRESS SAME AS BILLING ADDRESS </h4>
                <Radio
                  title={"YES"}
                  show={showEditShipping == "yes" ? true : false}
                  disabled={EditItem?.shipping_addresses?.length > 0}
                  setShow={() => {
                    setShowEditShipping("yes");
                    setEditItem({ ...EditItem, shipping_addresses: [] });
                  }}
                />
                <Radio
                  title={"NO"}
                  show={showEditShipping == "no" ? true : false}
                  setShow={() => {
                    setShowEditShipping("no");
                    setEditItem({ ...EditItem, shipping_addresses: [{}] });
                  }}
                />
              </div>
              {showEditShipping == "no" &&
                EditItem?.shipping_addresses?.length > 0 && (
                  <Table
                    headData={[
                      "UNIT/PLANT NAME",
                      "ADDRESS",
                      "STATE",
                      "DISTRICT",
                      "PINCODE",
                      "LOCATION",
                      "ACTIONS",
                    ]}
                    body={EditItem?.shipping_addresses?.map((it, s) => (
                      <tr key={s}>
                        <td style={{ width: "20%", padding: " .15rem .5rem" }}>
                          <Input
                            placeholder={"UNIT/PLANT NAME"}
                            value={it?.UNIT_NAME}
                            onChange={(e) => {
                              const { value } = e.target;
                              const updatedAddresses = [
                                ...EditItem.shipping_addresses,
                              ];
                              updatedAddresses[s] = {
                                ...updatedAddresses[s],
                                UNIT_NAME: value,
                              };
                              setEditItem({
                                ...EditItem,
                                shipping_addresses: updatedAddresses,
                              });
                            }}
                          />
                        </td>

                        <td style={{ width: "20%", padding: " .15rem .5rem" }}>
                          <Input
                            placeholder={"ADDRESS"}
                            value={it?.S_ADDRESS}
                            onChange={(e) => {
                              const { value } = e.target;
                              const updatedAddresses = [
                                ...EditItem.shipping_addresses,
                              ];
                              updatedAddresses[s] = {
                                ...updatedAddresses[s],
                                S_ADDRESS: value,
                              };
                              setEditItem({
                                ...EditItem,
                                shipping_addresses: updatedAddresses,
                              });
                            }}
                          />
                        </td>

                        <td style={{ width: "20%", padding: " .15rem .5rem" }}>
                          <Dropdown
                            options={
                              <>
                                <option>
                                  {it?.S_STATE?.length === 0 &&
                                  stateList.length > 0
                                    ? "SELECT"
                                    : stateList.filter(
                                        (v) => v.code === v.S_STATE
                                      )[0]?.name || "SELECT"}
                                </option>
                                {stateList?.length > 0 &&
                                  stateList?.map((v, i) => (
                                    <option key={i} value={v.code}>
                                      {v.name}
                                    </option>
                                  ))}
                              </>
                            }
                            value={it?.S_STATE}
                            onBlur={() => handleDistrict3(s)}
                            onChange={(e) => {
                              const { value } = e.target;
                              const updatedAddresses = [
                                ...EditItem.shipping_addresses,
                              ];
                              updatedAddresses[s] = {
                                ...updatedAddresses[s],
                                S_STATE: value,
                                S_DISTRICT: "",
                                S_PINCODE: "",
                                S_LOCATION: "",
                              };
                              setEditItem({
                                ...EditItem,
                                shipping_addresses: updatedAddresses,
                              });
                            }}
                          />
                        </td>

                        <td style={{ width: "20%", padding: " .15rem .5rem" }}>
                          <Dropdown
                            value={
                              it?.S_DISTRICT
                                ? capitalizeEachWord(it?.S_DISTRICT)
                                : ""
                            }
                            onChange={(e) => {
                              const { value } = e.target;
                              const updatedAddresses = [
                                ...EditItem.shipping_addresses,
                              ];
                              updatedAddresses[s] = {
                                ...updatedAddresses[s],
                                S_DISTRICT: value,
                                S_PINCODE: "",
                                S_LOCATION: "",
                              };
                              setEditItem({
                                ...EditItem,
                                shipping_addresses: updatedAddresses,
                              });
                            }}
                            options={
                              <>
                                <option>
                                  {it?.S_DISTRICT?.length === 0 &&
                                  !it?.S_DISTRICT
                                    ? "SELECT"
                                    : it?.S_DISTRICT}
                                </option>
                                {it?.distList2Arr?.length > 0 &&
                                  it?.distList2Arr?.map((item, i) => (
                                    <option
                                      key={i}
                                      value={item.name.toUpperCase()}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                              </>
                            }
                          />
                        </td>
                        <td style={{ width: "20%", padding: " .15rem .5rem" }}>
                          <Input
                            placeholder={"PINCODE"}
                            value={it?.S_PINCODE}
                            onChange={async (e) => {
                              if (e.target.value.length <= 6) {
                                const validInput = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                const updatedAddresses = [
                                  ...EditItem.shipping_addresses,
                                ];
                                updatedAddresses[s] = {
                                  ...updatedAddresses[s],
                                  S_PINCODE: validInput,
                                  S_LOCATION: "",
                                };
                                setEditItem({
                                  ...EditItem,
                                  shipping_addresses: updatedAddresses,
                                });
                              }
                              if (e.target.value?.length === 6) {
                                get_location4(e.target.value, it?.S_STATE, s)
                                  .then((data) => {
                                    const updatedListArr = [...locationListArr];
                                    updatedListArr[s] = data;
                                    setLocationListArr(updatedListArr);
                                  })
                                  .catch((error) => {
                                    console.error(
                                      "Error fetching data:",
                                      error
                                    );
                                  });
                              }
                            }}
                            onBlur={() => {
                              setLocationListArr(locationListArr);
                            }}
                          />
                        </td>
                        <td style={{ width: "20%", padding: " .15rem .5rem" }}>
                          <Dropdown
                            onFocus={() => {
                              setLocationListArr(locationListArr);
                            }}
                            onChange={(e) => {
                              const { value } = e.target;
                              const updatedAddresses = [
                                ...EditItem.shipping_addresses,
                              ];
                              updatedAddresses[s] = {
                                ...updatedAddresses[s],
                                S_LOCATION: value,
                              };
                              setEditItem({
                                ...EditItem,
                                shipping_addresses: updatedAddresses,
                              });
                            }}
                            value={it?.S_LOCATION}
                            options={
                              <>
                                <option value={""}>SELECT</option>
                                {locationListArr[s]?.map((val, ind) => (
                                  <option key={ind} value={val}>
                                    {val}
                                  </option>
                                ))}
                              </>
                            }
                          />
                        </td>

                        <td>
                          <DeleteIcon
                            onClick={() => handleAddDelete2(it?.ID, s)}
                          />
                        </td>
                      </tr>
                    ))}
                  />
                )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontSize: "11px",
                  marginTop: "1rem",
                  gap: ".5rem",
                }}
              >
                {showEditShipping == "no" && (
                  <AddButton onClick={handleAddData2} back />
                )}
                <SaveButton onClick={() => update()} />
              </div>
            </div>
          }
        />
      )}

      {/* Upload Excel for Cust Vend */}
      {openUpload && (
        <SmallModal
          okBtnHide={false}
          isOpen={openUpload}
          setIsOpen={setOpenUpload}
          style={{ minWidth: "30rem" }}
          okbtn={setOpenUpload}
          title={"UPLOAD MASTER DATA"}
          mess={<UploadFile handle_click={(file) => upload_excel(file)} />}
        />
      )}

      {/* Update Ujjwala  */}

      {showEditUjj && (
        <SmallModal
          okBtnHide={false}
          isOpen={showEditUjj}
          setIsOpen={setShowEditUjj}
          style={{ minWidth: "35rem" }}
          okbtn={setShowEditUjj}
          title={"ENTER BANK BALANCE"}
          mess={
            <div className="pin-ver">
              <div style={{ margin: "1rem 0" }}>
                If you have credit balance put a negative sign.
              </div>
              <div
                className="pin-ver-1st"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                  margin: "1rem",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <label style={{ width: "40%", fontSize: "14px" }}>
                    Enter Account Balance:
                  </label>
                  <div style={{ width: "60%" }}>
                    <Input
                      placeholder={"Enter Account Balance"}
                      value={editItemUjj?.OBAL}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        if (
                          (inputValue?.length < 11 &&
                            /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                              inputValue
                            )) ||
                          event.target.value == 0 ||
                          !editItemUjj?.OBAL
                        ) {
                          setEditItemUjj({
                            ...editItemUjj,
                            OBAL: /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                              inputValue
                            )
                              ? inputValue
                              : "",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div style={{ width: "30%", padding: "0 5% " }}>
                  <Button onClick={update_account} title={"SUBMIT"} />
                </div>
              </div>
            </div>
          }
        />
      )}

      {/* Show Delete Pop up */}
      {showDeleteAlert && (
        <SmallModal
          isOpen={showDeleteAlert}
          setIsOpen={setShowDeleteAlert}
          onClick={() => handleDeleteCustVend(deleteId)}
          title={"Confirm"}
          mess={"Are you sure? You want to delete"}
        />
      )}

      {/* Show Alert Pop up */}
      {openAlert && (
        <SmallModal
          isOpen={openAlert}
          setIsOpen={setOpenAlert}
          title={"alert"}
          mess={alertMess}
          okbtn={setOpenAlert}
        />
      )}

      {/* Show Loader */}
      {showLoader && <Loader loading={showLoader} />}
    </div>
  );
};
