import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

const userIdString = localStorage.getItem("userId");
const userId = JSON.parse(userIdString)?.aid;

export const get_master_complete_list = createAsyncThunk(
  "completelist",
  async () => {
    const userIdString = localStorage.getItem("userId");
    const OMC = JSON.parse(userIdString)?.OMC;
    const body = new FormData();
    body.append("OMC", OMC);
    const response = await fetch(base_url + "masterdata_completelist_api.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);
export const get_statutory_list = createAsyncThunk(
  "statutorylist",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);

    if (data.entries !== "All") {
      body.append("page", data?.pageNo || "");
      body.append("limit", data?.entries || "");
    }
    const empid = JSON.parse(userIdString)?.eid;
    if (empid) {
      body.append("emp_code", empid);
    }
    const response = await fetch(base_url + "statutory_data.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data.setTotalCount(res.totalcount);
    return res;
  }
);
export const get_equipment_list = createAsyncThunk(
  "equipmentlist",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    body.append("page", data?.pageNo || "1");
    body.append("limit", data?.entries || "100000");
    const empid = JSON.parse(userIdString)?.eid;
    if (empid) {
      body.append("emp_code", empid);
    }
    const response = await fetch(base_url + "equipment_master_api.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data?.setTotalCount(res.totalcount);
    return res;
  }
);
export const get_arb_list = createAsyncThunk("arblist", async (data) => {
  const userIdString = localStorage.getItem("userId");
  const userId = JSON.parse(userIdString)?.aid;
  const body = new FormData();
  body.append("action", "list");
  body.append("agencyid", userId);
  body.append("page", data?.pageNo || "1");
  body.append("limit", data?.entries || "10000");
  const empid = JSON.parse(userIdString)?.eid;
  if (empid) {
    body.append("emp_code", empid);
  }
  const response = await fetch(base_url + "arb_api.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  data?.setTotalCount(res.totalcount);
  return res;
});

export const get_service_master_list = createAsyncThunk(
  "service",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    body.append("page", data?.pageNo || "1");
    body.append("limit", data?.entries || "10000");
    const empid = JSON.parse(userIdString)?.eid;
    if (empid) {
      body.append("emp_code", empid);
    }
    const response = await fetch(base_url + "service_master_api.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data?.setTotalCount(res.totalcount);
    return res;
  }
);

export const get_areacode_master_list = createAsyncThunk(
  "areacode",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    body.append("page", data?.pageNo || "1");
    body.append("limit", data?.entries || "10000");
    const empid = JSON.parse(userIdString)?.eid;
    if (empid) {
      body.append("emp_code", empid);
    }

    const response = await fetch(base_url + "area_code_api.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data?.setTotalCount(res.totalcount);
    return res;
  }
);

// Set nc_dbc Master

export const get_nc_dbc_list = createAsyncThunk("ncdbcpack", async (data) => {
  const userIdString = localStorage.getItem("userId");
  const userId = JSON.parse(userIdString)?.aid;
  const body = new FormData();
  body.append("action", "list");
  body.append("agencyid", userId);
  body.append("page", data?.pageNo || "1");
  body.append("limit", data?.entries || "10000000");
  const empid = JSON.parse(userIdString)?.eid;
  if (empid) {
    body.append("emp_code", empid);
  }
  const response = await fetch(base_url + "dbc_api.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  data?.setTotalCount(res.totalcount);
  return res;
});

// Set Refill Price Master

export const get_refill_price_list = createAsyncThunk(
  "refillPrice",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    body.append("page", data?.pageNo || "1");
    body.append("limit", data?.entries || "1000");
    const empid = JSON.parse(userIdString)?.eid;
    if (empid) {
      body.append("emp_code", empid);
    }
    const response = await fetch(base_url + "refill_price_api.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data?.setTotalCount(res.totalcount);
    return res;
  }
);

export const get_blpg_price_list = createAsyncThunk(
  "blpgPrice",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    body.append("page", data?.pageNo || "1");
    body.append("limit", data?.entries || "100000");
    const empid = JSON.parse(userIdString)?.eid;
    if (empid) {
      body.append("emp_code", empid);
    }

    const response = await fetch(base_url + "blpg_price_api.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data?.setTotalCount(res.totalcount);
    return res;
  }
);

// Set Customer Vender Master

export const get_customer_vender_list = createAsyncThunk(
  "customerVender",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    body.append("page", data?.pageNo || "");
    body.append("limit", data?.entries || "");
    body.append("search", data?.search || "");
    const empid = JSON.parse(userIdString)?.eid;
    if (empid) {
      body.append("emp_code", empid);
    }

    const response = await fetch(base_url + "vend_master_api.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data?.setTotalCount(res?.totalcount);
    data?.setSearchCount(res?.searchcount || 0);
    return res;
  }
);

// Set Staff Master

export const get_staff_list = createAsyncThunk("staff_list", async (data) => {
  const userIdString = localStorage.getItem("userId");
  const userId = JSON.parse(userIdString)?.aid;
  const body = new FormData();
  body.append("action", "list");
  body.append("agencyid", userId);
  body.append("page", data?.pageNo || "1");
  body.append("limit", data?.entries || "1000");
  const empid = JSON.parse(userIdString)?.eid;
  if (empid) {
    body.append("emp_code", empid);
  }

  const response = await fetch(base_url + "staff_master.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  data?.setTotalCount(res.totalcount);
  return res;
});

// Set Employee Master

export const get_employee_list = createAsyncThunk(
  "employee_list",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "listAll");
    body.append("agencyid", userId);
    body.append("page", data?.pageNo);
    body.append("limit", data?.entries);
    const empid = JSON.parse(userIdString)?.eid;
    if (empid) {
      body.append("emp_code", empid);
    }

    const response = await fetch(base_url + "employee.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data?.setTotalCount(res.totalcount);
    return res;
  }
);

// Set Vehical Master

export const get_vehical_list = createAsyncThunk("vehical", async (data) => {
  const body = new FormData();
  const userIdString = localStorage.getItem("userId");
  const userId = JSON.parse(userIdString)?.aid;
  body.append("action", "list");
  body.append("agencyid", userId);
  body.append("page", data?.pageNo || "1");
  body.append("limit", data?.entries || "10000");
  const empid = JSON.parse(userIdString)?.eid;
  if (empid) {
    body.append("emp_code", empid);
  }

  const response = await fetch(base_url + "vechical_master.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  data?.setTotalCount(res.totalcount);
  return res;
});

// Set Credit Limit
export const get_credit_limit_list = createAsyncThunk(
  "creditlimit",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    body.append("page", data?.pageNo || "");
    body.append("limit", data?.entries || "");
    const empid = JSON.parse(userIdString)?.eid;
    if (empid) {
      body.append("emp_code", empid);
    }

    const response = await fetch(base_url + "credit_limit.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data?.setTotalCount(res.totalcount);
    return res;
  }
);

// Set Stock Adjustment Limit
export const get_stock_adjustment_list = createAsyncThunk(
  "stockadjustment",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    body.append("page", data?.pageNo || "");
    body.append("limit", data?.entries || "");
    body.append("search", data?.search || "");
    const empid = JSON.parse(userIdString)?.eid;
    if (empid) {
      body.append("emp_code", empid);
    }

    const response = await fetch(base_url + "stock_adjustment.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data?.setTotalCount(res.totalcount);
    data?.setSearchCount(res?.searchcount || 0);
    return res;
  }
);

// Set Bank Master Limit
export const get_bank_master_list = createAsyncThunk(
  "bankmaster",
  async (data) => {
    const userIdString = localStorage.getItem("userId");
    const userId = JSON.parse(userIdString)?.aid;
    const body = new FormData();
    body.append("action", "list");
    body.append("agencyid", userId);
    body.append("page", data?.pageNo || "1");
    body.append("limit", data?.entries || "10000");
    const empid = JSON.parse(userIdString)?.eid;
    if (empid) {
      body.append("emp_code", empid);
    }

    const response = await fetch(base_url + "bank_master.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    data?.setTotalCount(res.totalcount);
    return res;
  }
);

const initialState = {
  master_complete_list: {
    equipment: [],
    ARB: [],
    hsn_list: [],
  },
  master_complete_loading: false,
  statutory_list: [],
  statutory_loading: false,
  equipment_list: [],
  equipment_loading: false,
  arb_list: [],
  arb_loading: false,
  area_code_list: [],
  area_code_loading: false,
  service_master_list: [],
  service_master_percent: 0,
  service_master_loading: false,
  nc_dbc_list: [],
  nc_dbc_loading: false,
  refill_price_list: [],
  refill_price_loading: false,
  blpg_price_list: [],
  blpg_price_loading: false,
  cust_vend_list: [],
  cust_vend_loading: false,
  staff_list: [],
  staff_loading: false,
  employee_list: [],
  employee_loading: false,
  vehical_list: [],
  vehical_loading: false,
  credit_limit_list: [],
  credit_limit_loading: false,
  stock_adjustment_list: [],
  stock_adjustment_loading: false,
  bank_master_list: [],
  bank_master_loading: false,
};

const masterdataSlice = createSlice({
  name: "masterdata",
  initialState: initialState,
  extraReducers: (builder) => {
    // Master Complete List
    builder.addCase(get_master_complete_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.master_complete_loading = false;
      if (data.success) {
        state.master_complete_list.equipment = data.Equipment;
        state.master_complete_list.ARB = data.ARB;
      } else {
        state.master_complete_list.equipment = [];
        state.master_complete_list.ARB = [];
      }
    });

    builder.addCase(get_master_complete_list.pending, (state, action) => {
      state.master_complete_loading = true;
    });

    builder.addCase(get_master_complete_list.rejected, (state, action) => {
      state.master_complete_loading = false;
    });

    // Master Statutory List

    builder.addCase(get_statutory_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.statutory_loading = false;
      if (data.success) {
        state.statutory_list = data.data;
      } else {
        state.statutory_list = [];
      }
    });

    builder.addCase(get_statutory_list.pending, (state, action) => {
      state.statutory_loading = true;
    });

    builder.addCase(get_statutory_list.rejected, (state, action) => {
      state.statutory_loading = false;
    });

    // Master Equipment List

    builder.addCase(get_equipment_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.equipment_loading = false;
      if (data.success) {
        state.equipment_list = data.data;
      } else {
        state.equipment_list = [];
      }
    });

    builder.addCase(get_equipment_list.pending, (state, action) => {
      state.equipment_loading = true;
    });

    builder.addCase(get_equipment_list.rejected, (state, action) => {
      state.equipment_loading = false;
    });

    // Master ARB List

    builder.addCase(get_arb_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.arb_loading = false;
      if (data.success) {
        state.arb_list = data.data;
      } else {
        state.arb_list = [];
      }
    });

    builder.addCase(get_arb_list.pending, (state, action) => {
      state.arb_loading = true;
    });

    builder.addCase(get_arb_list.rejected, (state, action) => {
      state.arb_loading = false;
    });

    // Master Service List

    builder.addCase(get_service_master_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.service_master_loading = false;

      if (data.success) {
        const dataArray = Object.keys(data.data)
          .filter((key) => key !== "percent")
          .map((key) => data.data[key]);
        state.service_master_list = dataArray;
        state.service_master_percent = data?.data?.percent;
      } else {
        state.service_master_list = [];
      }
    });

    builder.addCase(get_service_master_list.pending, (state, action) => {
      state.service_master_loading = true;
    });

    builder.addCase(get_service_master_list.rejected, (state, action) => {
      state.service_master_loading = false;
    });

    // Master Area Code List

    builder.addCase(get_areacode_master_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.area_code_loading = false;
      if (data.success) {
        state.area_code_list = data.data;
      } else {
        state.area_code_list = [];
      }
    });

    builder.addCase(get_areacode_master_list.pending, (state, action) => {
      state.area_code_loading = true;
    });

    builder.addCase(get_areacode_master_list.rejected, (state, action) => {
      state.area_code_loading = false;
    });

    // Created by Naveen
    //  NC DBC Packaging List

    builder.addCase(get_nc_dbc_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.nc_dbc_loading = false;
      if (data.success) {
        state.nc_dbc_list = data.list;
      } else {
        state.nc_dbc_list = [];
      }
    });

    builder.addCase(get_nc_dbc_list.pending, (state, action) => {
      state.nc_dbc_loading = true;
    });
    builder.addCase(get_nc_dbc_list.rejected, (state, action) => {
      state.nc_dbc_loading = false;
    });

    // Master Refill Price List

    builder.addCase(get_refill_price_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.refill_price_loading = false;
      if (data.success) {
        state.refill_price_list = data.list;
      } else {
        state.refill_price_list = [];
      }
    });
    builder.addCase(get_refill_price_list.pending, (state, action) => {
      state.refill_price_loading = true;
    });
    builder.addCase(get_refill_price_list.rejected, (state, action) => {
      state.refill_price_loading = false;
    });

    // Master BLPG Price List

    builder.addCase(get_blpg_price_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.blpg_price_loading = false;
      if (data.success) {
        state.blpg_price_list = data.list;
      } else {
        state.blpg_price_list = [];
      }
    });
    builder.addCase(get_blpg_price_list.pending, (state, action) => {
      state.blpg_price_loading = true;
    });
    builder.addCase(get_blpg_price_list.rejected, (state, action) => {
      state.blpg_price_loading = false;
    });

    // Master Customer Vender List

    builder.addCase(get_customer_vender_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.cust_vend_loading = false;
      if (data.success) {
        state.cust_vend_list = data.data;
      } else {
        state.cust_vend_list = [];
      }
    });
    builder.addCase(get_customer_vender_list.pending, (state, action) => {
      state.cust_vend_loading = true;
    });
    builder.addCase(get_customer_vender_list.rejected, (state, action) => {
      state.cust_vend_loading = false;
    });

    //  Staff Master List

    builder.addCase(get_staff_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.staff_loading = false;
      if (data.success) {
        state.staff_list = data.data;
      } else {
        state.staff_list = [];
      }
    });
    builder.addCase(get_staff_list.pending, (state, action) => {
      state.staff_loading = true;
    });
    builder.addCase(get_staff_list.rejected, (state, action) => {
      state.staff_loading = false;
    });

    //  Employee Master List

    builder.addCase(get_employee_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.employee_loading = false;
      if (data.success) {
        state.employee_list = data.data;
      } else {
        state.employee_list = [];
      }
    });
    builder.addCase(get_employee_list.pending, (state, action) => {
      state.employee_loading = true;
    });
    builder.addCase(get_employee_list.rejected, (state, action) => {
      state.employee_loading = false;
    });

    //  Vehical Master List

    builder.addCase(get_vehical_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.vehical_loading = false;
      if (data.success) {
        state.vehical_list = data.data;
      } else {
        state.vehical_list = [];
      }
    });
    builder.addCase(get_vehical_list.pending, (state, action) => {
      state.vehical_loading = true;
    });
    builder.addCase(get_vehical_list.rejected, (state, action) => {
      state.vehical_loading = false;
    });

    //  Credit Limit Master List

    builder.addCase(get_credit_limit_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.credit_limit_loading = false;
      if (data.success) {
        state.credit_limit_list = data.data;
      } else {
        state.credit_limit_list = [];
      }
    });
    builder.addCase(get_credit_limit_list.pending, (state, action) => {
      state.credit_limit_loading = true;
    });
    builder.addCase(get_credit_limit_list.rejected, (state, action) => {
      state.credit_limit_loading = false;
    });

    //  Stock Adjustment Master List

    builder.addCase(get_stock_adjustment_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.stock_adjustment_loading = false;
      if (data.success) {
        state.stock_adjustment_list = data.data;
      } else {
        state.stock_adjustment_list = [];
      }
    });
    builder.addCase(get_stock_adjustment_list.pending, (state, action) => {
      state.stock_adjustment_loading = true;
    });
    builder.addCase(get_stock_adjustment_list.rejected, (state, action) => {
      state.stock_adjustment_loading = false;
    });

    //  Bank Master List

    builder.addCase(get_bank_master_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.bank_master_loading = false;
      if (data.success) {
        state.bank_master_list = data.data;
      } else {
        state.bank_master_list = [];
      }
    });
    builder.addCase(get_bank_master_list.pending, (state, action) => {
      state.bank_master_loading = true;
    });
    builder.addCase(get_bank_master_list.rejected, (state, action) => {
      state.bank_master_loading = false;
    });
  },
});

export default masterdataSlice.reducer;
