import React, { useEffect, useMemo, useRef, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import Button from "../../../components/btn/Button";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import { motion } from "framer-motion";
import Modal from "../../../components/modal/Modal";
import Input from "../../../components/inputs/Input";
import Dropdown from "../../../components/inputs/Dropdown";
import RadioBtn from "../../../components/radio/RadioBtn";
import PageLoading from "../../../components/PageLoading";
import { BankCodeFinder, DeleteIcon, Radio } from "../../../utils/utils";
import moment from "moment";
import Table from "../../../components/table/Table";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import {
  ARBList,
  CVOList,
  PriceList,
  StaffList,
} from "../../OtherComponent/StaticLists/staticLists";
import { Pagination3 } from "../../../components/pagination/pagination";
import NoData from "../../../components/NoData";
import { useSelector } from "react-redux";
import store from "../../../redux/store";
import {
  get_arb_list,
  get_bank_master_list,
  get_blpg_price_list,
  get_credit_limit_list,
  get_customer_vender_list,
  get_staff_list,
  get_vehical_list,
} from "../../MasterData/masterdataSlice";
import {
  DateValidator2,
  gst_calculate,
  isInvoiceDateWithinCreditPeriod,
  UnitFunction,
} from "../../../utils/validation";
import {
  get_blpg_arb_sales_list,
  get_customer_purchase_order_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import { GST_data_api, arb_sales_api } from "../../../utils/apis";
import { toast } from "react-toastify";
import { DropInput } from "../../../components/inputs/DropInput";
import { alfaNumericWithoutSpace } from "../../../utils/Rejex";
import ReactToPrint from "react-to-print";
import { Alerts } from "../../../components/Alerts/Alerts";
import { UOMData } from "../../../utils/data";
import TaxInvoice2 from "../../OtherComponent/TaxInvoice/TaxInvoice2";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
import TaxInvoice3 from "../../OtherComponent/TaxInvoice/TaxInvoice3";
import { ReduceFunction } from "../../../utils/Hook/ReduceFunction";
import { EmptyDataHook } from "../../../utils/Hook/EmptyDataHook";
const tax_invoice_table_header = [
  { title: "Sl No.", cols: false },
  { title: "Description of Goods", cols: true },
  { title: "HSN/SAC", cols: false },
  { title: "GST Rate", cols: false },
  { title: "Quantity", cols: false },
  { title: "Rate Incl. GST", cols: false },
  { title: "Rate", cols: false },
  { title: "per", cols: false },
  // { title:"Disc.% Cash",cols:false},
  { title: "Discount", cols: false },
  // { title: "Transport Charge" ,cols:false},
  { title: "Amount", cols: false },
];
const tax_invoice_table_header2 = [
  "S No.",
  "PRODUCT",
  "HSN/SAC",
  "QTY",
  "UOM",
  "RATE",
  "DISCOUNT",
  "TAXABLE",
  "TDS/TCS",
  "GST %	",
  "TOTAL",
];

const objdata = {
  product_code: "",
  p_gst: "",
  unit_rate: "",
  total_amount: "",
  tcs_tds: "",
  discount_price: "",
  quantity: "",
  tcs_tds: "",
  total_amount: "",
  narration: "",
};

export const BANSales = () => {
  const previewRef = useRef(null);
  const [isAdd, setIsAdd] = useState(false);
  const [addData, setAddData] = useState([]);
  const [addDataHead, setAddDataHead] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open2data, setOpen2data] = useState("");
  const [mess, setMess] = useState("");
  const [id, setId] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [arbClick, setArbClick] = useState(false);
  const [PriceClick, setPriceClick] = useState(false);
  const [StaffClick, setStaffClick] = useState(false);
  const [cvoClick, setCvoClick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState([]);

  console.log("viewItem ===", viewItem);

  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };
  const { day_end_date, user_turnover } = useSelector((state) => state.profile);
  const { user_id, today, emp_id, user_type } = useSelector(
    (state) => state.other
  );

  const {
    cust_vend_list,
    staff_list,
    vehical_list,
    blpg_price_list,
    bank_master_list,
    credit_limit_list,
    arb_list,
  } = useSelector((state) => state.masterdata);
  const { godown_address_list } = useSelector((state) => state.profile);
  const {
    blpg_sales_list,
    blpg_sales_loading,
    transition_complete_list,
    customer_purchase_list,
  } = useSelector((state) => state.transactions);

  useEffect(() => {
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_customer_purchase_order_list());
    store.dispatch(get_customer_vender_list());
    store.dispatch(get_staff_list());
    store.dispatch(get_vehical_list());
    store.dispatch(get_blpg_price_list());
    store.dispatch(get_bank_master_list());
    store.dispatch(get_arb_list());
    store.dispatch(get_credit_limit_list());
  }, []);

  const fetchData = () => {
    store.dispatch(get_blpg_arb_sales_list(obj));
  };

  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const navigate = useNavigate();

  const calculate = () => {
    if (
      addData?.some(
        (v) => !v?.quantity || (v?.quantity && v?.quantity.length === 0)
      )
    ) {
      setMess(`Please Enter Quantity`);
      setOpen1(true);
      return;
    } else if (addData?.some((v) => v?.quantity == 0)) {
      setMess(`Quantity cannot be Zero`);
      setOpen1(true);
      return;
    }
    if (
      addData?.some(
        (v) =>
          v?.discount_price === undefined ||
          v?.discount_price === null ||
          v?.discount_price === ""
      )
    ) {
      setMess(`Please Enter DISCOUNT`);
      setOpen1(true);
      return;
    } else if (
      addData?.some((v) => Number(v?.unit_rate) <= Number(v?.discount_price))
    ) {
      setMess(`DISCOUNT cannot be greater than or equal to unit rate`);
      setOpen1(true);
      return;
    }
    let total = 0;
    addData?.forEach((data, index) => {
      const unit_rate = Number(data?.unit_rate);
      const quantity = Number(data?.quantity);
      const p_gst = Number(data?.p_gst);
      const discount_price = Number(data?.discount_price);

      const basic_amount = (unit_rate - discount_price) * quantity;
      const gst_amount = basic_amount * (p_gst / 100);
      const amount = basic_amount + gst_amount;
      const tds_amt =
        addDataHead?.type == "NA" ? 0 : basic_amount * (0.1 / 100);
      const amountwithTds =
        addDataHead?.type == "TDS"
          ? amount - tds_amt
          : addDataHead?.type == "TCS"
          ? amount + tds_amt
          : amount;
      setAddData((prevData) =>
        prevData.map((v, i) =>
          i === index
            ? {
                ...v,
                total_amount: Number(amountwithTds).toFixed(2),
                tcs_tds: Number(tds_amt).toFixed(2),
                igst_amount:
                  addDataHead?.c_type == "isp" ? gst_amount.toFixed(2) : "0.00",
                cgst_amount:
                  addDataHead?.c_type != "isp"
                    ? (gst_amount / 2).toFixed(2)
                    : "0.00",
                sgst_amount:
                  addDataHead?.c_type != "isp"
                    ? (gst_amount / 2).toFixed(2)
                    : "0.00",
                basic_amount: Number(basic_amount).toFixed(2),
              }
            : v
        )
      );
      total += amountwithTds;
    });
    setAddDataHead((prevHead) => ({
      ...prevHead,
      inv_amount: Number(total).toFixed(2),
      cash_recieved:
        addDataHead?.cust_reg == "0"
          ? Number(
              total -
                (addDataHead?.online_recieved > 0
                  ? addDataHead?.online_recieved
                  : 0)
            ).toFixed(2)
          : "",
      online_recieved:
        addDataHead?.cust_reg == "0"
          ? addDataHead?.online_recieved || 0.0
          : 0.0,
    }));
  };

  const fetch_gst = async () => {
    if (
      !addDataHead?.inv_date ||
      (addDataHead?.inv_date && addDataHead?.inv_date?.length != 10)
    ) {
      setOpen1(true);
      setMess("Please select invoice Date");
      return;
    } else if (
      !addData[addData?.length - 1]?.product_code ||
      (addData[addData?.length - 1]?.product_code &&
        addData[addData?.length - 1]?.product_code?.length === 0)
    ) {
      setOpen1(true);
      setMess("Please select PRODUCT and then click on FETCH PRICE ");
      return;
    } else if (
      !addDataHead?.customer_name ||
      (addDataHead?.customer_name && addDataHead?.customer_name?.length === 0)
    ) {
      setOpen1(true);
      setMess(
        "Please Enter CUSTOMER NO/NAME and then click on FETCH UNIT PRICE AND GST"
      );
      return;
    }
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "fetchgst");
      body.append("agencyid", user_id);
      body.append("pcode", addData[addData?.length - 1]?.product_code);
      body.append("invdate", addDataHead?.inv_date);
      body.append("cus_id", addDataHead?.customer_id || "0");
      body.append("pono", addDataHead?.purchase_order || "");
      const response = await arb_sales_api(body);
      setLoading(false);
      if (response?.success) {
        setAddData((pre) =>
          pre.map((v, i) =>
            i === addData.length - 1
              ? {
                  ...v,
                  p_gst: response?.GSTP,
                  unit_rate: response?.BASE_PRICE,
                  discount_price: response?.dis_unit_rate,
                }
              : { ...v }
          )
        );
      } else if (!response.success) {
        setMess(response?.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const preview_inv = async (type) => {
    const data = await valiDate();
    if (data?.length == 0) {
      if (type == "first") {
        if (addDataHead?.cust_reg == "0" && addDataHead?.inv_amount > 250000) {
          setMess(
            "YOUR INVOICE AMOUNT EXCEEDS RS.2,50,000/- .IF YOU WANT TO ADD FURTHER,ADD IN NEXT INVOICE"
          );
          setOpen1(true);
          return;
        } else if (addDataHead?.cust_reg == "1") {
          const data3 = credit_limit_list?.filter(
            (v) => v?.customer_id == addDataHead?.customer_id
          )?.[0];
          const data2 = cust_vend_list?.filter(
            (v) => v?.ID == addDataHead?.customer_id
          )?.[0];
          const dateValid = isInvoiceDateWithinCreditPeriod(
            data3?.modified_date,
            addDataHead?.inv_date,
            data3?.credit_days
          );

          if (data3?.cc_days == "BLOCK" && !dateValid) {
            setMess(
              "THIS TRANSACTION CANNOT BE PROCEEDED FURTHER AS THIS CUSTOMER HAS EXCEEDED CREDIT Days."
            );
            setOpen1(true);
            return;
          } else if (data3?.cc_days == "WARN" && !dateValid) {
            setMess(
              "THIS CUSTOMER HAS EXCEEDED CREDIT Days. DO YOU WANT TO CONTINUE?"
            );
            setOpen4(true);
            return;
          } else if (
            data3?.cc_limit == "BLOCK" &&
            (Number(data3.credit_limit) < Number(addDataHead?.inv_amount) ||
              Number(data3.credit_limit) < Number(data2.CBAL) ||
              Number(data3.credit_limit) <
                Number(addDataHead?.inv_amount) + Number(data2.CBAL))
          ) {
            setMess(
              "THIS TRANSACTION CANNOT BE PROCEEDED FURTHER AS THIS CUSTOMER HAS EXCEEDED CREDIT LIMIT."
            );
            setOpen1(true);
            return;
          } else if (
            (data3?.cc_limit == "WARN" &&
              (Number(data3.credit_limit) < Number(addDataHead?.inv_amount) ||
                Number(data3.credit_limit) < Number(data2.CBAL))) ||
            Number(data3.credit_limit) <
              Number(addDataHead?.inv_amount) + Number(data2.CBAL)
          ) {
            setMess(
              "THIS CUSTOMER HAS EXCEEDED CREDIT LIMIT. DO YOU WANT TO CONTINUE?"
            );
            setOpen4(true);
            return;
          }
        }
      }
      setOpen4(false);
      setOpen1(false);
      setShowPreview(true);
      filter_hsn_data(addData);
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const onDelete_item = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", id);
      if (emp_id) {
        body.append("emp_code", emp_id);
      }
      const response = await arb_sales_api(body);
      setLoading(false);
      if (response?.success) {
        setPageNo(blpg_sales_list?.length < 2 ? pageNo - 1 : pageNo);
        store.dispatch(
          get_blpg_arb_sales_list({
            pageNo: blpg_sales_list?.length < 2 ? pageNo - 1 : pageNo,
            entries: entries,
            setTotalCount: setTotalCount,
            setSearchCount: setSearchCount,
            search: SerchInput,
          })
        );
        toast(response?.message);
        setOpen(false);
      } else if (!response.success) {
        setMess(response?.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetch_invoice = async () => {
    if (
      !addDataHead?.inv_date ||
      (addDataHead?.inv_date && addDataHead?.inv_date?.length != 10)
    ) {
      setMess("Please Enter Invoice Date");
      setOpen1(true);
      return;
    } else if (
      DateValidator2(
        moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
        today,
        day_end_date
      ) != true
    ) {
      setMess(
        ...DateValidator2(
          moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
          today,
          day_end_date,
          `Invoice date cant be below as a day end date (${day_end_date})`,
          "Invoice Date cant be a Future date"
        )
      );
      setOpen1(true);
      return;
    } else if (
      !addDataHead?.customer_name ||
      (addDataHead?.customer_name && addDataHead?.customer_name?.length == 0)
    ) {
      setMess("Please Enter Customer Name");
      setOpen1(true);
      return;
    }

    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "invoicenumber");
      body.append("agencyid", user_id);
      body.append("date", addDataHead?.inv_date);
      body.append("cus_id", addDataHead?.customer_id || "");
      const response = await arb_sales_api(body);
      setLoading(false);
      if (response?.success) {
        setAddDataHead({ ...addDataHead, SR_NO: response?.invoice_number });
      } else if (!response.success) {
        setMess(response?.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const valiDate = async () => {
    const data = [];
    const data1 = await DateValidator2(
      moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Invoice date cant be below as a day end date (${day_end_date})`,
      "Invoice Date cant be a Future date"
    );
    if (
      !addDataHead?.inv_date ||
      (addDataHead?.inv_date && addDataHead?.inv_date.length === 0)
    ) {
      data.push("Please Enter Invoice Date");
    } else if (data1 != true) {
      data.push(...data1);
    } else if (
      !addDataHead?.SR_NO ||
      (addDataHead?.SR_NO && addDataHead?.SR_NO.length === 0)
    ) {
      data.push("Please Fetch Invoice Number");
    }
    if (
      !addDataHead?.cust_reg ||
      (addDataHead?.cust_reg && addDataHead?.cust_reg.length === 0)
    ) {
      data.push("Please Enter Customer Registration");
    }
    if (
      !addDataHead?.customer_name ||
      (addDataHead?.customer_name && addDataHead?.customer_name.length === 0)
    ) {
      data.push("Please Enter CUSTOMER NAME");
    }
    if (
      !addDataHead?.staff_name ||
      (addDataHead?.staff_name && addDataHead?.staff_name.length === 0)
    ) {
      data.push("Please Select Staff Name");
    }
    // if (
    //   !addDataHead?.vahicle_number_id ||
    //   (addDataHead?.vahicle_number_id &&
    //     addDataHead?.vahicle_number_id.length === 0)
    // ) {
    //   data.push("Please Select VEHICLE NUMBER");
    // }
    if (addDataHead?.cust_reg == "1") {
      // if (
      //   godown_address_list?.length > 1 &&
      //   (!addDataHead?.godown_id ||
      //     (addDataHead?.godown_id && addDataHead?.godown_id.length === 0))
      // ) {
      //   data.push("Please Select DISPATCH GODOWN");
      // }
      if (
        cust_vend_list
          ?.filter((item) => item?.CVO_CAT == "1")
          .filter((item) => item?.ID == addDataHead?.customer_id)?.[0]
          ?.shipping_addresses?.length > 1 &&
        (!addDataHead?.delivery_godown_id ||
          (addDataHead?.delivery_godown_id &&
            addDataHead?.delivery_godown_id.length === 0))
      ) {
        data.push("Please Select DELIVERY GODOWN");
      }
    } else if (addDataHead?.cust_reg == "0") {
      if (
        !addDataHead?.address ||
        (addDataHead?.address && addDataHead?.address.length === 0)
      ) {
        data.push("Please Enter Address");
      } else if (addDataHead?.address?.length < 10) {
        data.push("Address should be greater then 10 charactors ");
      } else if (addDataHead?.address?.length > 200) {
        data.push("Address should be less then 200 charactors ");
      }
      if (
        addDataHead?.gst_num &&
        addDataHead?.gst_num != "" &&
        addDataHead?.gst_num.length != 15
      ) {
        data.push("Please Enter a Valid GST");
      }
      if (addDataHead?.payment_terms_dis == "1") {
        const sumData = (
          Number(addDataHead?.cash_recieved || 0) +
          Number(addDataHead?.online_recieved || 0)
        ).toFixed(2);
        if (
          (!addDataHead?.cash_recieved || addDataHead?.cash_recieved == 0) &&
          (!addDataHead?.online_recieved || addDataHead?.online_recieved == 0)
        ) {
          data.push("Please Enter Cash and Online Recieved Amount");
        } else if (
          Number(addDataHead?.cash_recieved) < 0 ||
          Number(addDataHead?.online_recieved) < 0
        ) {
          if (Number(addDataHead?.cash_recieved) < 0) {
            data.push("Cash Received amount cant be lessthen Zero");
          } else if (Number(addDataHead?.online_recieved) < 0) {
            data.push("Online Received amount cant be lessthen Zero");
          }
        } else if (sumData != Number(addDataHead?.inv_amount).toFixed(2)) {
          data.push(
            "Please Make sure the sum of Cash and Online Recieved Amount is Equal to Inv Amount"
          );
        }
        if (addDataHead?.online_recieved > 0) {
          // if (
          //   !addDataHead?.transaction_id ||
          //   (addDataHead?.transaction_id &&
          //     addDataHead?.transaction_id?.length == 0)
          // ) {
          //   data.push("Please Enter Transaction Id");
          // } else if (addDataHead?.transaction_id?.length != 15) {
          //   data.push("Please Enter valid Transaction Id");
          // }

          // if (
          //   addDataHead?.cash_recieved_from &&
          //   addDataHead?.cash_recieved_from?.length == 0 &&
          //   addDataHead?.cash_recieved > 0
          // ) {
          //   data.push("Please Select a Cash Recieved From");
          // }
          // if (
          //   addDataHead?.bank_account &&
          //   addDataHead?.bank_account?.length == 0 &&
          //   addDataHead?.online_recieved > 0
          // ) {
          //   data.push("Please Select a Bank Account");
          // }
          if (
            (!addDataHead?.cash_recieved_from ||
              addDataHead?.cash_recieved_from == "") &&
            addDataHead?.cash_recieved > 0
          ) {
            data.push("Please Select a Cash Recieved From");
          }
          if (
            (!addDataHead?.bank_account || addDataHead?.bank_account == "") &&
            addDataHead?.online_recieved > 0
          ) {
            data.push("Please Select a Bank Account");
          }
        }
      }
    }

    for (let v of addData) {
      if (
        !v?.product_code ||
        (v?.product_code && v?.product_code?.length === 0)
      ) {
        data.push("Please Select PRODUCT ");
      }
      if (!v?.p_gst || (v?.p_gst && v?.p_gst?.length === 0)) {
        data.push("Please Select GST");
      }
      if (!v?.unit_rate || (v?.unit_rate && v?.unit_rate?.length === 0)) {
        data.push("Please Select Unit Rate");
      }
      // if (
      //   !v?.discount_price ||
      //   (v?.discount_price && v?.discount_price?.length === 0)
      // ) {
      //   data.push("Please Enter Discount Price");
      // }
      if (
        v?.discount_price === undefined ||
        v?.discount_price === null ||
        v?.discount_price === ""
      ) {
        data.push("Please Enter Discount Price");
      } else if (v?.discount_price < 0) {
        data.push("Discount cant be a negetive value");
      }
      if (!v?.quantity || (v?.quantity && v?.quantity?.length === 0)) {
        data.push("Please Enter Quantity");
      } else if (v?.quantity == 0) {
        data.push("Please Enter valid Quantity");
      }
      if (
        !v?.total_amount ||
        (v?.total_amount && v?.total_amount.length === 0)
      ) {
        data.push("First Cliick on Calculate Button");
      }
      if (!v?.narration || (v?.narration && v?.narration.length === 0)) {
        data.push("Please Enter Narration");
      }
    }
    if (
      !addDataHead?.inv_amount ||
      (addDataHead?.inv_amount && addDataHead?.inv_amount.length === 0)
    ) {
      data.push("First Cliick on Calculate Button");
    }
    return data;
  };
  const add_items = async () => {
    const data = await valiDate();
    if (data?.length == 0) {
      try {
        setLoading(true);
        const formdata = new FormData();
        formdata.append("action", "create");
        formdata.append("agencyid", user_id);
        formdata.append("invnumber", addDataHead?.SR_NO);
        formdata.append("invdate", addDataHead?.inv_date);
        formdata.append("pono", addDataHead?.PO_NUMBER);
        formdata.append("cus_id", addDataHead?.customer_id);
        formdata.append("cus_name", addDataHead?.customer_name);
        formdata.append("si_amt", addDataHead?.inv_amount);
        formdata.append("payment_terms", addDataHead?.payment_terms);
        formdata.append("staff_id", addDataHead?.staff_name);
        formdata.append("discount", "375");
        formdata.append("vehicle_no", addDataHead?.vahicle_number_id);
        formdata.append("cust_reg", addDataHead?.cust_reg == "0" ? "2" : "1");
        if (addDataHead?.cust_reg == "1") {
          // formdata.append("dispatch_godown", addDataHead?.godown_id || "");
          formdata.append(
            "delivery_godown",
            addDataHead?.delivery_godown_id || ""
          );
          // formdata.append("cust_reg", addDataHead?.cust_reg);
        } else if (addDataHead?.cust_reg == "0") {
          // if (addDataHead?.cust_reg == "0") {}4
          formdata.append("dispatch_godown", addDataHead?.godown_id || "");
          formdata.append("gst_no", addDataHead?.gst_num || "");
          formdata.append("address", addDataHead?.address || "");
          formdata.append("cash_rcvd", addDataHead?.cash_recieved);
          formdata.append("online_rcvd_amt", addDataHead?.online_recieved);
          formdata.append("transaction_id", addDataHead?.transaction_id || "");
          formdata.append("cash_rcvd_form", addDataHead?.cash_recieved_from);
          formdata.append("bank_id", addDataHead?.bank_account);
        }
        addData?.forEach((v) => {
          if (v) {
            formdata.append("product[]", v?.product_code);
            formdata.append("quantity[]", v?.quantity);
            formdata.append("unit_rate[]", v?.unit_rate);
            formdata.append("dis_unit_rate[]", v?.discount_price);
            formdata.append("gst[]", v?.p_gst);
            formdata.append("total_amount[]", v?.total_amount);
            formdata.append("tds[]", v?.tcs_tds);
            formdata.append("basic_amount[]", v?.basic_amount);
            formdata.append("igst_amount[]", v?.igst_amount);
            formdata.append("cgst_amount[]", v?.cgst_amount);
            formdata.append("sgst_amount[]", v?.sgst_amount);
            formdata.append("narration[]", v?.narration);
          }
        });
        formdata.append(
          "tds_status",
          addDataHead?.type == "TDS"
            ? "1"
            : addDataHead?.type == "TCS"
            ? "2"
            : "3"
        );
        formdata.append("connection_type", addDataHead?.c_type || "");
        if (emp_id) {
          formdata.append("emp_code", emp_id);
        }

        const response = await arb_sales_api(formdata);
        setLoading(false);
        if (response.success) {
          toast(response.message);
          setIsAdd(false);
          setShowPreview(false);
          setAddData([]);
          setAddDataHead({});
          store.dispatch(get_blpg_arb_sales_list(obj));
        } else if (!response.success) {
          setMess(response?.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };
  const validation_check = () => {
    if (
      addData.find(
        (v) =>
          !v?.product_code ||
          (v.product_code && v.product_code.length == 0) ||
          !v.p_gst ||
          (v.p_gst && v.p_gst.length == 0) ||
          !v.unit_rate ||
          (v.unit_rate && v.unit_rate.length == 0) ||
          !v.quantity ||
          v.quantity == 0 ||
          (v.quantity && v.quantity.length == 0) ||
          v?.discount_price === undefined ||
          v?.discount_price === null ||
          v?.discount_price === "" ||
          !v.total_amount ||
          (v.total_amount && v.total_amount.length == 0)
      )
    ) {
      return true;
    }
  };
  const userIdString = localStorage.getItem("userId");
  const OMC = JSON.parse(userIdString)?.OMC;

  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);

  const filter_hsn_data = (addData) => {
    if (addData?.length > 0) {
      const hsn_data = Object.values(
        addData.reduce((acc, item) => {
          if (!acc[item.hsn]) {
            acc[item.hsn] = {
              hsn: item.hsn,
              taxable: 0,
              cgst: 0,
              igst: 0,
              sgst: 0,
              total: 0,
              igst_p: addDataHead?.c_type == "lp" ? 0 : item?.p_gst,
              sgst_p: addDataHead?.c_type == "lp" ? Number(item?.p_gst) / 2 : 0,
              cgst_p: addDataHead?.c_type == "lp" ? Number(item?.p_gst) / 2 : 0,
            };
          }
          acc[item.hsn].taxable += Number(item.basic_amount);
          acc[item.hsn].cgst += Number(item.cgst_amount);
          acc[item.hsn].igst += Number(item.igst_amount);
          acc[item.hsn].sgst += Number(item.sgst_amount);
          acc[item.hsn].total +=
            Number(item.cgst_amount) +
            Number(item.igst_amount) +
            Number(item.sgst_amount);
          return acc;
        }, {})
      );
      setAddDataHead({ ...addDataHead, hsn_data: hsn_data });
    } else {
      return;
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={
          (OMC == 1 && "NFR SALES") ||
          (OMC == 2 && "ARB SALES") ||
          (OMC == 3 && "BLPG SALES")
        }
        right={
          <>
            <AddButton
              onClick={() => {
                setAddData((prevData) => [...prevData, {}]);
                setIsAdd(true);
              }}
            />
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/oEzSuwSIkRg",
                "https://youtu.be/rrX-pqA7h68",
              ]}
            />
          </>
        }
      />
      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {blpg_sales_loading ? (
          <PageLoading />
        ) : blpg_sales_list?.length == 0 ? (
          <NoData />
        ) : (
          <div className="grid">
            {blpg_sales_list.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">
                    {v?.CUSTOMER?.toUpperCase() || "NA"}
                  </div>
                  <RowTxt
                    title={"Invoice No."}
                    value={
                      <ReactToPrint
                        trigger={() => (
                          <div
                            onMouseEnter={() => {
                              setViewItem(v);
                            }}
                            onTouchStart={() => {
                              setViewItem(v);
                            }}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setViewItem(v);
                            }}
                          >
                            {v?.SR_NO}
                          </div>
                        )}
                        content={() => previewRef.current}
                        documentTitle={`${v?.CUSTOMER}-${v?.SR_NO}`}
                      />
                    }
                  />
                  <RowTxt
                    title={"Invoice Date"}
                    value={moment(v?.INVOICE_DATE).format("DD-MMM-YYYY")}
                  />
                  <RowTxt title={"Total Amount"} value={v?.SI_AMOUNT} />
                  {/* <RowTxt title={"Staff Name"} value={v?.STAFF_NAME} /> */}
                  {/* <RowTxt title={"Vehicle No."} value={v?.VEHICLE_NO} /> */}
                  <RowTxt
                    title={"Payment Terms "}
                    value={v?.PAYMENT_TERMS == "1" ? "CASH" : "CREDIT"}
                  />
                  <RowTxt
                    title={"TDS/TCS"}
                    value={
                      v?.TDS_STATUS == 1
                        ? "TDS"
                        : v?.TDS_STATUS == 2
                        ? "TCS"
                        : v?.TDS_STATUS == 3
                        ? "NA"
                        : ""
                    }
                  />
                  {/* <RowTxt
                    title={"Customer Type"}
                    value={
                      v?.CUSTOMER_STATUS == "2"
                        ? "Un Registered Customer"
                        : "Registered Customer"
                    }
                  /> */}
                  {v?.PAYMENT_TERMS == "1" && (
                    <>
                      {v?.GST_NO && (
                        <RowTxt title={"GST Number"} value={v?.GST_NO} />
                      )}
                      {/* <RowTxt
                        title={"Address"}
                        value={v?.CVO_ADDRESS || "NA"}
                      />
                      <RowTxt title={"GSTIN No."} value={v?.GST_NO || "NA"} /> */}
                      <RowTxt
                        title={"Cash Recieved"}
                        value={v?.CASH_RECEIVED}
                      />
                      <RowTxt
                        title={"Cash Recieved From"}
                        value={
                          v?.CASH_RECEIVED_FROM == "1"
                            ? "Phone Pay"
                            : v?.CASH_RECEIVED_FROM == "2"
                            ? "Google Pay"
                            : v?.CASH_RECEIVED_FROM == "3"
                            ? "Paytm"
                            : v?.CASH_RECEIVED_FROM == "4"
                            ? "Cred"
                            : v?.CASH_RECEIVED_FROM == "5"
                            ? "Others"
                            : "NA"
                        }
                      />
                      <RowTxt
                        title={"Online Recieved"}
                        value={v?.ONLINE_RCVD_AMOUNT || 0}
                      />
                      <RowTxt
                        title={"Online Recieved From"}
                        value={v?.BANK_ACCOUNT}
                      />
                      <RowTxt
                        title={"Transaction Id"}
                        value={v?.TRANSACTION_ID}
                      />
                    </>
                  )}
                  <RowTxt title={"Created By"} value={v?.CREATED_NAME || "-"} />
                </div>
                <div
                  className="df jcsb mt10"
                  style={{
                    justifyContent:
                      user_type != "admin" && v?.EMPLOYEE_CODE != emp_id
                        ? "flex-end"
                        : "space-between",
                  }}
                >
                  {v?.STATUS_FLAG == "0" ? (
                    <>
                      {user_type == "admin" || v?.EMPLOYEE_CODE == emp_id ? (
                        <DeleteIcon
                          onClick={() => {
                            setId(v?.ID);
                            setOpen(true);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <div></div>
                  )}
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div
                      className="view-btn"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    >
                      View
                    </div>
                    <HiOutlineArrowRight
                      size={20}
                      color="#1b64af"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddData([]);
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button
                title={"CVO"}
                back
                onClick={() => setCvoClick(!cvoClick)}
              />
              <Button
                title={"ARB"}
                back
                onClick={() => setArbClick(!arbClick)}
              />
              <Button
                title={"PRICE"}
                back
                onClick={() => setPriceClick(!PriceClick)}
              />
              <Button
                title={"BANK"}
                back
                onClick={() => setCvoClick(!cvoClick)}
              />
              <Button
                title={"STAFF"}
                back
                onClick={() => setStaffClick(!StaffClick)}
              />
            </div>
          }
          title={
            (OMC == 1 && "NFR SALES") ||
            (OMC == 2 && "ARB SALES") ||
            (OMC == 3 && "BLPG SALES")
          }
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Input
                  label={"SALES INVOICE DATE"}
                  type={"date"}
                  value={addDataHead.inv_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        inv_date: e.target.value,
                        SR_NO: "",
                      });
                      const resetData1 = EmptyDataHook(addData?.[0]);
                      setAddData([resetData1]);
                    }
                  }}
                />
                <Input
                  label={"INVOICE NUMBER"}
                  placeholder={"INVOICE NUMBER"}
                  value={addDataHead?.SR_NO}
                  disabled={true}
                />
                <Dropdown
                  width2=""
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      cust_reg: e.target.value,
                      customer_name: "",
                      customer_id: "",
                      gst_num: "",
                      address: "",
                      dis_radio: e.target.value == "0" ? true : false,
                      type: e.target.value == "0" ? "NA" : "",
                      payment_terms:
                        e.target.value == "1"
                          ? "2"
                          : e.target.value == "0"
                          ? "1"
                          : "",
                      SR_NO: "",
                      inv_amount: "",
                      payment_terms_dis: true,
                      // e.target.value == "1"
                      //   ?
                      //     true
                      //   : false,
                    });
                    setAddData([objdata]);
                  }}
                  value={addDataHead?.cust_reg}
                  label={"CUSTOMER REG."}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"1"}>YES</option>
                      <option value={"0"}>NO</option>
                    </>
                  }
                />
                {addDataHead?.cust_reg == "0" && (
                  <Input
                    label={"GST NO."}
                    placeholder={"GST NO."}
                    value={addDataHead?.gst_num}
                    onChange={async (e) => {
                      setMess("");
                      let GSTInputValue = e.target.value.toUpperCase();
                      const validInput = GSTInputValue.replace(
                        /[^a-zA-Z0-9]/g,
                        ""
                      );
                      if (validInput.length <= 15) {
                        setAddDataHead({
                          ...addDataHead,
                          gst_num: validInput,
                        });
                      }
                      if (validInput?.length == 15) {
                        try {
                          const body = new FormData();
                          body.append("gstin", validInput);
                          const response = await GST_data_api(body);
                          if (response.error === false) {
                          } else if (response.error === true) {
                            setAddDataHead({
                              ...addDataHead,
                              gst_num: "",
                            });
                            setMess(
                              response.errmsg == null
                                ? "Please enter valid GSTIN"
                                : response?.errmsg
                            );
                          } else {
                            setMess(response?.errmsg);
                          }
                          setOpen1(true);
                        } catch (error) {
                          console.log(error);
                        }
                      }
                    }}
                  />
                )}
                <DropInput
                  label={"CUSTOMER / OMC"}
                  id="cust"
                  placeholder={"CUSTOMER NAME"}
                  value={addDataHead?.customer_name}
                  onChange={(e) => {
                    const data = cust_vend_list?.filter(
                      (item) =>
                        item?.CVO_CAT == "1" && item?.ID == e.target.value
                    );
                    const data1 = transition_complete_list?.Customer?.filter(
                      (item) => item?.ID == e.target.value
                    );
                    setAddDataHead({
                      ...addDataHead,
                      customer_name:
                        addDataHead?.cust_reg == "1"
                          ? data[0]?.cvo_name
                          : e.target.value || "",
                      customer_id:
                        addDataHead?.cust_reg == "1"
                          ? e.target.value
                          : "" || "",
                      SR_NO: "",
                      type:
                        e.target.value != ""
                          ? user_turnover < 4 &&
                            data1?.[0]?.ANNUAL_TURNOVER == 1
                            ? "NA" // If user_turnover is less than 10 CR and VENDOR ANNUAL_TURNOVER is > 10 CR, return "NA"
                            : // user_turnover >= 4 &&
                            data1?.[0]?.ANNUAL_TURNOVER == 1 &&
                              data1?.[0]?.ANNUAL_PURCHASE == 1
                            ? "TCS" // If  VENDOR ANNUAL_TURNOVER is > 10 CR, and VENDOR ANNUAL_PURCHASE is > 50 LAKHS, return "TCS"
                            : user_turnover >= 4 &&
                              data1?.[0]?.ANNUAL_TURNOVER == 0 &&
                              data1?.[0]?.ANNUAL_PURCHASE == 1
                            ? "TDS" // If user_turnover is greater than or equal to 10 CR, VENDOR ANNUAL_PURCHASE is > 50 LAKHS, and ANNUAL_TURNOVER is < 10 CR, return "TDS"
                            : "NA"
                          : "",
                      dis: e.target.value != "" && data1?.[0] ? true : false,
                      c_type:
                        addDataHead?.cust_reg == "1"
                          ? data1[0]?.connection_type
                          : "lp",
                      inv_amount: "",
                      c_type_dis: addDataHead?.cust_reg == "1" ? true : false,
                      address: data?.length > 0 ? data?.[0]?.cvo_address : "",
                      gst_num:
                        addDataHead?.cust_reg == "1"
                          ? data?.length > 0
                            ? data?.[0]?.CVO_TIN
                            : ""
                          : "",
                      district: data1?.length > 0 ? data1[0]?.B_DISTRICT : "",
                      location: data1?.length > 0 ? data1[0]?.B_LOCATION : "",
                      pincode: data1?.length > 0 ? data1[0]?.B_PINCODE : "",
                      state_code: data?.length > 0 ? data?.[0]?.B_STATE : "",
                      state_name:
                        data?.length > 0 ? data?.[0]?.B_STATE_NAME : "",
                      mobile: data1?.length > 0 ? data[0]?.CVO_CONTACT : "",
                      delivery_godown_id: "",
                      ship_name: "",
                      ship_address: "",
                      ship_location: "",
                      ship_district: "",
                      ship_pincode: "",
                      ship_state_code: "",
                      ship_state_name: "",
                    });
                    setAddData([objdata]);
                  }}
                  list={
                    addDataHead?.cust_reg == "1"
                      ? transition_complete_list?.Customer?.filter(
                          (v, i) => v?.ARB == "1"
                        )?.map((v, i) => (
                          <option key={i} value={v?.ID}>
                            {v?.cvo_name?.toUpperCase()}
                          </option>
                        ))
                      : ""
                  }
                />

                {addDataHead?.cust_reg == "0" ? (
                  <>
                    <Input
                      label={"ADDRESS"}
                      placeholder={"ADDRESS"}
                      value={addDataHead?.address}
                      onChange={(e) => {
                        if (e.target.value.length <= 200) {
                          setAddDataHead({
                            ...addDataHead,
                            address: e.target.value,
                          });
                        }
                      }}
                    />
                  </>
                ) : (
                  <Dropdown
                    value={addDataHead?.purchase_order}
                    width2=""
                    label={"PURCHASE ORDER NO"}
                    onChange={(e) => {
                      const data = customer_purchase_list?.filter(
                        (v) => v?.ID == e.target.value
                      )?.[0];
                      setAddDataHead({
                        ...addDataHead,
                        purchase_order: e.target.value,
                        PO_NUMBER: data ? data?.PO_NO : "",
                        PO_DATE: data ? data?.PO_Date : "",
                      });
                    }}
                    options={
                      <>
                        <option value={""}>SELECT</option>
                        {customer_purchase_list
                          ?.filter(
                            (v) => v?.CUSTOMER_ID == addDataHead?.customer_id
                          )
                          .filter(
                            (v) =>
                              new Date(v?.PO_VALID_UPTO) >=
                              new Date(addDataHead?.inv_date || new Date())
                          )
                          ?.map((v, i) => (
                            <option value={v?.ID} key={i}>
                              {v?.PO_NO}
                            </option>
                          ))}
                      </>
                    }
                  />
                )}

                <Dropdown
                  width2=""
                  value={addDataHead?.payment_terms}
                  disabled={addDataHead?.payment_terms_dis}
                  label={"PAYMENT TERMS"}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      payment_terms: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"1"}>CASH</option>
                      <option value={"2"}>CREDIT</option>
                    </>
                  }
                />
                {addDataHead?.payment_terms == "1" && (
                  <>
                    <Input
                      label={"CASH RECIEVED"}
                      placeholder={"CASH RECIEVED"}
                      value={addDataHead?.cash_recieved}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          (inputValue?.length <= 10 &&
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            )) ||
                          inputValue === "" ||
                          inputValue === "-" ||
                          !addDataHead?.cash_recieved
                        ) {
                          setAddDataHead({
                            ...addDataHead,
                            cash_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              )
                                ? inputValue
                                : "",
                            online_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) ||
                              inputValue === "" ||
                              inputValue === "-"
                                ? Number(
                                    Number(addDataHead?.inv_amount || 0) -
                                      Number(
                                        inputValue == "-" ? 0 : inputValue || 0
                                      )
                                  )?.toFixed(2)
                                : "",
                          });
                        }
                      }}
                    />
                    <Input
                      label={"ONLINE RCVD AMOUNT"}
                      placeholder={"ONLINE RCVD AMOUNT"}
                      value={addDataHead?.online_recieved}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          (inputValue?.length <= 10 &&
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            )) ||
                          inputValue === "" ||
                          inputValue === "-" ||
                          !addDataHead?.online_recieved
                        ) {
                          setAddDataHead({
                            ...addDataHead,
                            online_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              )
                                ? inputValue
                                : "",
                            cash_recieved:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) ||
                              inputValue == "" ||
                              inputValue === "-"
                                ? Number(
                                    Number(addDataHead?.inv_amount || 0) -
                                      Number(
                                        inputValue == "-" ? 0 : inputValue || 0
                                      )
                                  )?.toFixed(2)
                                : "",
                          });
                        }
                      }}
                    />
                    <Input
                      placeholder={"TRANSACTION ID"}
                      disabled={addDataHead?.online_recieved <= 0}
                      label={"TRANSACTION ID"}
                      onChange={(e) => {
                        const inputValue = e.target.value?.toUpperCase();
                        if (
                          inputValue.length <= 15 &&
                          (alfaNumericWithoutSpace.test(inputValue) ||
                            inputValue === "")
                        ) {
                          setAddDataHead({
                            ...addDataHead,
                            transaction_id: alfaNumericWithoutSpace.test(
                              inputValue
                            )
                              ? inputValue
                              : "",
                          });
                        }
                      }}
                      value={addDataHead?.transaction_id}
                    />
                    <Dropdown
                      width2=""
                      disabled={addDataHead?.online_recieved <= 0}
                      label={"CASH RECIVED FROM"}
                      value={addDataHead?.cash_recieved_from}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          cash_recieved_from: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value="">SELECT</option>
                          <option value="1">Phonepay</option>
                          <option value="2">GPay</option>
                          <option value="3">Paytm</option>
                          <option value="4">Cred</option>
                          <option value="5">Others</option>
                        </>
                      }
                    />

                    <Dropdown
                      width2=""
                      label={"BANK ACCOUNT"}
                      disabled={addDataHead?.online_recieved <= 0}
                      value={addDataHead?.bank_account}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          bank_account: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value="">SELECT</option>
                          {bank_master_list
                            ?.filter((v) => v?.BANK_CODE != "STAR ACCOUNT")
                            //  ?.filter((v) => v?.BANK_CODE != "OVER DRAFT")
                            //  ?.filter((v) => v?.BANK_CODE != "TAR ACCOUNT")
                            ?.filter((v, i) => v?.BANK_CODE != "CASH ACCOUNT")
                            ?.filter((v) => v?.BANK_CODE != "LOAN")
                            ?.map((v, i) => (
                              <option key={i} value={v?.ID}>
                                {bankCode(v?.BANK_CODE)} -
                                {v?.BANK_ACC_NO == "NA"
                                  ? user_id
                                  : v?.BANK_ACC_NO}
                              </option>
                            ))}
                        </>
                      }
                    />
                  </>
                )}
                {/* <Dropdown
                  width2=""
                  label={"DISPATCH GODOWN"}
                  value={addDataHead?.godown_id}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      godown_id: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {godown_address_list?.map((s, i) => (
                        <option key={i} value={s?.ID}>
                          {s?.UNIT_NAME}
                        </option>
                      ))}
                    </>
                  }
                /> */}
                {/* )} */}

                {addDataHead?.cust_reg == "1" && (
                  <>
                    <Dropdown
                      width2=""
                      label={"DELIVERY GODOWN"}
                      value={addDataHead?.delivery_godown_id}
                      onChange={(e) => {
                        const data = cust_vend_list
                          ?.filter((item) => item?.CVO_CAT == "1")
                          .filter(
                            (item) => item?.ID == addDataHead?.customer_id
                          )?.[0]
                          ?.shipping_addresses?.filter(
                            (v, i) => v?.ID == e.target.value
                          )?.[0];

                        setAddDataHead({
                          ...addDataHead,
                          delivery_godown_id: e.target.value,
                          ship_name: data ? data?.UNIT_NAME : "",
                          ship_address: data ? data?.S_ADDRESS : "",
                          ship_location: data ? data?.S_LOCATION : "",
                          ship_district: data ? data?.S_DISTRICT : "",
                          ship_pincode: data ? data?.S_LOCATION : "",
                          ship_state_code: data ? data?.S_STATE : "",
                          ship_state_name: data ? data?.S_DISTRICT : "",
                        });
                      }}
                      options={
                        <>
                          <option value={"0"}>SELECT</option>
                          {cust_vend_list
                            ?.filter((item) => item?.CVO_CAT == "1")
                            .filter(
                              (item) => item?.ID == addDataHead?.customer_id
                            )?.[0]
                            ?.shipping_addresses?.map((s, i) => (
                              <option key={i} value={s?.ID}>
                                {s?.UNIT_NAME}
                              </option>
                            ))}
                        </>
                      }
                    />
                  </>
                )}
                <Input
                  label={"SALES INVOICE AMOUNT"}
                  placeholder={"INV AMOUNT"}
                  disabled={true}
                  value={addDataHead.inv_amount}
                />
                <Dropdown
                  width2=""
                  value={addDataHead?.staff_name}
                  label={"STAFF NAME"}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      staff_name: e.target.value,
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {staff_list?.map((s, i) => (
                        <option key={i} value={s?.ID}>
                          {s?.EMP_NAME}
                        </option>
                      ))}
                    </>
                  }
                />

                <Dropdown
                  width2=""
                  value={addDataHead?.vahicle_number_id}
                  label={"VEHICLE NUMBER"}
                  onChange={(e) => {
                    const data = vehical_list?.filter(
                      (v) => v?.ID == e.target.value
                    );
                    setAddDataHead({
                      ...addDataHead,
                      vahicle_number_id: e.target.value,
                      vahicle_number:
                        data?.length > 0 ? data?.[0]?.VEHICLE_NO : "",
                    });
                  }}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {vehical_list?.map((s, i) => (
                        <option key={i} value={s?.ID}>
                          {s?.VEHICLE_NO}
                        </option>
                      ))}
                    </>
                  }
                />
              </div>
              <div
                className="flex aic gap10"
                style={{
                  width: "600px",
                  flexWrap: "wrap",
                  rowGap: "0",
                  margin: "1rem 0",
                }}
              >
                <RadioBtn label={"TDS/TCS"} />
                <Radio
                  disabled={addDataHead?.dis_radio}
                  title={"TDS"}
                  show={addDataHead?.type == "TDS" ? "TDS" : ""}
                  setShow={() => {
                    setOpen2(true);
                    setOpen2data("TDS");
                  }}
                />
                <Radio
                  disabled={addDataHead?.dis_radio}
                  title={"TCS"}
                  show={addDataHead?.type == "TCS" ? "TCS" : ""}
                  setShow={() => {
                    setOpen2(true);
                    setOpen2data("TCS");
                  }}
                />
                <Radio
                  disabled={addDataHead?.dis_radio}
                  title={"NA"}
                  show={addDataHead?.type == "NA" ? "NA" : ""}
                  setShow={() => {
                    setOpen2(true);
                    setOpen2data("NA");
                  }}
                />
              </div>
              <div
                style={{
                  maxHeight: "20%",
                  overflow: "scroll",
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Table
                  headData={[
                    "PRODUCT",
                    "GST %	",
                    "UNIT PRICE",
                    "DISCOUNT ON UNIT PRICE",
                    "QUANTITY",
                    "TDS/TCS",
                    "TOTAL AMOUNT",
                    "NARRATION",
                    "ACTIONS",
                  ]}
                  body={addData?.map((val, ind) => (
                    <tr key={ind}>
                      <td>
                        {
                          <Dropdown
                            options={
                              <>
                                <option value={""}>SELECT</option>
                                {addDataHead?.PO_NUMBER?.length > 0
                                  ? customer_purchase_list
                                      ?.filter(
                                        (v) =>
                                          v?.PO_NO == addDataHead?.PO_NUMBER
                                      )
                                      .filter(
                                        (v) =>
                                          v?.ID == addDataHead?.purchase_order
                                      )?.[0]
                                      ?.products?.filter(
                                        (v) =>
                                          !(
                                            v?.CAT_NAME == "COMMERCIAL" ||
                                            v?.CAT_NAME == "DOMESTIC" ||
                                            v?.CAT_NAME == "RELATORS"
                                          )
                                      )
                                      ?.filter(
                                        (v) =>
                                          v?.CAT_DESC &&
                                          v?.PROD_NAME &&
                                          v?.CAT_NAME
                                      )
                                      ?.map((v, i) => (
                                        <option
                                          disabled={
                                            addData?.filter(
                                              (d) => d?.product_code == v?.CODE
                                            )?.length > 0
                                          }
                                          key={i}
                                          value={v?.CODE}
                                        >
                                          {v?.CAT_DESC} - {v?.CAT_NAME} -
                                          {v?.PROD_NAME}
                                        </option>
                                      ))
                                  : blpg_price_list.map((v, i) => (
                                      <option
                                        disabled={
                                          addData?.filter(
                                            (d) => d?.product_code == v?.arbcode
                                          )?.length > 0
                                        }
                                        key={i}
                                        value={v?.arbcode}
                                      >
                                        {v?.cat_desc} - {v?.prod_brand} -
                                        {v?.prod_name}
                                      </option>
                                    ))}
                              </>
                            }
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        product_code: e.target.value,
                                        p_gst: "",
                                        unit_rate: "",
                                        total_amount: "",
                                        tcs_tds: "",
                                      }
                                    : { ...v }
                                )
                              )
                            }
                            value={val.product_code}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.p_gst}
                            placeholder={"GST %	"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.unit_rate}
                            placeholder={"UNIT PRICE"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.discount_price}
                            placeholder={"DISCOUNT ON UNIT PRICE"}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length < 10 &&
                                  /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                inputValue == "" ||
                                !val?.discount_price
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i == ind
                                      ? {
                                          ...v,
                                          discount_price:
                                            /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(
                                              inputValue
                                            ) || inputValue == ""
                                              ? inputValue
                                              : "",
                                          total_amount: "",
                                          tcs_tds: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.quantity}
                            placeholder={"Quantity"}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length <= 4 &&
                                  /^\d{0,4}$/.test(inputValue)) ||
                                inputValue === "0" ||
                                !val?.quantity
                              ) {
                                setAddData((prev) =>
                                  prev.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          quantity: /^\d{0,4}$/.test(inputValue)
                                            ? inputValue
                                            : "",
                                          total_amount: "",
                                          tcs_tds: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      {/* <td>
                        {
                          <Dropdown
                            options={
                              <>
                                <option
                                  disabled={addDataHead?.bank_account == 1}
                                  value={""}
                                >
                                  SELECT
                                </option>
                                {bank_master_list?.map((v, i) => (
                                  <option
                                    key={i}
                                    value={v?.ID}
                                    disabled={addDataHead?.bank_account == 1}
                                  >
                                    {v?.BANK_CODE} -{" "}
                                    {
                                      bankDetails?.filter(
                                        (s, i) => s?.value == v?.BANK_NAME
                                      )[0]?.bankName
                                    }
                                  </option>
                                ))}
                              </>
                            }
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        bank_account: e.target.value,
                                      }
                                    : { ...v }
                                )
                              )
                            }
                            value={val.bank_account}
                          />
                        }
                      </td> */}
                      <td>
                        {
                          <Input
                            placeholder={"TCS/TDS"}
                            value={val?.tcs_tds}
                            disabled={true}
                          />
                        }
                      </td>

                      <td>
                        {
                          <Input
                            value={val?.total_amount}
                            placeholder={"Sale Amount"}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.narration}
                            placeholder={"Narration"}
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        narration: e.target.value,
                                      }
                                    : { ...v }
                                )
                              )
                            }
                          />
                        }
                      </td>
                      <td>
                        {
                          <DeleteIcon
                            onClick={() => {
                              setAddData((pre) =>
                                pre.filter((v, i) => i != ind)
                              );
                              setAddDataHead({
                                ...addDataHead,
                                inv_amount: "",
                              });
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <Button
                  title={"ADD"}
                  onClick={() => {
                    validation_check() &&
                      setMess(
                        "Please enter all the values in current row,calculate and then add next row"
                      );
                    validation_check()
                      ? setOpen1(true)
                      : setAddData((pre) => [...pre, {}]);
                  }}
                />
                <Button
                  title={"FETCH INVOICE"}
                  onClick={fetch_invoice}
                  disable={
                    !addDataHead?.inv_date ||
                    (addDataHead?.inv_date &&
                      addDataHead?.inv_date?.length != 10)
                  }
                />
                <Button title={"FETCH PRICE"} back onClick={fetch_gst} />
                <Button title={"CALCULATE"} back onClick={() => calculate()} />
                <Button
                  title={"PREVIEW DATA AND SAVE"}
                  back
                  onClick={() => preview_inv("first")}
                />
              </div>
            </div>
          }
        ></Modal>
      )}

      {showPreview && (
        <Modal
          isOpen={showPreview}
          setIsOpen={setShowPreview}
          title={
            (OMC == 1 && "NFR SALES - PREVIEW") ||
            (OMC == 2 && "ARB SALES - PREVIEW") ||
            (OMC == 3 && "BLPG SALES - PREVIEW")
          }
          body={
            <div>
              <TaxInvoice3
                view={true}
                empty_row_height={180 - addData?.length * 25}
                listdata={{
                  SR_NO: addDataHead?.SR_NO,
                  SR_NO_DATE: addDataHead?.inv_date,
                  REF_NUMBER: addDataHead?.PO_NUMBER,
                  REF_DATE: addDataHead?.PO_DATE,
                  CVO_NAME: addDataHead?.customer_name,
                  CVO_ADDRESS: addDataHead?.address,
                  CVO_DISTRICT: addDataHead?.district,
                  CVO_LOCATION: addDataHead?.location,
                  CVO_PINCODE: viewItem?.pincode,
                  CVO_TIN: addDataHead?.gst_num || "NA",
                  B_STATE_CODE: addDataHead?.state_code,
                  B_STATE_NAME: addDataHead?.state_name,
                  SHIP_CVO_NAME:
                    addDataHead?.ship_name || addDataHead?.customer_name,
                  SHIP_CVO_ADDRESS:
                    addDataHead?.ship_address || addDataHead?.address,
                  SHIP_CVO_DISTRICT: viewItem?.ship_location,
                  SHIP_CVO_LOCATION: viewItem?.ship_district,
                  SHIP_B_STATE: addDataHead?.ship_state_code,
                  SHIP_B_STATE_NAME: addDataHead?.ship_state_name,
                  SHIP_CVO_PINCODE: viewItem?.ship_pincode,
                  SHIP_CVO_TIN: addDataHead?.gst_num || "NA",
                  MOBILE: addDataHead?.mobile,
                  vehicle_num: addDataHead?.vahicle_number,
                }}
                table_body={
                  <>
                    {addData?.map((v, i) => (
                      <tr key={i} className="both">
                        <td className="both">{i + 1}</td>
                        <td
                          className="both"
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            minWidth: "280px",
                          }}
                        >
                          {(() => {
                            const data = arb_list?.filter(
                              (d) => d?.ID == v?.product_code
                            );
                            if (data?.length > 0) {
                              return `${data[0]?.category_description} - ${data[0]?.PROD_BRAND}-${data[0]?.PROD_NAME}`;
                            } else {
                              return "";
                            }
                          })()}
                        </td>
                        <td className="both">{v?.product_code || "NA"}</td>
                        <td className="both">
                          {Number(v?.quantity)} {UnitFunction(v?.UOM, "title")}
                        </td>
                        <td className="both">{v?.unit_rate}</td>
                        <td className="both">{v?.discount_price}</td>
                        <td className="both">{v?.p_gst}</td>
                        <td className="both" style={{ textAlign: "right" }}>
                          {v?.basic_amount}
                        </td>
                      </tr>
                    ))}
                  </>
                }
                net_total={Number(addDataHead?.inv_amount).toFixed(2)}
                taxable_amt={ReduceFunction(addData, "basic_amount")}
                Total_tax={(
                  Number(ReduceFunction(addData, "igst_amount")) +
                  Number(ReduceFunction(addData, "sgst_amount")) +
                  Number(ReduceFunction(addData, "cgst_amount"))
                ).toFixed(2)}
                igst={ReduceFunction(addData, "igst_amount")}
                cgst={ReduceFunction(addData, "cgst_amount")}
                sgst={ReduceFunction(addData, "sgst_amount")}
                other={`${addDataHead?.type == "TCS" ? "-" : ""} ${Number(
                  ReduceFunction(addData, "tcs_tds")
                ).toFixed(2)}`}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: "1rem",
                  padding: "0 5% 0 5%",
                }}
              >
                <div
                  style={{
                    width: "500px",
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    title={"CANCEL"}
                    onClick={() => {
                      setShowPreview(false);
                    }}
                  />
                  <SaveButton onClick={add_items} />
                </div>
              </div>
            </div>
          }
        />
      )}

      {cvoClick && <CVOList cvoClick={cvoClick} setCvoClick={setCvoClick} />}
      {StaffClick && (
        <StaffList StaffClick={StaffClick} setStaffClick={setStaffClick} />
      )}
      {PriceClick && (
        <PriceList PriceClick={PriceClick} setPriceClick={setPriceClick} />
      )}
      {arbClick && <ARBList arbClick={arbClick} setArbClick={setArbClick} />}
      {openView && (
        <Modal
          header_right={
            <ReactToPrint
              trigger={() => (
                <Button style={{ cursor: "pointer" }} title={"PRINT"} />
              )}
              content={() => previewRef.current}
              documentTitle={`${viewItem?.CUSTOMER}-${viewItem?.SR_NO}`}
            />
          }
          onCancel={() => {
            setViewItem({});
          }}
          isOpen={openView}
          title={viewItem?.CUSTOMER || "NA"}
          setIsOpen={setOpenView}
          body={
            <>
               {/* {viewItem?.CUSTOMER_STATUS == "2" && (
                <>
                  <div className="rowtxt">
                    <div className="card-label card-label-width" style={{maxWidth:'100px',minWidth:'100px'}} >GSTIN No.</div>
                    <div className="card-label">:</div>
                    <div className="card-title" style={{ textWrap: "wrap" }}>
                      {viewItem?.GST_NO}
                    </div>
                  </div>
                  <div className="rowtxt">
                    <div className="card-label card-label-width" style={{maxWidth:'100px',minWidth:'100px'}}>Address</div>
                    <div className="card-label">:</div>
                    <div className="card-title" style={{ textWrap: "wrap" }}>
                      {viewItem?.CVO_ADDRESS}
                    </div>
                  </div>
                </>
              )} */}
              {viewItem?.CUSTOMER_STATUS == "2" && (
                <>
                  {viewItem?.GST_NO && (
                    <div className="rowtxt">
                      <div
                        className="card-label "
                        style={{ maxWidth: "100px", minWidth: "100px" }}
                      >
                        GSTIN No.
                      </div>
                      <div className="card-label">:</div>
                      <div className="card-title" style={{ textWrap: "wrap" }}>
                        {viewItem?.GST_NO}
                      </div>
                    </div>
                  )}
                  <div className="rowtxt">
                    <div
                      className="card-label "
                      style={{ maxWidth: "100px", minWidth: "100px" }}
                    >
                      Address
                    </div>
                    <div className="card-label">:</div>
                    <div className="card-title" style={{ textWrap: "wrap" }}>
                      {viewItem?.CVO_ADDRESS}
                    </div>
                  </div>
                </>
              )}

              <Table
                mt={10}
                headData={[
                  "PRODUCT",
                  "UNIT PRICE",
                  "GST %",
                  "DISCOUNT ON UNIT PRICE	",
                  "QUANTITY",
                  "TAXABLE AMOUNT",
                  "TDS/TCS",
                  "IGST AMOUNT	",
                  "CGST AMOUNT	",
                  "SGST AMOUNT	",
                  "TOTAL AMOUNT	",
                ]}
                body={
                  <>
                    {viewItem?.products?.map((v, i) => (
                      <tr key={i}>
                        <td>{`${v?.CAT_DESC}-${v?.PROD_BRAND}-${v?.PROD_NAME}`}</td>
                        <td>{v?.UNIT_RATE}</td>
                        <td>{v?.GSTP}</td>
                        <td>{v?.DISC_UNIT_RATE}</td>
                        <td>{v?.QUANTITY}</td>
                        <td>{v?.TAX_AMOUNT}</td>
                        <td>
                          {viewItem?.TDS_STATUS == "2" ? "-" : ""} {v?.TDS}
                        </td>
                        <td>{v?.IGST_AMOUNT}</td>
                        <td>{v?.CGST_AMOUNT}</td>
                        <td>{v?.SGST_AMOUNT}</td>
                        <td>{v?.SALE_AMOUNT}</td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        colSpan={5}
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        TOTAL
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v?.TAX_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.TDS)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.IGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.SGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.CGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {Number(viewItem?.SI_AMOUNT)?.toFixed(2)}
                      </td>
                    </tr>
                  </>
                }
              />
            </>
          }
        ></Modal>
      )}

      {open2 && (
        <SmallModal
          title={"Confirm"}
          isOpen={open2}
          setIsOpen={setOpen2}
          mess={"Are you sure? You want to Change financial Status ?"}
          bname={"SUBMIT"}
          onClick={() => {
            setAddDataHead({ ...addDataHead, type: open2data });
            setOpen2(false);
            setOpen2data("");
            addData.forEach((data, index) => {
              setAddData((prevData) => {
                return prevData.map((item, i) => {
                  if (i === index) {
                    return {
                      ...item,
                      tax_amount: "",
                      tcs_tds: "",
                    };
                  }
                  return item;
                });
              });
            });
          }}
        />
      )}

      <div style={{ display: "none" }}>
        <TaxInvoice3
          empty_row_height={180 - viewItem?.products?.length * 25}
          previewRef={previewRef}
          listdata={{
            SR_NO: viewItem?.SR_NO,
            SR_NO_DATE: viewItem?.INVOICE_DATE,
            REF_NUMBER: viewItem?.PO_NUMBER,
            REF_DATE: viewItem?.SI_DATE,
            CVO_NAME: viewItem?.CUSTOMER || viewItem?.CUSTOMER_NAME,
            CVO_ADDRESS: viewItem?.CVO_ADDRESS || viewItem?.BILLING_ADDRESS,
            CVO_DISTRICT: viewItem?.BILLING_DISTRICT,
            CVO_LOCATION: viewItem?.BILLING_LOCATION,
            B_STATE_CODE: viewItem?.BILLING_STATE_CODE,
            B_STATE_NAME: viewItem?.BILLING_STATE_NAME,
            CVO_PINCODE: viewItem?.BILLING_PINCODE,
            CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
            EMAIL: viewItem?.CVO_EMAIL,
            MOBILE: viewItem?.CVO_CONTACT,
            SHIP_CVO_NAME: viewItem?.CUSTOMER || viewItem?.CUSTOMER_NAME,
            SHIP_CVO_ADDRESS:
              viewItem?.SHIPPING_ADDRESS || viewItem?.CVO_ADDRESS,
            SHIP_CVO_DISTRICT: viewItem?.SHIPPING_DISTRICT,
            SHIP_CVO_LOCATION: viewItem?.SHIPPING_LOCATION,
            SHIP_B_STATE: viewItem?.SHIPPING_STATE,
            SHIP_B_STATE_NAME: viewItem?.SHIPPING_STATE_NAME,
            SHIP_CVO_PINCODE: viewItem?.SHIPPING_PINCODE,
            SHIP_CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
            vehicle_num: viewItem?.VEHICLE_NO,
            pay_terms: viewItem?.PAYMENT_TERMS == "1" ? "CASH" : "CREDIT",
          }}
          table_body={
            <>
              {viewItem?.products?.map((v, i) => (
                <tr key={i}>
                  <td className="both">{i + 1}</td>
                  <td
                    className="both"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      minWidth: "230px",
                    }}
                  >
                    {`${v?.CAT_DESC}-${v?.PROD_BRAND}-${v?.PROD_NAME}`}
                  </td>

                  <td className="both">{v?.CSTEH_NO || "NA"}</td>
                  <td className="both">
                    {v?.QUANTITY} {UnitFunction(v?.UNITS || v?.UOM, "title")}
                  </td>
                  <td className="both">{v?.UNIT_RATE}</td>
                  <td className="both">{v?.DISC_UNIT_RATE}</td>
                  <td className="both">{v?.GSTP}</td>
                  <td className="both" style={{ textAlign: "right" }}>
                    {v?.TAX_AMOUNT}
                  </td>
                </tr>
              ))}
            </>
          }
          Total_tax={viewItem?.hsn_groups
            ?.map((v) => v?.TOTAL_AMOUNT)
            ?.reduce((a, b) => Number(a) + Number(b), 0)
            ?.toFixed(2)}
          taxable_amt={ReduceFunction(viewItem?.products, "TAX_AMOUNT")}
          igst={ReduceFunction(viewItem?.products, "IGST_AMOUNT")}
          cgst={ReduceFunction(viewItem?.products, "CGST_AMOUNT")}
          sgst={ReduceFunction(viewItem?.products, "SGST_AMOUNT")}
          other={`${viewItem?.TDS_STATUS == "2" ? "-" : ""} ${Number(
            ReduceFunction(viewItem?.products, "TDS")
          ).toFixed(2)}`}
          net_total={Number(viewItem?.SI_AMOUNT).toFixed(2)}
        />
      </div>

      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => onDelete_item()}
        />
      )}
      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {open4 && (
        <SmallModal
          bname={"Yes"}
          cname={"No"}
          title={"Confirm"}
          isOpen={open4}
          setIsOpen={setOpen4}
          mess={mess}
          onClick={() => {
            preview_inv();
          }}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};
