import React, { useEffect, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import { FaTrashCan } from "react-icons/fa6";
import Input from "../../../components/inputs/Input";
import Dropdown from "../../../components/inputs/Dropdown";
import store from "../../../redux/store";
import { get_equipment_list } from "../masterdataSlice";
import { useSelector } from "react-redux";
import { DeleteIcon, base_url } from "../../../utils/utils";
import Loader from "../../../components/Loader";
import PageLoading from "../../../components/PageLoading";
import Table from "../../../components/table/Table";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import SmallModal from "../../../components/modal/SmallModal";
import { equipments_action } from "../../../utils/apis";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { DateValidator2 } from "../../../utils/validation";
import { toast } from "react-toastify";
import TableNew from "../../../components/table/TableWithPagination";
import NoData from "../../../components/NoData";
import { Pagination2 } from "../../../components/pagination/pagination";
import { Alerts } from "../../../components/Alerts/Alerts";

const EquipmentMaster = () => {
  const [addData, setAddData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [ID, setID] = useState("");
  const [SerchInput, setSearchInput] = useState("");
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  const { godown_address_list } = useSelector((state) => state.profile);
  const { eff_date, emp_id, user_id, today, user_type } = useSelector(
    (state) => state.other
  );

  const headData = [
    "GO-DOWN",
    "PRODUCT",
    "UNITS",
    "GST %",
    "HSN CODE",
    "SECURITY DEPOSIT",
    "OPENING FULLS",
    "OPENING EMPTIES",
    "EFFECTIVE DATE",
    "ACTIONS",
  ];
  const navigate = useNavigate();
  const { equipment_loading, equipment_list, master_complete_list } =
    useSelector((state) => state.masterdata);

  useEffect(() => {
    store.dispatch(get_equipment_list(obj));
  }, [pageNo, entries]);

  const validation_check = () => {
    if (
      addData.find(
        (v) =>
          !v.product ||
          (v.product && v.product.length == 0) ||
          !v.units ||
          (v.units && v.units.length == 0) ||
          // !v.gst ||
          // (v.gst && v.gst.length == 0) ||
          v?.gst === undefined ||
          v?.gst === null ||
          v?.gst < 0 ||
          !v.hsn ||
          (v.hsn && v.hsn.length == 0) ||
          !v.security ||
          (v.security && v.security.length == 0) ||
          !v.fulls ||
          (v.fulls && v.fulls.length == 0) ||
          v.emptys.length == 0 ||
          !v.date ||
          (v.date && v.date.length == 0) ||
          (godown_address_list?.length > 1 &&
            (!v.godown || (v.godown && v.godown.length == 0)))
      )
    ) {
      return true;
    }
  };

  const valiDate = () => {
    const data = [];
    if (addData?.length > 4) {
      data.push("We can add only four product at the single time");
    } else {
      for (let v of addData) {
        const dateData = DateValidator2(
          moment(v?.date).format("DD/MM/YYYY"),
          today,
          eff_date,
          `Effective Date cant be before ${eff_date} `,
          `Effective Date cant be a future date`
        );
        if (!v.product || (v.product && v.product.length == 0)) {
          data.push("Please Select the Equipment from the Drop-down");
        }
        if (!v.units || (v.units && v.units.length == 0)) {
          data.push("Please Select UNITS");
        }
        if (v.gst === undefined || v.gst === null || v.gst === "") {
          data.push("Please Select GST%");
        }
        if (!v.hsn || (v.hsn && v.hsn.length == 0)) {
          data.push("Please Enter HSN CODE");
        }
        if (!v.fulls || v.fulls.length == 0) {
          data.push("Please Enter FULLS ");
        }
        if (v.emptys.length == 0) {
          data.push("Please Enter EMPTIES if not Enter 0.");
        }
        if (!v.date || (v.date && v.date.length != 10)) {
          data.push("Please Enter EFFECTIVE DATE");
        } else if (dateData != true) {
          data.push(...dateData);
        }
        if (godown_address_list?.length == 0) {
          data.push("First Add Godown after Try");
        } else if (
          godown_address_list?.length > 1 &&
          (!v.godown || (v.godown && v.godown.length == 0))
        ) {
          data.push("Please Select Godown Address");
        }
      }
    }
    return data;
  };

  const add_items = async () => {
    const data = [...new Set(valiDate())];
    if (data.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        if (emp_id) {
          body.append("emp_code", emp_id);
        }
        addData.forEach((v) => {
          body.append("productcode[]", v.product);
          body.append("unit[]", v.units);
          body.append("gst[]", v.gst);
          body.append("hsn[]", v.hsn);
          body.append("security[]", v.security);
          body.append("fulls[]", v.fulls);
          body.append("emptys[]", v.emptys);
          body.append("effectivedate[]", v.date);
          body.append(
            "godown_id[]",
            v.godown || godown_address_list?.[0]?.ID || 0
          );
        });
        const responce = await fetch(base_url + "equipment_master_api.php", {
          method: "POST",
          body: body,
        });
        const res = await responce.json();
        setLoading(false);
        if (res.success) {
          store.dispatch(get_equipment_list(obj));
          setAddData([]);
          toast(res.message);
        } else {
          setOpen(true);
          setMess(res.message);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const deleteItem = async () => {
    setOpen1(false);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    body.append("id", ID);
    if (emp_id) {
      body.append("emp_code", emp_id);
    }
    const res = await equipments_action(body, setLoading);
    if (res.success) {
      toast(res.message);
      // store.dispatch(get_equipment_list(obj));
      if (equipment_list?.length < 2) {
        setPageNo(pageNo - 1);
        store.dispatch(
          get_equipment_list({
            pageNo: pageNo - 1,
            entries: entries,
            setTotalCount: setTotalCount,
          })
        );
      } else {
        store.dispatch(get_equipment_list(obj));
      }
    } else if (!res.success) {
      toast(res.message);
    }
  };

  const fetch_data = async (pid, godown, ind) => {
    try {
      const body = new FormData();
      body.append("action", "fetch");
      body.append("agencyid", user_id);

      body.append("pid", pid);
      body.append("godown_id", godown || godown_address_list?.[0]?.ID);
      if (emp_id) {
        body.append("emp_code", emp_id);
      }

      const responce = await fetch(base_url + "equipment_master_api.php", {
        method: "POST",
        body: body,
      });
      const res = await responce.json();
      if (res.success) {
        // const data = master_complete_list?.equipment?.filter(
        //   (v) => v?.ID == pid
        // );
        // const newData =
        //   data?.length > 0 && parseInt(data[0]?.CAPACITY) > 0 ? true : false;
        setAddData((pre) =>
          pre.map((v, i) =>
            i == ind
              ? {
                  ...v,
                  fetch_data: true,
                  product: pid,
                  gst: res?.GSTP,
                  units: 1,
                  units_disable: true,
                  // units: res?.UNITS
                  //   ? res?.UNITS
                  //   : newData &&
                  //     Number(data[0]?.CAPACITY) &&
                  //     Number(data[0]?.CAPACITY) < 19
                  //   ? "1"
                  //   : data[0]?.CAT_NAME == "REGULATOR"
                  //   ? "1"
                  //   : "",
                  // units_disable: res?.UNITS
                  //   ? true
                  //   : newData &&
                  //     Number(data[0]?.CAPACITY) &&
                  //     Number(data[0]?.CAPACITY) < 19
                  //   ? true
                  //   : data[0]?.CAT_NAME == "REGULATOR"
                  //   ? true
                  //   : false,
                  hsn: res?.CSTEH_NO,
                  security: res?.SECURITY_DEPOSIT,
                  fetch_data_fulls: res?.FULLS ? true : false,
                  fulls: res?.FULLS || 0,
                  emptys: res?.EMPTIES || 0,
                  date:
                    res?.EFFECTIVE_DATE != ""
                      ? moment(res?.EFFECTIVE_DATE).format("YYYY-MM-DD")
                      : "",
                }
              : { ...v }
          )
        );
      } else if (!res.success) {
        setOpen(true);
        setMess(res.message);
        setAddData((pre) =>
          pre.map((v, i) =>
            i == ind
              ? {
                  ...v,
                  fetch_data: false,
                  product: pid,
                  gst: "",
                  units: 1,
                  units_disable: true,
                  hsn: "",
                  security: "",
                  fulls: "",
                  emptys: "",
                  date: "",
                }
              : { ...v }
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      {loading && <Loader loading={loading} />}
      <PageHeading
        title={"EQUIPMENT MASTER"}
        right={
          <>
            <AddButton
              onClick={() => {
                console.log("check ====", validation_check());
                console.log("addData ====", addData);
                if (validation_check()) {
                  setMess(
                    "Please enter all the values in current row and then add next row"
                  );
                  setOpen(true);
                  return;
                }
                setAddData((pre) => [
                  ...pre,
                  {
                    security: "0.00",
                    product: "",
                    units: "",
                    gst: "",
                    hsn: "",
                    fulls: "",
                    emptys: "",
                    date: "",
                  },
                ]);
              }}
            />

            {addData.length > 0 && <SaveButton onClick={() => add_items()} />}
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/_vkck33H-zE",
                "https://youtu.be/0iV0CiU67xc",
              ]}
            />
          </>
        }
      />
      {addData?.length > 0 && (
        <div>
          <Table
            headData={
              godown_address_list?.length <= 1
                ? headData?.filter((v) => v != "GO-DOWN")
                : headData
            }
            body={addData.map((val, ind) => (
              <tr key={ind}>
                {godown_address_list?.length > 1 && (
                  <td>
                    <Dropdown
                      value={addData?.godown}
                      onChange={(e) => {
                        setAddData((pre) =>
                          pre.map((v, i) =>
                            i == ind
                              ? { ...v, godown: e.target.value }
                              : { ...v }
                          )
                        );
                        if (
                          val?.product &&
                          val?.product?.length > 0 &&
                          e.target.value != ""
                        ) {
                          fetch_data(val?.product, e.target.value, ind);
                        }
                      }}
                      options={
                        <>
                          <option value={""}>SELECT</option>
                          {godown_address_list?.map((s, i) => (
                            <option key={i} value={s?.ID}>
                              {s?.UNIT_NAME}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </td>
                )}

                <td>
                  <Dropdown
                    value={val?.product}
                    onChange={async (e) => {
                      const inputValue = e.target.value;
                      if (
                        e?.target.value != "" &&
                        (godown_address_list?.length <= 1 ||
                          (val?.godown && val?.godown.length > 0))
                      ) {
                        fetch_data(
                          inputValue,
                          val?.godown || godown_address_list?.[0]?.ID,
                          ind
                        );
                      } else {
                        setAddData((pre) =>
                          pre.map((v, i) =>
                            i == ind
                              ? {
                                  ...v,
                                  product: e.target.value,
                                }
                              : { ...v }
                          )
                        );
                      }
                    }}
                    options={[
                      <option disabled={val.product != ""} value={""}>
                        {"SELECT"}
                      </option>,
                    ].concat(
                      master_complete_list?.equipment?.map((v, i) => (
                        <option
                          key={i}
                          disabled={
                            addData.filter((item) => item.product == v.ID)
                              .length > 0
                              ? true
                              : false
                          }
                          value={v.ID}
                        >
                          {v.CAT_NAME + " - " + v.CAT_DESC}
                        </option>
                      ))
                    )}
                  />
                </td>
                <td>
                  <Dropdown
                    placeholder={"UNIT"}
                    disabled={val?.units_disable}
                    value={val?.units}
                    options={
                      <>
                        <option value={""}>SELECT</option>
                        <option value={"1"}>NOS</option>
                        {/* <option value={"2"}>KGS</option> */}
                      </>
                    }
                    onChange={(e) => {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind
                            ? {
                                ...v,
                                units: e.target.value,
                              }
                            : { ...v }
                        )
                      );
                    }}
                  />
                </td>
                <td>
                  <Input placeholder={"GST"} value={val.gst} disabled={true} />
                </td>
                <td>
                  <Input
                    placeholder={"HSN CODE"}
                    value={val.hsn}
                    disabled={true}
                  />
                </td>
                <td>
                  <Input
                    placeholder={"SECURITY DEPOSIT"}
                    value={val.security}
                    disabled={true}
                  />
                </td>
                <td>
                  <Input
                    placeholder={"FULLS"}
                    // disabled={val?.fetch_data}
                    disabled={val?.fetch_data_fulls}
                    value={val.fulls}
                    onChange={(e) => {
                      if (e.target.value?.length < 5) {
                        setAddData((pre) =>
                          pre.map((v, i) =>
                            i === ind
                              ? {
                                  ...v,
                                  fulls: /^\d+$/.test(e.target.value)
                                    ? Number(e.target.value)
                                    : "",
                                }
                              : { ...v }
                          )
                        );
                      }
                    }}
                  />
                </td>
                <td>
                  <Input
                    placeholder={"EMPTIES"}
                    value={val.emptys}
                    disabled={
                      master_complete_list?.equipment?.filter(
                        (v) => v?.ID == val?.product
                      )[0]?.CAT_NAME == "REGULATOR" || val?.fetch_data_fulls
                    }
                    onChange={(e) => {
                      if (e.target.value?.length < 5) {
                        setAddData((pre) =>
                          pre.map((v, i) =>
                            i == ind
                              ? {
                                  ...v,
                                  emptys: /^\d+$/.test(e.target.value)
                                    ? Number(e.target.value)
                                    : "",
                                }
                              : { ...v }
                          )
                        );
                      }
                    }}
                  />
                </td>
                <td>
                  <Input
                    type="date"
                    value={val.date}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setAddData((pre) =>
                          pre.map((v, i) =>
                            i == ind ? { ...v, date: e.target.value } : { ...v }
                          )
                        );
                      }
                    }}
                  />
                </td>

                <td>
                  <DeleteIcon
                    onClick={() => {
                      // if (addData?.length == 1) {
                      //   store.dispatch(setSidebarWidth(false));
                      // }
                      setAddData((pre) => pre.filter((v, i) => i != ind));
                    }}
                  />
                </td>
              </tr>
            ))}
          />
        </div>
      )}
      {totalCount > 5 && (
        <Pagination2
          top={true}
          search={false}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {equipment_loading ? (
        <PageLoading />
      ) : equipment_list?.length == 0 ? (
        <NoData mt={30} />
      ) : (
        <TableNew
          mt={10}
          totalCount={totalCount}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
          setSearchInput={setSearchInput}
          SerchInput={SerchInput}
          nodata={equipment_list?.length == 0 ? true : false}
          headData={[
            "GO-DOWN",
            "PRODUCT",
            "UNITS",
            "GST %",
            "HSN CODE",
            "SECURITY DEPOSIT",
            "OPENING FULLS",
            "OPENING EMPTIES",
            "CURRENT FULLS",
            "CURRENT EMPTIES",
            "EFFECTIVE DATE",
            "CREATED BY",
            "ACTIONS",
          ]}
          body={equipment_list.map((v, i) => (
            <tr key={i}>
              <td>{v?.UNIT_NAME || "-"}</td>
              <td>
                {v?.CAT_NAME} - {v.product_name}
              </td>
              <td>{v?.UNITS == 1 ? "NOS" : "KGS"}</td>
              <td>{v?.GSTP}</td>
              <td>{v?.CSTEH_NO}</td>
              <td>{v?.SECURITY_DEPOSIT}</td>
              <td>{v?.OS_FULLS}</td>
              <td>{v?.OS_EMPTYS}</td>
              <td>{v?.CS_FULLS}</td>
              <td>{v?.CS_EMPTYS}</td>
              <td>{moment(v.formatted_date).format("DD-MMM-YYYY")}</td>
              <td>{v?.CREATED_NAME || "-"}</td>
              <td>
                {user_type == "admin" || v?.EMPLOYEE_CODE == emp_id ? (
                  <FaTrashCan
                    color="#f00"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setID(v.ID);

                      setOpen1(true);
                    }}
                  />
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))}
        />
      )}
      {totalCount > 5 && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}

      {open1 && (
        <SmallModal
          onClick={() => deleteItem()}
          title={"Alerts"}
          mess={"Are you sure? You want to delete"}
          isOpen={open1}
          setIsOpen={setOpen1}
        />
      )}

      {open && (
        <SmallModal
          okbtn
          title={"Alerts"}
          mess={mess}
          isOpen={open}
          setIsOpen={setOpen}
        />
      )}
    </div>
  );
};

export default EquipmentMaster;
