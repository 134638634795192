import React, { useEffect, useMemo, useState } from "react";
import { BackButton } from "../../components/btn/Buttons";
import PageHeading from "../../components/PageHeading";
import { Input } from "../../components/inputs/Inputs";
import Dropdown from "../../components/inputs/Dropdown";
import Button from "../../components/btn/Button";
import { useSelector } from "react-redux";
import Table from "../../components/table/Table";
import store from "../../redux/store";
import { get_purchase_report_list } from "./transactionReportSlice";
import { DateValidator2, DateValidatorReports } from "../../utils/validation";
import moment from "moment";
import SmallModal from "../../components/modal/SmallModal";
import Loader from "../../components/Loader";
import {
  bank_report_api,
  bank_report_api2,
  purchase_report_api,
} from "../../utils/apis";
import {
  Pagination2,
  Pagination3,
} from "../../components/pagination/pagination";
import NoData from "../../components/NoData";
import { BankCodeFinder, downloadExcel, exportPDF } from "../../utils/utils";
import { get_bank_master_list } from "../MasterData/masterdataSlice";
import { Form } from "react-router-dom";
import { Alerts } from "../../components/Alerts/Alerts";
import SearchApiHook from "../../CustomHooks/SearchApiHook";

const BankBook = () => {
  const [addData, setAddData] = useState({});
  const [addDataPre, setAddDataPre] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [totalCount, setTotalCount] = useState("50");
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [serchInput, setSerchInput] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [showData, setShowData] = useState(false);
  const [openingBal, setOpeningBal] = useState();
  const [purchase_report_item_list, setPurchase_report_item_list] = useState(
    []
  );
  const [totalData, setTotalData] = useState({});
  const { bank_master_list } = useSelector((state) => state.masterdata);
  const { firm_name } = useSelector((state) => state.profile);
  const { day_end_date } = useSelector((state) => state.profile);
  const { eff_date, user_id, today } = useSelector((state) => state.other);

  useEffect(() => {
    store.dispatch(get_bank_master_list());
  }, []);

  // useEffect(() => {
  //   if (showData) {
  //     get_list(addDataPre);
  //   }
  // }, [pageNo, entries]);
  const fetchData = () => {
    if (showData) {
      get_list(addDataPre);
    }
  };
  SearchApiHook(fetchData, serchInput?.trim(), prevSearch, [entries, pageNo]);

  const table_data = (addData, total) => {
    const data = [
      ...addData?.map((v, i) => [
        i + 1,
        v?.SR_NO,
        // moment(v?.BT_DATE).format("DD/MM/YYYY"),
        v?.BT_DATE,
        v?.TRANSACTION_MODULE,
        v?.TRANSACTION_DETAILS,
        v?.DEBIT,
        v?.CREDIT || "NA",
        v?.TRANS_CHARGES,
        v?.CLOSING_BALANCE,
        v?.CREATED_NAME,
      ]),
    ];

    return data;
  };

  const table_header = [
    "VOCHER/TRANSACTION NO",
    "DATE",
    "TRANSACTION MODULE",
    "TRANSACTION DETAILS",
    "DEBIT",
    "CREDIT",
    "CHARGES",
    "BALANCE",
    "CREATED BY",
  ];
  const export_pdf = (addData, data, total) => {
    exportPDF({
      table_data: table_data(data, total),
      table_headers: ["S.No.", ...table_header],
      fileName: `${firm_name} - BANK BOOK -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: BANK SELECTED : : ${addData?.custProdDetails}`,
      tableName: `${firm_name} - BANK BOOK -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: BANK SELECTED : : ${addData?.custProdDetails}`,
    });
  };

  const export_excel = (addData, data, total) => {
    downloadExcel(
      table_data(data, total),
      ["S.No.", ...table_header],
      `${firm_name} - BANK BOOK -- FROM DATE : ${moment(
        addData?.from_date
      ).format("DD/MM/YY")} & TO DATE : ${moment(addData?.to_date).format(
        "DD/MM/YY"
      )}   :: BANK SELECTED : : ${addData?.custProdDetails}`
    );
  };

  const get_list = async (addData, data1 = "") => {
    const data = validate(addData);
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "fetchbankreport");
        body.append("agencyid", user_id);
        body.append("fd", addData?.from_date);
        body.append("td", addData?.to_date);
        // body.append("page", data1?.length > 1 ? 1 : pageNo);
        // body.append("limit", data1?.length > 1 ? totalCount : entries);
        body.append("page", data1?.length > 1 || data1 == "main" ? 1 : pageNo);
        body.append(
          "limit",
          data1?.length > 1 ? (data1 == "main" ? entries : totalCount) : entries
        );
        body.append("search", data1 == "main" ? "" : serchInput);
        body.append("bid", addData?.bank_id);
        const response = await bank_report_api2(body);
        setShowLoader(false);
        if (response.success) {
          if (data1 == "excel") {
            export_excel(
              addData,
              response?.data || response?.list,
              response?.total
            );
          } else if (data1 == "pdf") {
            export_pdf(
              addData,
              response?.data || response?.list,
              response?.total
            );
          } else {
            setOpeningBal(response?.openingBal);
            setPurchase_report_item_list(response?.list);
            setTotalCount(response?.totalcount);
            setSearchCount(response?.searchcount);
            setShowData(true);
            setAddDataPre(addData);
            setTotalData(response?.total);
          }
        } else if (!response.success) {
          setMess(response?.message);
          setOpen(true);
        }

        // const response = await bank_report_api(body);
        // setShowLoader(false);
        // if (response.success) {
        //   if (data1 == "excel") {
        //     export_excel(
        //       addData,
        //       response?.data || response?.productlist,
        //       response?.total
        //     );
        //   } else if (data1 == "pdf") {
        //     export_pdf(
        //       addData,
        //       response?.data || response?.productlist,
        //       response?.total
        //     );
        //   } else {
        //     setPurchase_report_item_list(
        //       response?.data || response?.productlist
        //     );
        //     setTotalCount(response?.totalcount);
        //     setShowData(true);
        //     setAddDataPre(addData);
        //     setTotalData(response?.total);
        //   }
        // } else if (!response.success) {
        //   setMess(response?.message);
        //   setOpen(true);
        // }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const validate = (addData) => {
    const data = [];
    // if (
    //   !addData?.from_date ||
    //   (addData?.from_date && addData?.from_date?.length != 10)
    // ) {
    //   data.push("Please Enter From Date");
    // } else if (
    //   DateValidator2(
    //     moment(addData?.from_date).format("DD/MM/YYYY"),
    //     today,
    //     day_end_date
    //   ) != true
    // ) {
    //   data.push(
    //     ...DateValidator2(
    //       moment(addData?.from_date).format("DD/MM/YYYY"),
    //       today,
    //       day_end_date,
    //       `From Date Date cant be before ${day_end_date} `,
    //       `From Date Date cant be a future date`
    //     )
    //   );
    // }
    // if (
    //   !addData?.to_date ||
    //   (addData?.to_date && addData?.to_date?.length != 10)
    // ) {
    //   data.push("Please Enter TO DATE");
    // } else if (
    //   DateValidator2(
    //     moment(addData?.to_date).format("DD/MM/YYYY"),
    //     today,
    //     addData?.from_date
    //   ) != true
    // ) {
    //   data.push(
    //     ...DateValidator2(
    //       moment(addData?.to_date).format("DD/MM/YYYY"),
    //       today,
    //       addData?.from_date,
    //       `To Date cant be before ${moment(addData?.from_date).format(
    //         "DD/MM/YYYY"
    //       )} `,
    //       `To Date cant be a future date`
    //     )
    //   );
    // }
    const DateValidatorReportsData = DateValidatorReports(
      moment(addData?.from_date).format("DD/MM/YYYY"),
      moment(addData?.to_date).format("DD/MM/YYYY")
    );
    if (
      !addData?.from_date ||
      (addData?.from_date && addData?.from_date?.length != 10)
    ) {
      data.push("Please Enter From Date");
    } else if (
      !addData?.to_date ||
      (addData?.to_date && addData?.to_date?.length != 10)
    ) {
      data.push("Please Enter TO DATE");
    } else if (DateValidatorReportsData != true) {
      data.push(...DateValidatorReportsData);
    }
    if (
      !addData?.bank_id ||
      (addData?.bank_id && addData?.bank_id?.length == 0)
    ) {
      data.push("Please Select Bank Account");
    }
    return data;
  };
  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);

  return (
    <div className="container ">
      <PageHeading title={"BANK BOOK"} right={<BackButton />} />
      <div className="transactionMain">
        <div style={{ width: "220px" }}>
          <Dropdown
            label={"BANK"}
            value={addData?.product_code}
            onChange={(e) => {
              const data = bank_master_list?.filter(
                (v) => v?.ID == e.target.value
              )?.[0];
              setAddData({
                ...addData,
                bank_id: e.target.value,
                action: e.target.value != "" ? "product" : "",
                custProdDetails: data
                  ? ` ${data?.BANK_CODE} -
                  ${data?.BANK_ACC_NO == "NA" ? user_id : data?.BANK_ACC_NO}`
                  : "",
                OB: data ? data?.ACC_OB : "",
              });
            }}
            options={
              <>
                <option value={""}>SELECT</option>
                {bank_master_list
                  ?.filter((v, i) => v?.BANK_CODE != "CASH ACCOUNT")
                  ?.map((v, i) => (
                    <option key={i} value={v?.ID}>
                      {bankCode(v?.BANK_CODE)} -
                      {v?.BANK_ACC_NO == "NA" ? user_id : v?.BANK_ACC_NO}
                    </option>
                  ))}
              </>
            }
          />
        </div>
        <div style={{ width: "180px" }}>
          <Input
            label={"FROM DATE"}
            type={"date"}
            value={addData?.from_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, from_date: e.target.value });
              }
            }}
          />
        </div>
        <div style={{ width: "180px" }}>
          <Input
            label={"TO DATE"}
            value={addData?.to_date}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setAddData({ ...addData, to_date: e.target.value });
              }
            }}
            type={"date"}
          />
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Button
            title={"SEARCH"}
            // onClick={() => get_list(addData)}
            onClick={async () => {
              await setShowData(false);
              await setSerchInput("");
              await setPageNo(1);
              await setEntries(5);
              get_list(addData, "main");
            }}
          />
        </div>
      </div>

      {showData && totalCount != 0 ? (
        <>
          <div className="SFromDate">
            <span style={{ fontSize: "11PX" }}>FROM DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.from_date).format("DD/MM/YY")}
            </p>
            <span style={{ fontSize: "11PX" }}>TO DATE :</span>
            <p className="FDATE">
              {moment(addDataPre?.to_date).format("DD/MM/YY")}
            </p>
            <span style={{ fontSize: "11PX" }}>BANK SELECTED:</span>
            <h6 className="PDS">
              {addDataPre?.custProdDetails} Opening Balance As On :{" "}
              {moment(addDataPre?.to_date).format("DD/MM/YY")} : {openingBal}
            </h6>
            {/* <span style={{ fontSize: "11PX" }}>
              Opening Balance As On :
              {moment(addDataPre?.from_date).format("DD/MM/YY")}
            </span> */}
            {/* <h6 className="FDATE">{addDataPre?.OB}</h6> */}
          </div>

          <Pagination3
            count={0}
            download={true}
            PDFDown={() => get_list(addDataPre, "pdf")}
            excelDown={() => get_list(addDataPre, "excel")}
            top={true}
            totalCount={totalCount > SearchCount ? SearchCount : totalCount}
            SerchInput={serchInput}
            serchChange={(e) => {
              setSerchInput(e.target.value);
              setPrevSearch(serchInput);
              setPageNo(1);
            }}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          >
            {purchase_report_item_list?.length == 0 ? (
              <NoData mt={30} />
            ) : (
              <Table
                headData={table_header}
                body={
                  <>
                    {purchase_report_item_list?.map((v, i) => (
                      <tr key={i}>
                        <td>{v?.SR_NO}</td>
                        <td>
                          {/* {new Date(parseInt(v?.BT_DATE)).toLocaleDateString(
                          "en-US",
                          { day: "numeric", month: "short", year: "numeric" }
                        )} */}
                          {v?.BT_DATE}
                        </td>
                        <td>{v?.TRANSACTION_MODULE}</td>
                        <td>{v?.TRANSACTION_DETAILS}</td>
                        <td>{v?.DEBIT}</td>
                        <td>{v?.CREDIT}</td>
                        <td>{v?.TRANS_CHARGES}</td>
                        <td>{v?.CLOSING_BALANCE}</td>
                        <td>{v?.CREATED_NAME}</td>
                      </tr>
                    ))}
                  </>
                }
              />
            )}
          </Pagination3>
        </>
      ) : showData ? (
        <NoData title={"NO RECORDS FOUND"} color="red" mt={30} />
      ) : (
        ""
      )}

      {open && (
        <SmallModal
          isOpen={open}
          setIsOpen={setOpen}
          title={"alerts"}
          mess={mess}
          okbtn={setOpen}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default BankBook;
